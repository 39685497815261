import * as types from "./types";

export default {
  [types.MUTATE_ABILITIES]: (state, payload) => {
    state.abilities = payload;
  },
  [types.MUTATE_DEPARTMENT_USERS]: (state, payload) => {
    state.departmentUers = payload;
  },
  [types.MUTATE_PROFILES_RESULT]: (state, payload) => {
    state.profilesResult = payload;
  },
};
