import * as types from "./types";

export default {
  [types.CUSTOMER]: (state) => {
    return state.customer;
  },
  [types.USER_ADDRESS_SIDEBAR]: (state) => {
    return state.userAddressSidebar;
  },
  [types.USER_PERSONAL_DATA_SIDEBAR]: (state) => {
    return state.userPersonalDataSidebar;
  },
  [types.CUSTOMER_COMMENTS]: (state) => {
    return state.customerComments;
  },
  [types.CUSTOMER_TIMELINE_EVENTS]: (state) => {
    return state.customerTimelineEvents;
  },
  [types.CUSTOMER_APPOINTMENTS]: (state) => {
    return state.customerAppointments;
  },
  [types.CUSTOMER_CONTRACTS]: (state) => {
    return state.customerContracts;
  },
  [types.CUSTOMER_FILES]: (state) => {
    return state.customerFiles;
  },
  [types.UPLOAD_FILE_SIDEBAR]: (state) => {
    return state.uploadFileSidebar;
  },
  [types.CUSTOMER_RECOMMENDATIONS]: (state) => {
    return state.customerRecommendations;
  },
};
