import * as types from './types'

export default {
  [types.FINANCIAL_ADJUSTMENTS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.FINANCIAL_ADJUSTMENT_REASONS]: state => {
    return state.adjustmentReasons
  },
  [types.FINANCIAL_ADJUSTMENT_CREDIT_TYPES]: state => {
    return state.adjustmentCreditTypes
  },
  [types.FINANCIAL_ADJUSTMENT_TYPES]: state => {
    return state.adjustmentTypes
  },
  [types.FINANCIAL_ADJUSTMENT_TOTALIZER]: state => {
    return state.adjustmentTotalizer
  },
  [types.SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR]: state => {
    return state.saveFinancialAdjustmentSidebar
  },
  [types.FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR]: state => {
    return state.adjustmentDetailSidebar
  },
  [types.FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT]: state => {
    return state.adjustmentsByMonth
  },
}
