import _ from "lodash";
import * as types from "./types";

export default {
  [types.MUTATE_DIGITAL_FRANCHISES_RESULT]: (state, payload) => {
    state.searchDigitalFranchiseResult = payload;
  },
  [types.MUTATE_PHYSICAL_FRANCHISES_RESULT]: (state, payload) => {
    state.searchPhysicalFranchiseResult = payload;
  },
  [types.MUTATE_NEW_FRANCHISE_SIDEBAR]: (state, payload) => {
    state.newFranchiseSidebar = { ...state.newFranchiseSidebar, ...payload };
    if (!state.newFranchiseSidebar.visible) {
      state.newFranchiseSidebar.id = null;
      state.newFranchiseSidebar.edit = false;
      state.newFranchiseSidebar.visualizar = false;
    }
  },

  [types.MUTATE_NEW_PHYSICAL_FRANCHISE_SIDEBAR]: (state, payload) => {
    state.newPhysicalFranchiseSidebar = {
      ...state.newPhysicalFranchiseSidebar,
      ...payload,
    };
    if (!state.newPhysicalFranchiseSidebar.visible) {
      state.newPhysicalFranchiseSidebar.id = null;
      state.newPhysicalFranchiseSidebar.edit = false;
      state.newPhysicalFranchiseSidebar.visualizar = false;
    }
  },

  [types.MUTATE_RESPONSABLES]: (state, payload) => {
    state.responsables = payload;
  },

  [types.MUTATE_PHYSICAL_RESPONSABLES]: (state, payload) => {
    state.physicalResponsables = payload;
  },
};
