import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  structureSearchResult: [],
  consultantSearchResult: [],
  npsStatuses: [],
  npsCustomersList: [],
  npsFranchisesConsultantsList: [],
  npsTotalizer: undefined,
  npsFranchiseTotalizer: undefined,
}

export default {
  state,
  actions,
  getters,
  mutations
}
