import * as types from './types'

export default {
  [types.MUTATE_BILLS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_PAYMENT_SIDEBAR]: (state, payload) => {
    state.paymentSidebar = { ...state.paymentSidebar, ...payload }
  },
  [types.MUTATE_BILL_DETAILS_SIDEBAR]: (state, payload) => {
    state.billDetailsSidebar = { ...state.billDetailsSidebar, ...payload }
  },
  [types.MUTATE_PAID_BILLS_SEARCH_RESULT]: (state, payload) => {
    state.searchPaidResult = payload
  },
  [types.MUTATE_EXPECTED_BILLS_SEARCH_RESULT]: (state, payload) => {
    state.searchExpectedResult = payload
  },
  [types.MUTATE_ACTIVE_BILLING_MONTH]: (state, payload) => {
    state.activeBillingMonth = payload
  },
  [types.MUTATE_CONTRACT_PAYMENTS_SEARCH_RESULT]: (state, payload) => {
    state.contractPaymentsSearchResult = payload
  },
  [types.MUTATE_PAYMENT_CONCILIATORS]: (state, payload) => {
    state.paymentConciliators = payload
  },
  [types.MUTATE_PAYMENT_PRODUCTS]: (state, payload) => {
    state.paymentProducts = payload
  },
  [types.MUTATE_PAYMENTS_IMPORTS_LIST]: (state, payload) => {
    state.paymentsImportsList = payload
  },
  [types.MUTATE_IMPORT_PAYMENTS_SIDEBAR]: (state, payload) => {
    state.importPaymentsSidebar = { ...state.importPaymentsSidebar, ...payload }
  },
  [types.MUTATE_PAYMENTS_IMPORTS_DETAILS_LIST]: (state, payload) => {
    state.paymentsImportsDetailsList = payload
  },
  [types.MUTATE_PAYMENTS_IMPORTS_DETAILS_TOTALIZER]: (state, payload) => {
    state.paymentsImportsDetailsTotalizer = payload
  }
}
