import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  initialItemsPerPage: 10,
  itemsPerPageOptions: [10, 25, 50, 100],
  businessConfig: {
    productTax: 0,
    billingChangeRequestDeadlineForPNs: undefined
  },
  fileUploadSidebar: {
    id: undefined,
    title: undefined,
    description: undefined,
    visible: false,
    fileType: undefined,
    saveAction: undefined
  },
  commentSidebar: {
    id: undefined,
    title: undefined,
    comment: undefined,
    visible: undefined,
    saveAction: undefined
  },
  customFieldTypes: [],
  customers: [],
  addressStates: [],
  addressCities: [],
  incomes: [],
  occupations: [],
  banks: [],
  pixKeyTypes: [],
  consultantApprovalDates: [],
  consultantFutureApprovalDates: [],
  franchises: undefined,
  physicalFranchises: undefined,
  roles: undefined,
  consultants: [],
  allConsultants: [],
  structureTypes: [],
  specialistConsultants: [],
  closerConsultants: [],
  partnerCompanies: [],
  products: [],
  creditTypes: [],
  schoolLevels: [],
  leadOrigins: [],
  leadGoals: [],
  withdrawalSummary: undefined,
  presentBillingDate: undefined,
  paymentDeadline: undefined,
  employeePaymentsInfo: undefined,
  departments: [],
  employees: [],
  withdrawalStatueses: [],
  consultantsPartner: [],
  withdrawalCategories: [],
  paymentGateways: [],
  activeConsultants: [],
  qrCodeSidebar: {
    title: undefined,
    url: undefined,
    visible: false
  },
  campaigns: [],
  costCenters: [],
  costCenterCategories: [],
  allCostCenterCategories: [],
  paymentTypes: [],
  groupCompanies: [],
  employeesByCostCenter: []
}

export default {
  state,
  actions,
  getters,
  mutations
}
