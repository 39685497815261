import * as types from "./types";

export default {
  [types.NEW_TO_DO_SIDEBAR]: state => {
    return state.newToDoSidebar
  },
  [types.TASKS_FROM_TODAY]: (state) => {
    return state.tasksFromToday;
  },
  [types.TODOS_INBOX_SEARCH_RESULT ]: state => {
    return state.todosInboxSearchResult
  },
  [types.TO_DO_DETAILS_SIDEBAR]: state => {
    return state.toDoDetailsSidebar
  },
  [types.TODOS_FLOAT_SIDEBAR]: state => {
    return state.todosFloatSidebar
  }
};
