import * as types from './types'

export default {
  [types.NPS_STRUCTURE_SEARCH_RESULT]: state => {
    return state.structureSearchResult
  },
  [types.NPS_CONSULTANT_SEARCH_RESULT]: state => {
    return state.consultantSearchResult
  },
  [types.NPS_STATUSES]: state => {
    return state.npsStatuses
  },
  [types.NPS_CUSTOMERS_LIST]: state => {
    return state.npsCustomersList
  },
  [types.NPS_FRANCHISES_CONSULTANTS_LIST]: state => {
    return state.npsFranchisesConsultantsList
  },
  [types.NPS_TOTALIZER]: state => {
    return state.npsTotalizer
  },
  [types.NPS_FRANCHISE_TOTALIZER]: state => {
    return state.npsFranchiseTotalizer
  },
}
