import * as types from './types'

export default {    
  [types.SUPPORT_TICKETS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.DETAILS_GENERAL_INFORMATION]: state => {
    return state.details.generalInformation
  },
  [types.TICKET_TYPES]: (state) => {
    return state.ticketTypes;
  },
  [types.RESOLVE_TICKET_SIDEBAR]: state => {
    return state.resolveTicketSidebar
  },
  [types.TICKET_STATUS]: (state) => {
    return state.ticketStatus;
  },
}
