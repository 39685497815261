// Getters
export const CONTRACTS_SEARCH_RESULT = 'contract/CONTRACTS_SEARCH_RESULT'
export const CONSULTANTS = 'contract/CONSULTANTS'
export const FINANCIAL_SOLUTIONS = 'contract/FINANCIAL_SOLUTIONS'
export const FINANCIAL_SOLUTION_FIELDS = 'contract/FINANCIAL_SOLUTION_FIELDS'
export const FINANCIAL_SOLUTION_CALCULATION_RULES = 'contract/FINANCIAL_SOLUTION_CALCULATION_RULES'
export const STATUS_LIST = 'contract/STATUS_LIST'
export const EDIT_CONTRACT_SIDEBAR = 'contract/EDIT_CONTRACT_SIDEBAR'
export const ADD_RECEIPT_SIDEBAR = 'contract/ADD_RECEIPT_SIDEBAR'
export const REQUEST_DELETION_SIDEBAR = 'contract/REQUEST_DELETION_SIDEBAR'
export const REQUEST_DELETION_APROVAL_SIDEBAR = 'contract/REQUEST_DELETION_APROVAL_SIDEBAR'
export const EDIT_SNAPSHOT_SIDEBAR = 'contract/EDIT_SNAPSHOT_SIDEBAR'
export const DETAILS_GENERAL_INFORMATION = 'contract/DETAILS_GENERAL_INFORMATION'
export const COMMENTS = 'contract/COMMENTS'
export const PAYMENTS = 'contract/PAYMENTS'
export const COMMISSIONS = 'contract/COMMISSIONS'
export const FILES = 'contract/FILES'
export const HISTORY = 'contract/HISTORY'
export const DELETEION_REQUEST_SEARCH_RESULT = 'contract/DELETEION_REQUEST_SEARCH_RESULT'
export const CUSTOMER_APPOINTMENTS = 'contract/CUSTOMER_APPOINTMENTS'
export const EDIT_CONTRACT_OWNER_SIDEBAR = 'contract/EDIT_CONTRACT_OWNER_SIDEBAR'
export const QUERY_ASAAS_ID_SIDEBAR = 'contract/QUERY_ASAAS_ID_SIDEBAR'
export const TRANSACTIONS = 'contract/TRANSACTIONS'
export const SAVE_TRANSACTION_SIDEBAR = 'contract/SAVE_TRANSACTION_SIDEBAR'

// Mutations
export const MUTATE_CONTRACTS_SEARCH_RESULT = 'contract/MUTATE_CONTRACTS_SEARCH_RESULT'
export const MUTATE_CONSULTANTS = 'contract/MUTATE_CONSULTANTS'
export const MUTATE_FINANCIAL_SOLUTIONS = 'contract/MUTATE_FINANCIAL_SOLUTIONS'
export const MUTATE_FINANCIAL_SOLUTION_FIELDS = 'contract/MUTATE_FINANCIAL_SOLUTION_FIELDS'
export const MUTATE_FINANCIAL_SOLUTION_CALCULATION_RULES = 'contract/MUTATE_FINANCIAL_SOLUTION_CALCULATION_RULES'
export const MUTATE_STATUS_LIST = 'contract/MUTATE_STATUS_LIST'
export const MUTATE_EDIT_CONTRACT_SIDEBAR = 'contract/MUTATE_EDIT_CONTRACT_SIDEBAR'
export const MUTATE_ADD_RECEIPT_SIDEBAR = 'contract/MUTATE_ADD_RECEIPT_SIDEBAR'
export const MUTATE_REQUEST_DELETION_SIDEBAR = 'contract/MUTATE_REQUEST_DELETION_SIDEBAR'
export const MUTATE_REQUEST_DELETION_APROVAL_SIDEBAR = 'contract/MUTATE_REQUEST_DELETION_APROVAL_SIDEBAR'
export const MUTATE_EDIT_SNAPSHOT_SIDEBAR = 'contract/MUTATE_EDIT_SNAPSHOT_SIDEBAR'
export const CLEAR_DETAILS_GENERAL_INFORMATION = 'contract/CLEAR_DETAILS_GENERAL_INFORMATION'
export const MUTATE_DETAILS_GENERAL_INFORMATION = 'contract/MUTATE_DETAILS_GENERAL_INFORMATION'
export const MUTATE_COMMENTS = 'contract/MUTATE_COMMENTS'
export const MUTATE_PAYMENTS = 'contract/MUTATE_PAYMENTS'
export const MUTATE_COMMISSIONS =  'contract/MUTATE_COMMISSIONS'
export const MUTATE_FILES = 'contract/MUTATE_FILES'
export const MUTATE_HISTORY = 'contract/MUTATE_HISTORY'
export const MUTATE_DELETION_REQUEST_SEARCH_RESULT = 'contract/MUTATE_DELETION_REQUEST_SEARCH_RESULT'
export const MUTATE_CUSTOMER_APPOINTMENTS = 'contract/MUTATE_CUSTOMER_APPOINTMENTS'
export const MUTATE_EDIT_CONTRACT_OWNER_SIDEBAR = 'contract/MUTATE_EDIT_CONTRACT_OWNER_SIDEBAR'
export const MUTATE_QUERY_ASAAS_ID_SIDEBAR = 'contract/MUTATE_QUERY_ASAAS_ID_SIDEBAR'
export const MUTATE_TRANSACTIONS = 'contract/MUTATE_TRANSACTIONS'
export const MUTATE_SAVE_TRANSACTION_SIDEBAR = 'contract/MUTATE_SAVE_TRANSACTION_SIDEBAR'

// Actions
export const GET_CONSULTANTS = 'contract/GET_CONSULTANTS'
export const GET_FINANCIAL_SOLUTION = 'contract/GET_FINANCIAL_SOLUTION'
export const GET_FINANCIAL_SOLUTIONS = 'contract/GET_FINANCIAL_SOLUTIONS'
export const GET_FINANCIAL_SOLUTION_FIELDS = 'contract/GET_FINANCIAL_SOLUTION_FIELDS'
export const GET_FINANCIAL_SOLUTION_CALCULATION_RULES = 'contract/GET_FINANCIAL_SOLUTION_CALCULATION_RULES'
export const SEARCH_CONTRACTS = 'contract/SEARCH_CONTRACTS'
export const EXPORT_CONTRACTS = 'contract/EXPORT_CONTRACTS'
export const GET_CONTRACT = 'contract/GET_CONTRACT'
export const SAVE_CONTRACT = 'contract/SAVE_CONTRACT'
export const GET_STATUS_LIST = 'contract/GET_STATUS_LIST'
export const SAVE_ATTACHMENT = 'contract/SAVE_ATTACHMENT'
export const OPEN_EDIT_CONTRACT_SIDEBAR  = 'contract/OPEN_EDIT_CONTRACT_SIDEBAR'
export const UPDATE_CONTRACT = 'contract/UPDATE_CONTRACT'
export const OPEN_ADD_RECEIPT_SIDEBAR = 'contract/OPEN_ADD_RECEIPT_SIDEBAR'
export const OPEN_REQUEST_DELETION_SIDEBAR = 'contract/REQUEST_DELETION_SIDEBAR'
export const OPEN_REQUEST_DELETION_APROVAL_SIDEBAR = 'contract/REQUEST_DELETION_APROVAL_SIDEBAR'
export const SAVE_RECEIPT = 'contract/SAVE_RECEIPT'
export const OPEN_EDIT_SNAPSHOT_SIDEBAR = 'contract/OPEN_EDIT_SNAPSHOT_SIDEBAR'
export const GET_SNAPSHOTS = 'contract/GET_SNAPSHOTS'
export const UPDATE_SNAPSHOTS = 'contract/UPDATE_SNAPSHOTS'
export const GET_PAYMENTS = 'contract/GET_PAYMENTS'
export const UPDATE_PAYMENTS = 'contract/UPDATE_PAYMENTS'
export const SAVE_COMMENT = 'contract/SAVE_COMMENT'
export const GET_DETAILS_GENERAL_INFORMATION = 'contract/GET_DETAILS_GENERAL_INFORMATION'
export const GET_COMMENTS = 'contract/GET_COMMENTS'
export const DELETE_COMMENT = 'contract/DELETE_COMMENT'
export const GET_COMMISSIONS = 'contract/GET_COMMISSIONS'
export const GET_FILES = 'contract/GET_FILES'
export const DOWNLOAD_FILE = 'contract/DOWNLOAD_FILE'
export const GET_HISTORY = 'contract/GET_HISTORY'
export const SEARCH_DELETION_REQUEST = 'contract/SEARCH_DELETION_REQUEST'
export const DELETE_DELETION_REQUEST = 'contract/DELETE_DELETION_REQUEST'
export const DELETION_REQUEST_SEARCH_RESULT = 'contract/DELETION_REQUEST_SEARCH_RESULT'
export const SAVE_REQUEST_DELETION_APROVAL_SIDEBAR = 'contract/SAVE_REQUEST_DELETION_APROVAL_SIDEBAR'
export const SAVE_REQUEST_DELETION_SIDEBAR = 'contract/SAVE_REQUEST_DELETION_SIDEBAR'
export const GET_CUSTOMER_APPOINTMENTS = 'contract/GET_CUSTOMER_APPOINTMENTS'
export const RESET_DAYS_ON_STATUS = 'contract/RESET_DAYS_ON_STATUS'
export const DELETE_SELECTED_DELETION_REQUEST = 'contract/DELETE_SELECTED_DELETION_REQUEST'
export const GET_CONSULTANTS_ROLES = 'contract/GET_CONSULTANTS_ROLES'
export const CALCULATE_SNAPSHOT_DISTRIBUTION = 'contract/CALCULATE_SNAPSHOT_DISTRIBUTION'
export const DELETE_PAYMENT = 'contract/DELETE_PAYMENT'
export const REVERSAL_CONTRACT_PAYMENTS = 'contract/REVERSAL_CONTRACT_PAYMENTS'
export const REVERSAL_PAYMENT = 'contract/REVERSAL_PAYMENT'
export const OPEN_EDIT_CONTRACT_OWNER_SIDEBAR = 'contract/OPEN_EDIT_CONTRACT_OWNER_SIDEBAR'
export const UPDATE_CONTRACT_OWNER = 'contract/UPDATE_CONTRACT_OWNER'
export const REFUSE_SELECTED_DELETION_REQUEST = 'contract/REFUSE_SELECTED_DELETION_REQUEST'
export const OPEN_QUERY_ASAAS_ID_SIDEBAR = 'contract/OPEN_QUERY_ASAAS_ID_SIDEBAR'
export const GET_ASAAS_ID = 'contract/GET_ASAAS_ID'
export const SAVE_CONTRACT_CUSTOM_FIELD = 'contract/SAVE_CONTRACT_CUSTOM_FIELD'
export const GET_TRANSACTIONS = 'contract/GET_TRANSACTIONS'
export const UPDATE_TRANSACTIONS = 'contract/UPDATE_TRANSACTIONS'
export const OPEN_SAVE_TRANSACTION_SIDEBAR = 'contract/OPEN_SAVE_TRANSACTION_SIDEBAR'
export const UPDATE_TRANSACTION = 'contract/UPDATE_TRANSACTION'
export const GET_TRANSACTION = 'contract/GET_TRANSACTION'
