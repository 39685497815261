import * as types from './types'

export default {
  [types.WITHDRAWALS]: state => {
    return state.withdrawals
  },
  [types.WITHDRAWAL_REQUEST_SIDEBAR]: state => {
    return state.withdrawalRequestSidebar
  },
  [types.WITHDRAWAL_DETAILS_SIDEBAR]: state => {
    return state.withdrawalDetailsSidebar
  }
}
