import _ from "lodash";
import * as types from "./types";

export default {
  [types.MUTATE_TOTAL_EXPENSES_BY_COST_CENTER]: (state, payload) => {
    state.totalExpensesByCostCenter = payload;
  },
  [types.MUTATE_EXPENSES_SEARCH_RESULT]: (state, payload) => {
    state.expensesSearchResult = payload;
  },
  [types.MUTATE_EXPENSE_SIDEBAR]: (state, payload) => {
    state.saveExpenseSidebar = { ...state.saveExpenseSidebar, ...payload };
  },
  [types.MUTATE_YEAR_INCOME_CHART_DATA]: (state, payload) => {
    state.yearIncomeChartData = payload;
  },
  [types.MUTATE_COMPETENCIES_EXPENSES]: (state, payload) => {
    state.competenciesExpenses = payload;
  },
  [types.MUTATE_EXPENSES_SEARCH_YEAR]: (state, payload) => {
    state.expensesSearchYear = payload;
  },
};
