import * as types from './types'

export default {
  [types.PARTNER_COMPANIES_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.PARTNER_COMPANY_AGENT_TYPES]: state => {
    return state.agentTypes
  },
  [types.PRODUCTS]: state => {
    return state.products
  },
}
