import * as types from './types'

export default {
  [types.OVERDUE_SIGNATURES]: state => {
    return state.overdueSignatures
  },
  [types.INACTIVE_PN_CONTRACTS]: state => {
    return state.inactivePnContracts
  },
  [types.INACTIVE_PN_CUSTOMERS]: state => {
    return state.inactivePnCustomers
  }
}
