import * as types from "./types";

export default {
  [types.DIGITAL_FRANCHISES_SEARCH_RESULT]: (state) => {
    return state.searchDigitalFranchiseResult;
  },
  [types.PHYSICAL_FRANCHISES_SEARCH_RESULT]: (state) => {
    return state.searchPhysicalFranchiseResult;
  },
  [types.DIGITAL_TABLE_COLUMNS]: (state) => {
    return state.tableColumns;
  },
  [types.PHYSICAL_TABLE_COLUMNS]: (state) => {
    return state.tableColumnsPhysical;
  },
  [types.NEW_FRANCHISE_SIDEBAR]: (state) => {
    return state.newFranchiseSidebar;
  },
  [types.NEW_PHYSICAL_FRANCHISE_SIDEBAR]: (state) => {
    return state.newPhysicalFranchiseSidebar;
  },
  [types.RESPONSABLES]: (state) => {
    return state.responsables;
  },
  [types.PHYSICAL_RESPONSABLES]: (state) => {
    return state.physicalResponsables;
  },
};
