// Getters
export const CONSULTANTS = 'kpi/CONSULTANTS'
export const TOP_BY_SCHEDULED_QUANTITY = 'kpi/TOP_BY_SCHEDULED_QUANTITY'
export const TOP_BY_DONE_QUANTITY = 'kpi/TOP_BY_DONE_QUANTITY'
export const TOP_BY_PAID_AMOUNT = 'kpi/TOP_BY_PAID_AMOUNT'
export const KPI_LIST = 'kpi/KPI_LIST'

// Mutations
export const MUTATE_CONSULTANTS = 'kpi/MUTATE_CONSULTANTS'
export const MUTATE_TOP_BY_SCHEDULED_QUANTITY = 'kpi/MUTATE_TOP_BY_SCHEDULED_QUANTITY'
export const MUTATE_TOP_BY_DONE_QUANTITY = 'kpi/MUTATE_TOP_BY_DONE_QUANTITY'
export const MUTATE_TOP_BY_PAID_AMOUNT = 'kpi/MUTATE_TOP_BY_PAID_AMOUNT'
export const MUTATE_KPI_LIST = 'kpi/MUTATE_KPI_LIST'
export const MUTATE_KPI_LIST_CONTENT = 'kpi/MUTATE_KPI_LIST_CONTENT'

// Actions
export const GET_CONSULTANTS = 'kpi/GET_CONSULTANTS'
export const GET_TOP_BY_SCHEDULED_QUANTITY = 'kpi/GET_TOP_BY_SCHEDULED_QUANTITY'
export const GET_TOP_BY_DONE_QUANTITY = 'kpi/GET_TOP_BY_DONE_QUANTITY'
export const GET_TOP_BY_PAID_AMOUNT = 'kpi/GET_TOP_BY_PAID_AMOUNT'
export const GET_CONSULTANT_AND_CHILDS = 'kpi/GET_CONSULTANT_AND_CHILDS'
export const GET_LEADS_PERFOMANCE = 'kpi/GET_LEADS_PERFOMANCE'
export const GET_APPOINTMENTS_PERFORMANCE = 'kpi/GET_APPOINTMENTS_PERFORMANCE'
export const GET_APPOINTMENT_REPORTS_PERFORMANCE = 'kpi/GET_APPOINTMENT_REPORTS_PERFORMANCE'
export const GET_INTERVIEWS_PERFORMANCE = 'kpi/GET_INTERVIEWS_PERFORMANCE'
export const GET_CONTRACTS_PERFORMANCE = 'kpi/GET_CONTRACTS_PERFORMANCE'
export const GET_HOT_LEADS_PERFOMANCE = 'kpi/GET_HOT_LEADS_PERFOMANCE'
