import * as types from "./types";

export default {
  [types.MUTATE_CUSTOMER]: (state, payload) => {
    state.customer = payload;
  },
  [types.MUTATE_USER_ADDRESS_SIDEBAR]: (state, payload) => {
    state.userAddressSidebar = { ...state.userAddressSidebar, ...payload };
  },
  [types.MUTATE_USER_PERSONAL_DATA_SIDEBAR]: (state, payload) => {
    state.userPersonalDataSidebar = {
      ...state.userPersonalDataSidebar,
      ...payload,
    };
  },
  [types.MUTATE_CUSTOMER_COMMENTS]: (state, payload) => {
    state.customerComments = payload;
  },
  [types.MUTATE_CUSTOMER_TIMELINE_EVENTS]: (state, payload) => {
    state.customerTimelineEvents = payload;
  },
  [types.MUTATE_CUSTOMER_APPOINTMENTS]: (state, payload) => {
    state.customerAppointments = payload;
  },
  [types.MUTATE_CUSTOMER_CONTRACTS]: (state, payload) => {
    state.customerContracts = payload;
  },
  [types.MUTATE_CUSTOMER_FILES]: (state, payload) => {
    state.customerFiles = payload;
  },
  [types.MUTATE_UPLOAD_FILE_SIDEBAR]: (state, payload) => {
    state.uploadFileSidebar = { ...state.uploadFileSidebar, ...payload };
  },
  [types.MUTATE_CUSTOMER_RECOMMENDATIONS]: (state, payload) => {
    state.customerRecommendations = payload;
  },
};
