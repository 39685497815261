import * as types from "./types";

export default {
  [types.CURRENT_COMMISSIONS_SEARCH_RESULT]: (state) => {
    return state.currentSearchResult;
  },
  [types.HISTORICAL_COMMISSIONS_SEARCH_RESULT]: (state) => {
    return state.historicalSearchResult;
  },
  [types.PAST_FUTURE_COMMISSIONS_SEARCH_RESULT]: (state) => {
    return state.pastFutureSearchResult;
  },
  [types.COMMISSION_PRODUCTS]: (state) => {
    return state.commissionProducts;
  },
  [types.COMMISSION_MANAGEMENT_SEARCH_RESULT]: (state) => {
    return state.searchResult;
  },
  [types.PAYMENT_CONCILIATORS]: (state) => {
    return state.paymentConciliators;
  },
  [types.FINANCIAL_SOLUTIONS]: (state) => {
    return state.financialSolutions;
  },
  [types.PAYMENT_PRODUCTS]: (state) => {
    return state.paymentProducts;
  },
  [types.GET_COMMISSION_MANAGEMENT_TOTALIZER]: (state) => {
    return state.commissionManagementTotalizer;
  },
  [types.CHART_COMMISSION_MANAGEMENT]: (state) => {
    return state.chartResult;
  }
};
