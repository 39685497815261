export default [
    {
      path: '/autenticacao/:token',
      name: 'account-token-auth',
      component: () => import('@/modules/account/views/TokenAuth.vue'),
      meta: {
        layout: 'full',
        public: true
      }
    },
    {
      path: '/login',
      name: 'account-login',
      component: () => import('@/modules/account/views/Login.vue'),
      meta: {
        layout: 'full',
        public: true,
        redirectIfLoggedIn: true
      },
    },
    {
      path: '/esqueci-senha',
      name: 'account-forgot-password',
      component: () => import('@/modules/account/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/nova-senha',
      name: 'account-reset-password',
      component: () => import('@/modules/account/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/appify',
      name: 'appify-auth',
      component: () => import('@/modules/account/views/AppifyAuth.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/appify-commissions',
      name: 'appify-auth-commissions',
      component: () => import('@/modules/account/views/AppifyAuth.vue'),
      meta: {
        layout: 'full',
        public: true
      },
      props: {
        commissions: true
      },
    },
  ]
  