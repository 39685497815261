import axios from 'axios'
import * as types from './types'

export default {
  [types.GET_NOTIFICATIONS]: ({  }, payload) => {
    const resource = `/api/notifications/unreads?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.get(resource)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_NOTIFICATION_LIST]: ({ commit }, payload) => {
    const resource = `/api/notifications/by_status?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_NOTIFICATION_LIST, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_UNREAD_NOTIFICATIONS]: ({ commit }, payload) => {
    return axios.get('/api/notifications/no_read_count', payload)
      .then(resp => {
        commit(types.MUTATE_UNREAD_NOTIFICATIONS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_NOTIFICATION_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NOTIFICATION_SIDEBAR, { ...payload, visible: true })
  },
  [types.READ_NOTIFICATION]: ({ commit }, payload) => {
    return axios.put('/api/notifications', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.READ_ALL_NOTIFICATIONS]: ({  }, payload) => {
    return axios.post('/api/notifications/read_all', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.READ_NOTIFICATIONS]: ({  }, payload) => {
    return axios.post('/api/notifications/read', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.UPDATE_NOTIFICATION_STATUS]: ({  }, payload) => {
    return axios.post('/api/notifications/update_status', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_NOTIFICATIONS_COUNTER]: ({ commit }, payload) => {
    return axios.get('/api/notifications/counter', payload)
      .then(resp => {
        commit(types.MUTATE_NOTIFICATIONS_COUNTER, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
}
