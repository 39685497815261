import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  searchResult: undefined,
  newBannerSidebar: {
    id: undefined,
    visible: false,
    saveAction: undefined
  },
  dashBannersSearchResult: [],
  announcementTypes: []
}

export default {
  state,
  actions,
  getters,
  mutations
}
