import * as types from './types'

export default {
  [types.MUTATE_VERSIONS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_DASHBOARD_BANNERS_SEARCH_RESULT]: (state, payload) => {
    state.dashBannersSearchResult = payload
  },
  [types.MUTATE_NEW_BANNER_SIDEBAR]: (state, payload) => {
    state.newBannerSidebar = { ...state.newBannerSidebar, ...payload }
  },
  [types.MUTATE_ANNOUNCEMENT_TYPES]: (state, payload) => {
    state.announcementTypes = payload
  },
}
