import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  searchResult: undefined,
  consultants: undefined,
  financialSolutions: undefined,
  financialSolutionFields: undefined,
  financialSolutionCalculationRules: {},
  statusList: [],
  editContractSidebar: {
    id: undefined,
    proposal_number: undefined,
    id_contract_status: undefined,
    financial_solution_id: undefined,
    visible: false,
    currentPage: undefined,
    saveAction: undefined,
  },
  addReceiptSidebar: {
    id: undefined,
    visible: false,
    currentPage: undefined,
    saveAction: undefined,
  },
  requestDeletionSidebar: {
    id: undefined,
    description: undefined,
    currentPage: undefined,
    contract_id: undefined,
  },
  editSnapshotSidebar: {
    id: undefined,
    consultant_id: undefined,
    visible: false,
  },
  details: {
    generalInformation: undefined,
  },
  comments: undefined,
  payments: undefined,
  commissions: undefined,
  files: undefined,
  history: undefined,
  deletionRequestSearchResult: undefined,
  requestDeletionAprovalSidebar: false,
  customerAppointments: [],
  editContractOwnerSidebar: {
    id: undefined,
    visible: false,
    saveAction: undefined
  },
  queryAsaasIdSidebar: {
    visible: false
  },
  transactions: [],
  saveTransactionSidebar: {
    id: undefined,
    visible: false,
    saveAction: undefined
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
