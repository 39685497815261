import {
  EXPENSE,
  READ_ACTION
} from '@/constants/resources'
  

export default [
  {
    path: '/despesas',
    name: 'expenses-list',
    component: () => import('@/modules/accounting_management/views/ExpensesList.vue'),
    meta: {
      resource: EXPENSE,
      action: READ_ACTION,
      pageTitle: 'Despesas'
    },
  }
]
  