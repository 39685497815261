import * as types from './types'

export default {
  [types.BILLS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.PAYMENT_SIDEBAR]: state => {
    return state.paymentSidebar
  },
  [types.BILL_DETAILS_SIDEBAR]: state => {
    return state.billDetailsSidebar
  },
  [types.PAID_BILLS_SEARCH_RESULT]: state => {
    return state.searchPaidResult
  },
  [types.EXPECTED_BILLS_SEARCH_RESULT]: state => {
    return state.searchExpectedResult
  },
  [types.ACTIVE_BILLING_MONTH]: state => {
    return state.activeBillingMonth
  },
  [types.CONTRACT_PAYMENTS_SEARCH_RESULT]: state => {
    return state.contractPaymentsSearchResult
  },
  [types.PAYMENT_CONCILIATORS]: state => {
    return state.paymentConciliators
  },
  [types.PAYMENT_PRODUCTS]: state => {
    return state.paymentProducts
  },
  [types.PAYMENTS_IMPORTS_LIST]: state => {
    return state.paymentsImportsList
  },
  [types.IMPORT_PAYMENTS_SIDEBAR]: state => {
    return state.importPaymentsSidebar
  },
  [types.PAYMENTS_IMPORTS_DETAILS_LIST]: state => {
    return state.paymentsImportsDetailsList
  },
  [types.PAYMENTS_IMPORTS_DETAILS_TOTALIZER]: state => {
    return state.paymentsImportsDetailsTotalizer
  }
}
