import axios from 'axios'
import * as types from './types'

export default {
  [types.OPEN_NEW_LEAD_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_LEAD_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_CUSTOMER]: ({ }, payload) => {
    return axios.get(`/api/leads/by_id/${payload.id}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CUSTOMER_LEAD]: ({ }, payload) => {
    return axios.get(`/api/leads/by_customer/${payload.id}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_CUSTOMERS]: ({ commit }, payload) => {
    const resource = `/api/customers/search?page=${payload.currentPage}`
    delete payload.currentPage;
    axios.post('/api/customers/search/totalizers', payload)
      .then(resp => {
        commit(types.MUTATE_CUSTOMERS_SEARCH_TOTALIZERS, resp.data)
        return Promise.resolve(resp)
      })
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_CUSTOMERS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_OCCUPATIONS]: ({ commit }, payload) => {
    return axios.get(`/api/common/occupations`)
      .then(resp => {
        commit(types.MUTATE_OCCUPATIONS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_SCHOOL_LEVELS]: ({ commit }, payload) => {
    return axios.get(`/api/common/school_levels`)
      .then(resp => {
        commit(types.MUTATE_SCHOOL_LEVELS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_GOALS]: ({ commit }, payload) => {
    return axios.get('/api/leads/goals',{ params: payload })
      .then(resp => {
        commit(types.MUTATE_GOALS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ORIGINS]: ({ commit }, payload) => {
    return axios.get('/api/leads/origins', { params: payload })
      .then(resp => {
        commit(types.MUTATE_ORIGINS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_SETTINGS_TABLE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_SETTINGS_TABLE_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_SETTINGS_TABLE_CUSTOMERS]: ({ commit }, payload) => {
    return axios.get('/api/users/get_settings_table_customers', { params: payload })
      .then(resp => {        
        commit(types.MUTATE_SETTINGS_TABLE_CUSTOMERS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_SETTINGS_TABLE_SIDEBAR]: ({ commit }, payload) => {
    return axios.post('/api/users/set_settings_table_customers', payload)    
      .then(resp => {
        commit(types.MUTATE_SETTINGS_TABLE_CUSTOMERS, resp.data)
        commit(types.HIDE_COLUMNS)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_STATUSES]: ({ commit }, payload) => {
    return axios.get('/api/customers_info/customer_statuses')    
      .then(resp => {
        commit(types.MUTATE_STATUSES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SET_CALL_REMINDER]: ({ }, payload) => {
    return axios.put('/api/customers_info/call_reminder', payload)    
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.TRANSFER_CUSTOMERS]: ({ }, payload) => {
    return axios.post('/api/customers/transfer_customers', payload)    
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CUSTOMER_TRANSFER_OPTIONS]: ({ commit }, payload) => {
    return axios.get('/api/customers/customer_transfer_types')    
      .then(resp => {
        commit(types.MUTATE_CUSTOMER_TRANSFER_OPTIONS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
