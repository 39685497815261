import {
    READ_ACTION,
    CUSTOMER_RESOURCE,
  } from "@/constants/resources";
  
  export default [
    {
      path: "/tasks",
      name: "tasks",
      component: () => import("@/modules/todo/views/ToDoInbox.vue"),
      meta: {
        resource: CUSTOMER_RESOURCE,
        action: READ_ACTION,
        pageTitle: "TO-DO",
        contentClass: 'email-application'
      },
    }
  ];
  