// Getters
export const CUSTOMERS_SEARCH_RESULT = 'customer/CUSTOMERS_SEARCH_RESULT'
export const CUSTOMERS_SEARCH_TOTALIZERS = 'customer/CUSTOMERS_SEARCH_TOTALIZERS'
export const LEAD_TYPES = 'customer/LEAD_TYPES'
export const OCCUPATIONS = 'customer/OCCUPATIONS'
export const SCHOOL_LEVELS = 'customer/SCHOOL_LEVELS'
export const INCOMES = 'customer/INCOMES'
export const GOALS = 'customer/GOALS'
export const ORIGINS = 'customer/ORIGINS'
export const COUNTERS = 'customer/COUNTERS'
export const SETTINGS_TABLE_SIDEBAR = 'customer/SETTINGS_TABLE_SIDEBAR'
export const SETTINGS_TABLE_CUSTOMERS = 'customer/SETTINGS_TABLE_CUSTOMERS'
export const SAVE_SETTINGS_TABLE_SIDEBAR = 'customer/SAVE_SETTINGS_TABLE_SIDEBAR'
export const CUSTOMERS_TABLE_COLUMNS = 'customer/CUSTOMERS_TABLE_COLUMNS'
export const STATUSES = 'customer/STATUSES'
export const SEQUENCE_COLUMN_ENABLED = 'customer/SEQUENCE_COLUMN_ENABLED'
export const CUSTOMER_TRANSFER_OPTIONS = 'customer/CUSTOMER_TRANSFER_OPTIONS'


// Mutations
export const MUTATE_CUSTOMERS_SEARCH_RESULT = 'customer/MUTATE_CUSTOMERS_SEARCH_RESULT'
export const MUTATE_CUSTOMERS_SEARCH_TOTALIZERS = 'customer/MUTATE_CUSTOMERS_SEARCH_TOTALIZERS'
export const MUTATE_NEW_LEAD_SIDEBAR = 'customer/MUTATE_NEW_LEAD_SIDEBAR'
export const MUTATE_LEAD_TYPES = 'customer/MUTATE_LEAD_TYPES'
export const MUTATE_OCCUPATIONS = 'customer/MUTATE_OCCUPATIONS'
export const MUTATE_SCHOOL_LEVELS = 'customer/MUTATE_SCHOOL_LEVELS'
export const MUTATE_INCOMES = 'customer/MUTATE_INCOMES'
export const MUTATE_GOALS = 'customer/MUTATE_GOALS'
export const MUTATE_ORIGINS = 'customer/MUTATE_ORIGINS'
export const MUTATE_COUNTERS = 'customer/MUTATE_COUNTERS'
export const MUTATE_SETTINGS_TABLE_CUSTOMERS = 'customer/MUTATE_SETTINGS_TABLE_CUSTOMERS'
export const MUTATE_SETTINGS_TABLE_SIDEBAR = 'customer/MUTATE_SETTINGS_TABLE_SIDEBAR'
export const MUTATE_CUSTOMERS_TABLE_COLUMNS = 'customer/MUTATE_CUSTOMERS_TABLE_COLUMNS'
export const HIDE_COLUMNS = 'customer/HIDE_COLUMNS'
export const MUTATE_STATUSES = 'customer/MUTATE_STATUSES'
export const MUTATE_CUSTOMER_TRANSFER_OPTIONS = 'customer/MUTATE_CUSTOMER_TRANSFER_OPTIONS'


// Actions
export const GET_CUSTOMER = 'customer/GET_CUSTOMER'
export const SEARCH_CUSTOMERS = 'customer/SEARCH_CUSTOMERS'
export const OPEN_NEW_LEAD_SIDEBAR = 'customer/OPEN_NEW_LEAD_SIDEBAR'
export const GET_LEAD_TYPES = 'customer/GET_LEAD_TYPES'
export const GET_LEAD_INCOME = 'customer/GET_LEAD_INCOME'
export const GET_OCCUPATIONS = 'customer/GET_OCCUPATIONS'
export const GET_SCHOOL_LEVELS = 'customer/GET_SCHOOL_LEVELS'
export const GET_INCOMES = 'customer/GET_INCOMES'
export const GET_GOALS = 'customer/GET_GOALS'
export const GET_ORIGINS = 'customer/GET_ORIGINS'
export const STORE_LEAD = 'customer/STORE_LEAD'
export const DOWNLOAD_RESUME = 'customer/DOWNLOAD_RESUME'
export const GET_COUNTERS = 'customer/GET_COUNTERS'
export const OPEN_SETTINGS_TABLE_SIDEBAR = 'customer/OPEN_SETTINGS_TABLE_SIDEBAR'
export const GET_SETTINGS_TABLE_CUSTOMERS = 'customer/GET_SETTINGS_TABLE_CUSTOMERS'
export const GET_STATUSES = 'customer/GET_STATUSES'
export const SET_CALL_REMINDER = 'customer/SET_CALL_REMINDER'
export const GET_CUSTOMER_LEAD = 'customer/GET_CUSTOMER_LEAD'
export const TRANSFER_CUSTOMERS = 'customer/TRANSFER_CUSTOMERS'
export const GET_CUSTOMER_TRANSFER_OPTIONS = 'customer/GET_CUSTOMER_TRANSFER_OPTIONS'

