import axios from 'axios'
import router from '@/router'
import store from '@/store'
import * as types from '@/modules/account/store/types'
import { ADMIN_CREDENTIALS_STORAGE_KEY } from '@/constants/auth'
import { getCredentials } from '@/helpers/credentials'

export const initApi = () => {
    const token = getCredentials(ADMIN_CREDENTIALS_STORAGE_KEY) || undefined
    if (token) {
      store.commit(types.MUTATE_TOKEN, token)
    }

    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.withCredentials = false
    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if (
                401 === error.response.status
                || 403 === error.response.status
                || 419 === error.response.status
            ) {
                store.dispatch(types.LOGOUT)
                router.push({ name: 'misc-not-authorized' })
            } else {
                return Promise.reject(error)
            }
        }
    )

    if (token) {
      store.dispatch(types.GET_USER_DATA)
    }
}