import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  abilities: [],
  departmentUers: [],
  profilesResult: [],
};

export default {
  state,
  actions,
  getters,
  mutations,
};
