import {
    READ_ACTION,
    CONTRACT_RESOURCE,
  } from '@/constants/resources'

export default [
    {
        path: '/notificacoes',
        name: 'notification-inbox',
        component: () => import('@/modules/notification/views/NotificationInbox.vue'),
        meta: {
          resource: CONTRACT_RESOURCE,
          action: READ_ACTION
        },
        children: [
          {
            path: 'nao_lidas',
            name: 'notification-inbox-unread',
            component: () => import('@/modules/notification/views/NotificationInboxList.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'notification-inbox'
            },
            props:{
              'status': 'notifications'
            }
          },
          {
            path: 'pendencias',
            name: 'notification-inbox-pendencies',
            component: () => import('@/modules/notification/views/NotificationInboxList.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'notification-inbox'
            },
            props:{
              'status': 'pendencies'
            }
          },
          {
            path: 'resolvidas',
            name: 'notification-inbox-solved',
            component: () => import('@/modules/notification/views/NotificationInboxList.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'notification-inbox'
            },
            props:{
              'status': 'solved'
            }
          }
        ]
    },
]