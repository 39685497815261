import axios from 'axios'
import * as types from './types'

export default {
  [types.SEARCH_BILLS]: ({ commit }, payload) => {
    const resource = `/api/financial_management/bills/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_BILLS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DOWNLOAD_DOCUMENT]: ({}, payload) => {
    return axios.get(`/api/financial_management/donwload_document/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_PAYMENT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_PAYMENT_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_BILL_DETAILS]: ({}, payload) => {
    return axios
      .get(`/api/financial_management/bills/withdrawal_request/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.STORE_PAYMENT_REVIEW]: ({}, payload) => {
    return axios
      .post(`/api/financial_management/bills/withdrawal_request`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_BILL_DETAILS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_BILL_DETAILS_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_INVOICE_HISTORY]: ({}, payload) => {
    return axios
      .post(`/api/financial_management/invoice_history`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_PAID_BILLS]: ({ commit }, payload) => {
    const resource = `/api/financial_management/bills/search_paid`
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_PAID_BILLS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_EXPECTED_BILLS]: ({ commit }, payload) => {
    const resource = `/api/financial_management/bills/search_expected`
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_EXPECTED_BILLS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ACTIVE_BILLING_MONTH]: ({ commit }, payload) => {
    const resource = `/api/business_configs/active_billing_month`
    return axios.get(resource, payload)
      .then(resp => {
        commit(types.MUTATE_ACTIVE_BILLING_MONTH, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.IMPORT_FINANCIAL_SOLUTION_PAYMENTS]: ({ }, payload) => {
    return axios.post('/api/financial_solutions/payment_import/import', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_IMPORT_STATUS]: ({ }, payload) => {
    return axios.get(`/api/financial_solutions/payment_import/status/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DOWNLOAD_IMPORT_FILE]: ({ }, payload) => {
    return axios.post(`/api/financial_solutions/payment_import/download_file`, payload, {responseType: 'blob'})
      .then(resp => {
          const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
          const fileURL = window.URL.createObjectURL(blob);
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', payload.file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DOWNLOAD_PAYMENTS_PROVISIONED_REPORT]: ({ }, payload) => {
    return axios.post(`/api/financial_solutions/payment_import/report`,
    payload,
    { responseType: 'blob' },
  )
    .then(resp => {
      let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      let fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'provisionados_nao_pagos.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
      return Promise.resolve(resp)
    })
    .catch(err => {
      return Promise.reject(err)
    })
  },
  [types.SEARCH_CONTRACT_PAYMENTS]: ({ commit }, payload) => {
    const resource = `/api/payments/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_CONTRACT_PAYMENTS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PAYMENT_CONCILIATORS]: ({ commit }, payload) => {
    return axios.get(`/api/common/payment_conciliation_types`)
      .then(resp => {
        commit(types.MUTATE_PAYMENT_CONCILIATORS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PAYMENT_PRODUCTS]: ({ commit }, payload) => {
    return axios.get(`/api/common/payment_product_types`)
      .then(resp => {
        commit(types.MUTATE_PAYMENT_PRODUCTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_PAYMENTS_IMPORTS]: ({ commit }, payload) => {
    const resource = `/api/financial_solutions/payment_import/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_PAYMENTS_IMPORTS_LIST, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_IMPORT_PAYMENTS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_IMPORT_PAYMENTS_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_PAYMENTS_IMPORTS_DETAILS_LIST]: ({ commit }, payload) => {
    const resource = `/api/financial_solutions/payment_import/details?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_PAYMENTS_IMPORTS_DETAILS_LIST, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PAYMENTS_IMPORTS_DETAILS_TOTALIZER]: ({ commit }, payload) => {
    const resource = `/api/financial_solutions/payment_import/totalizer`
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_PAYMENTS_IMPORTS_DETAILS_TOTALIZER, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.ROLLBACK_PAYMENTS_IMPORT]: ({ }, payload) => {
    const resource = `/api/financial_solutions/payment_import/${payload}/rollback`
    return axios.get(resource)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_HAS_MONTH_INVOICE]: ({ }, payload) => {
    const resource = `/api/financial_management/has_month_invoice`
    return axios.get(resource)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SCHEDULE_BILLING]: ({ }, payload) => {
    const resource = `/api/financial_management/schedule_billing`
    return axios.get(resource)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
