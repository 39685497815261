import {
  CONTRACT_RESOURCE,
  CREATE_ACTION,
  MANAGE_ACTION,
  COMMISSION_MANAGEMENT,
} from "@/constants/resources";

import * as commissionPeriod from "@/constants/commission_periods";

export default [
  {
    path: "/comissoes/passadas",
    name: "commissions-past",
    component: () => import("@/modules/commission/views/Commission.vue"),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: CREATE_ACTION,
    },
    props: {
      periodType: commissionPeriod.PAST,
    },
  },
  {
    path: "/comissoes/extrato",
    name: "commissions-receipt",
    component: () => import("@/modules/commission/views/CommissionsCompiledReceipt.vue"),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: CREATE_ACTION,
    },
    props: true,
  },  
  {
    path: "/comissoes/futuras",
    name: "commissions-future",
    component: () => import("@/modules/commission/views/Commission.vue"),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: CREATE_ACTION,
    },
    props: {
      periodType: commissionPeriod.FUTURE,
    },
  },
  {
    path: "/comissoes/:year?/:month?",
    name: "commissions",
    component: () => import("@/modules/commission/views/Commission.vue"),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: CREATE_ACTION,
    },
    props: {
      periodType: commissionPeriod.DATE,
    },
  },
  {
    path: "/lancamentos/:year?/:month?",
    name: "financial-adjustments-per-month",
    component: () => import("@/modules/commission/views/AdjustmentsPerMonth.vue"),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: CREATE_ACTION,
    },
  },
  {
    path: "/receitas",
    name: "commission-management",
    component: () =>
      import("@/modules/commission/views/CommissionManagement.vue"),
    meta: {
      resource: COMMISSION_MANAGEMENT,
      action: MANAGE_ACTION,
      pageTitle: "Receitas",
    },
  },
];
