import _ from "lodash";
import * as types from "./types";

export default {
  [types.MUTATE_APPOINTMENTS_FROM_TODAY]: (state, payload) => {
    state.appointmentsFromToday = payload;
  },
  [types.MUTATE_BIRTHDAYS_THIS_MONTH]: (state, payload) => {
    state.birthdaysThisMonth = payload;
  },
  [types.MUTATE_CUSTOMERS_REMINDER]: (state, payload) => {
    state.customersReminder = payload;
  },
  [types.MUTATE_LEADS_REMINDER]: (state, payload) => {
    state.leadsReminder = payload;
  },
  [types.MUTATE_BANNERS]: (state, payload) => {
    state.banners = payload;
  },
};
