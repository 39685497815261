import * as types from './types'
export default {
  [types.NOTIFICATIONS]: state => {
    return state.notifications
  },
  [types.NOTIFICATION_LIST]: state => {
    return state.notificationList
  },
  [types.UNREAD_NOTIFICATIONS]: state => {
    return state.unreadNotifications
  },
  [types.NOTIFICATION_SIDEBAR]: state => {
    return state.notificationSidebar
  },
  [types.NOTIFICATIONS_COUNTER]: state => {
    return state.notificationsCounter
  }
}
