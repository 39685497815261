// Getters
export const NOTIFICATIONS = 'notification/NOTIFICATIONS'
export const NOTIFICATION_LIST = 'notification/NOTIFICATION_LIST'
export const UNREAD_NOTIFICATIONS = 'notification/UNREAD_NOTIFICATIONS'
export const NOTIFICATION_SIDEBAR = 'notification/NOTIFICATION_SIDEBAR'
export const NOTIFICATIONS_COUNTER = 'notification/NOTIFICATIONS_COUNTER'


// Mutations
export const MUTATE_NOTIFICATIONS =  'notification/MUTATE_NOTIFICATIONS'
export const MUTATE_NOTIFICATION_LIST =  'notification/MUTATE_NOTIFICATION_LIST'
export const MUTATE_UNREAD_NOTIFICATIONS = 'notification/MUTATE_UNREAD_NOTIFICATIONS'
export const MUTATE_NOTIFICATION_SIDEBAR = 'notification/MUTATE_EDIT_NOTIFICATION_SIDEBAR'
export const MUTATE_NOTIFICATIONS_COUNTER = 'notification/MUTATE_NOTIFICATIONS_COUNTER'

// Actions
export const GET_NOTIFICATIONS = 'notification/GET_NOTIFICATIONS'
export const GET_NOTIFICATION_LIST = 'notification/GET_NOTIFICATION_LIST'
export const GET_UNREAD_NOTIFICATIONS = 'notification/GET_UNREAD_NOTIFICATIONS'
export const OPEN_NOTIFICATION_SIDEBAR = 'notification/OPEN_NOTIFICATION_SIDEBAR'
export const READ_NOTIFICATION = 'notification/READ_NOTIFICATION'
export const READ_ALL_NOTIFICATIONS = 'notification/READ_ALL_NOTIFICATIONS'
export const READ_NOTIFICATIONS = 'notification/READ_NOTIFICATIONS'
export const UPDATE_NOTIFICATION_STATUS = 'notification/UPDATE_NOTIFICATION_STATUS'
export const GET_NOTIFICATIONS_COUNTER = 'notification/GET_NOTIFICATIONS_COUNTER'