import {
  FINANCIAL_ADJUSTMENTS,
  READ_ACTION
} from '@/constants/resources'
  

export default [
  {
    path: '/ajustes-financeiros',
    name: 'financial-adjustments-list',
    component: () => import('@/modules/financial_adjustment/views/FinancialAdjustmentsList.vue'),
    meta: {
      resource: FINANCIAL_ADJUSTMENTS,
      action: READ_ACTION,
      pageTitle: 'Lançamentos financeiros'
    },
  }
]
  