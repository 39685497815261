import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  searchDigitalFranchiseResult: [],
  searchPhysicalFranchiseResult: [],
  responsables: [],
  physicalResponsables: [],
  newFranchiseSidebar: {
    id: null,
    franchise: null,
    franchise_visible: null,
    responsable: null,
    consultant_id: null,
    edit: false,
    visible: false,
    visualizar: false,
    saveAction: null,
  },
  newPhysicalFranchiseSidebar: {
    id: null,
    franchise: null,
    responsable: null,
    consultant_id: null,
    edit: false,
    visible: false,
    visualizar: false,
    saveAction: null,
  },
  tableColumns: [
    { key: "visible", label: "Visível no Faturômetro e BI" },
    { key: "name", label: "Nome", sortable: true },
    { key: "digital_franchise_owners", label: "Responsáveis", sortable: true },
    { key: "actions", label: "Ações", stickyColumn: true }, //Ações
  ],
  tableColumnsPhysical: [
    { key: "name", label: "Nome", sortable: true },
    { key: "physical_franchise_owners", label: "Responsáveis", sortable: true },
    { key: "actions", label: "Ações", stickyColumn: true }, //Ações
  ],
};

export default {
  state,
  actions,
  getters,
  mutations,
};
