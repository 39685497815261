// Getters
export const ABILITIES = "access_managment/ABILITIES";
export const DEPARTMENT_USERS = "access_managment/DEPARTMENT_USERS";
export const PROFILES_RESULT = "access_managment/PROFILES_RESULT";

// Mutations
export const MUTATE_ABILITIES = "access_managment/MUTATE_ABILITIES";
export const MUTATE_DEPARTMENT_USERS =
  "access_managment/MUTATE_DEPARTMENT_USERS";
export const MUTATE_PROFILES_RESULT = "access_managment/MUTATE_PROFILES_RESULT";

// Actions
export const SAVE_ACCESS_PROFILE = "access_managment/SAVE_ACCESS_PROFILE";
export const GET_ABILITIES = "access_managment/GET_ABILITIES";
export const HAS_ADMIN_DEPARTMENT = "access_managment/HAS_ADMIN_DEPARTMENT";
export const GET_ACCESS_PROFILE = "access_managment/GET_ACCESS_PROFILE";
export const GET_ACCESS_PROFILE_USERS =
  "access_managment/GET_ACCESS_PROFILE_USERS";
export const SAVE_ACCESS_PROFILE_USERS =
  "access_managment/SAVE_ACCESS_PROFILE_USERS";
export const GET_DEPARTMENT_USERS = "access_managment/GET_DEPARTMENT_USERS";
export const SEARCH_PROFILES = "access_managment/SEARCH_PROFILES";
export const INACTIVATE_PROFILE = "access_managment/INACTIVATE_PROFILE";
