import axios from 'axios'
import router from '@/router'
import * as types from './types'

export default {
  [types.GET_USER_DATA_CREDENTIALS]: ({ commit, dispatch }, payload) => {
    return axios.post('/api/users/login/by_token', payload)
      .then(resp => {
        commit(types.MUTATE_TOKEN, resp.data)
        dispatch(types.GET_USER_DATA, { hasRedirect: true })
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.LOGIN]: ({ commit, dispatch }, payload) => {
    return axios.post('/api/users/login', payload)
      .then(resp => {
        commit(types.MUTATE_TOKEN, resp.data)
        dispatch(types.GET_USER_DATA, { hasRedirect: true })
        router.push({ name: 'dashboard' })
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.RECOVER_PASSWORD]: ({ }, payload) => {
    return axios.post('/api/users/request_password', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SET_NEW_PASSWORD]: ({ }, payload) => {
    return axios.post('/api/users/new_password', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_USER_DATA]: ({ commit }, payload) => {
    return axios.get('/api/users/profile', payload)
      .then(resp => {
        commit(types.MUTATE_TOKEN, resp.data)
        if (payload?.hasRedirect) {
          let params = {}
          const back_url = router.currentRoute.query?.back_url
          if (router.currentRoute.query?.id) {
            params.id = router.currentRoute.query?.id
          }
          if (router.currentRoute.query?.appointment) {
            params.appointment = router.currentRoute.query?.appointment
          }
          if (back_url) {
            router.push({
              name: back_url,
              params
            })
          }
        }
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.LOGOUT]: ({ commit }) => {
    return axios.post('/api/users/logout')
      .then(resp => {
        commit(types.MUTATE_TOKEN_LOGOUT)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CUSTOMER_CREDENTIALS]: ({ commit }, payload) => {
    return axios.post('/api/login/by_customer_token', payload)
      .then(resp => {
        commit(types.MUTATE_TOKEN, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_DARK_MODE]: ({ }, payload) => {
    return axios.patch('/api/users/dark_mode', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.CHECK_CONTRACT_REPORT_PENDENCIES]: ({ }) => {
    return axios.get('/api/users/contract_report_pendencies')
      .then(resp => {
        if (resp.data?.id) {
          router.push({ name: 'contract-save', params: { 'blocked': true, 'appointment': resp.data.id } })
        }
      })
  },
  [types.CHECK_APPOINTMENT_REPORT_PENDENCIES]: ({ }) => {
    return axios.get('/api/users/appointment_report_pendencies')
      .then(resp => {
        if (resp.data?.id) {
          router.push({ name: 'appointments', params: { 'blocked': true, 'id': resp.data.id } })
        }
      })
  },
  [types.CHECK_COMPANY_PENDENCIES]: ({ commit, state }) => {
    var today = new Date();
    if(state.company_pendencies_viewed === today.toDateString()){ return }
    return axios.get('/api/users/company_pendencies')
      .then(resp => {
        if (resp.data?.has_company_data_pendencies) {
          commit(types.MUTATE_COMPANY_PENDENCIES)
          router.push({ 
            name: 'consultant-company-info-save', 
            params: { 
              company_data_pendency: true, 
              user: resp.data.user, 
              id: resp.data.user.consultant_id 
            } })
        } else if (resp.data?.has_company_contract_pendencies) {
          commit(types.MUTATE_COMPANY_PENDENCIES)
          router.push({ 
            name: 'consultant-company-info-save', 
            params: { 
              company_contract_pendency: true,
              user: resp.data.user, 
              id: resp.data.user.consultant_id 
            } 
          })
        }
      })
  },
  [types.GET_APPIFY_LINK]: ({ }, payload) => {
    return axios.get('/api/users/link_appify', { params: payload })
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.CHECK_SYSTEM_VERSION_NOTIFICATIONS]: ({ commit }, payload) => {
    return axios.get('/api/users/announcement_notifications')
      .then(resp => {
        commit(types.MUTATE_SYSTEM_VERSION_NOTIFICATIONS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.READ_SYSTEM_NOTIFICATION]: ({ commit }, payload) => {
    return axios.post(`/api/announcements/read_notification/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
