import axios from 'axios'
import * as types from './types'

export default {
  [types.SEARCH_OVERDUE_SIGNATURES]: ({ commit }, payload) => {
    const resource = `/api/reports/overdue_signature?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_OVERDUE_SIGNATURES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.EXPORT_OVERDUE_SIGNATURES]: ({ commit }, payload) => {
    return axios.post(
      '/api/reports/export_overdue_signature',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'assinaturas_inadimplentes.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_INACTIVE_PN_CONTRACTS]: ({ commit }, payload) => {
    const resource = `/api/reports/inactive_pn_contracts?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_INACTIVE_PN_CONTRACTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.EXPORT_INACTIVE_PN_CONTRACTS]: ({ commit }, payload) => {
    return axios.post(
      '/api/reports/export_inactive_pn_contracts',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'contratos_consultores_inativos.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_INACTIVE_PN_CUSTOMERS]: ({ commit }, payload) => {
    const resource = `/api/reports/inactive_pn_customers?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_INACTIVE_PN_CUSTOMERS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.EXPORT_INACTIVE_PN_CUSTOMERS]: ({ commit }, payload) => {
    return axios.post(
      '/api/reports/export_inactive_pn_customers',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'clientes_consultores_inativos.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.EXPORT_CONTRACTS_PAYMENTS_COMISSIONS]: ({ commit }, payload) => {
    return axios.post(
      '/api/reports/export_contracts_payments_comissions',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `contratos_pagamentos_comissoes-${payload.year}-${payload.month}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.EXPORT_INVOICES_SUMMARY]: ({ commit }, payload) => {
    return axios.post(
      '/api/reports/export_invoice_summary',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `resumo_faturamento-${payload.year}-${payload.month}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.EXPORT_AGENCY_COMMISSIONS]: ({ commit }, payload) => {
    return axios.post(
      '/api/reports/export_agency_commissions',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download',`comissoes_agenciamentos-${payload.year}-${payload.month}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
