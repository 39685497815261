import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  searchResult: [],
  searchTotalizers: undefined,
  leadTypes: [],
  leadPnTypes: [],
  newLeadSidebar: {
    id: undefined,
    leadType: undefined,
    visible: false,
    saveAction: undefined,
  },
  occupations: [],
  schoolLevels: [],
  incomes: [],
  goals: [],
  origins: [],
  leadPotentials: [],
  settingsTableCustomerLeads: undefined,
  settingsTableSidebar: {
    id: undefined,
    description: undefined,
    currentPage: undefined,
    contract_id: undefined,
    visible: false,
  },
  importLeadsSidebar: {
    file: undefined,
    visible: false,
  },
  tableColumns: [
    { key: "sequence", label: "Prio.", stickyColumn: true, sortable: true }, //Prioridade
    { key: "customer_name", label: "Nome", stickyColumn: true }, //Nome e Telefone
    { key: "customer_status_name", label: "Status", sortable: true }, //Status
    { key: "call_reminder", label: "Lembrar", sortable: true }, //Lembrar
    { key: "income_name", label: "Faixa de Renda", sortable: true }, //Faixa de renda
    {
      key: "monthly_savings",
      label: "Poupa mensalmente",
      fixed: 1,
      sortable: true,
    }, //Poupa
    { key: "goals", label: "Objetivo", sortable: true }, //Objetivo
    { key: "consultant_name", label: "Consultor Responsável", sortable: true }, //Consultor Responsável
    { key: "franchise_name", label: "Franquia", sortable: true }, //Franquia
    { key: "email", label: "Email", sortable: true }, //Email
    { key: "anniversary_date", label: "Aniversário", sortable: true }, //Aniversário
    { key: "last_interview_date", label: "Última reunião", sortable: true }, //Última Reunião
    { key: "created_at", label: "Data de criação", fixed: 1, sortable: true }, //Data de criação
    { key: "last_status_date", label: "Atualizado", sortable: true }, //Atualização
    { key: "origin", label: "Origem", sortable: true }, 
    {
      key: "customer_campaign_name",
      label: "Campanha",
      sortable: true,
    }, //Campanha
    {
      key: "customer_reference_name",
      label: "Recomendado por",
      sortable: true,
    }, //Recomendado por
    { key: "occupation", label: "Profissão", sortable: true }, //Profissão
    { key: "actions", label: "Ações", stickyColumn: true }, //Ações
  ],
  tableColumnsLeadsPotentials: [
    { key: "sequence", label: "Prio.", stickyColumn: true, sortable: true },
    { key: "lead_name", label: "Nome", stickyColumn: true },
    { key: "email", label: "Email", sortable: true },
    { key: "consultant_name", label: "Consultor Responsável", sortable: true },
    { key: "leader_franchise_name", label: "Franquia", sortable: true },
    { key: "call_reminder", label: "Lembrar", sortable: true },
    { key: "created_at", label: "Data de criação", fixed: 1, sortable: true },
    { key: "customer_status_name", label: "Status", sortable: true },
    { key: "consultant_lead_potential", label: "Potencial", sortable: true },
    { key: "school_level_name", label: "Formação", sortable: true },
    { key: "goals", label: "Objetivo", sortable: true },
    { key: "source", label: "Origem", sortable: true },
    {
      key: "customer_reference_name",
      label: "Recomendado por",
      sortable: true,
    },
    { key: "actions", label: "Ações", stickyColumn: true },
  ],
  sequenceColumnEnabled: true,
  openSettingsTableAprovalSidebar: false,
  statuses: [],
  leadSearchPotentials: [],
  settingsTablePotentialLeads: undefined,
  settingsTablePotentialLeadsSidebar: {
    id: undefined,
    description: undefined,
    currentPage: undefined,
    contract_id: undefined,
    visible: false,
  },
  sequenceColumnPotentialLeadEnabled: true,
  sources: [],
  leadStatus: [],
  campaignsList: [],
  newCampaignSidebar: {
    saveAction: undefined,
    visible: false
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
};
