import * as types from './types'

export default {
  [types.MUTATE_WITHDRAWALS]: (state, payload) => {
    state.withdrawals = payload
  },
  [types.MUTATE_WITHDRAWAL_REQUEST_SIDEBAR]: (state, payload) => {
    state.withdrawalRequestSidebar = { ...state.withdrawalRequestSidebar, ...payload }
  },
  [types.MUTATE_WITHDRAWAL_DETAILS_SIDEBAR]: (state, payload) => {
    state.withdrawalDetailsSidebar = { ...state.withdrawalDetailsSidebar, ...payload }
  },
}
