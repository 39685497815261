import axios from "axios";
import * as types from "./types";
import { unset } from "lodash";

export default {
  [types.GET_ABILITIES]: ({ commit }, payload) => {
    return axios
      .get("/api/access_management/abilities", payload)
      .then((resp) => {
        commit(types.MUTATE_ABILITIES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_ACCESS_PROFILE]: ({}, payload) => {
    let save = axios.post;
    let route = "/api/access_management/profile";
    if (payload.id) {
      save = axios.put;
      route = `/api/access_management/profile/${payload.id}`;
    }
    unset(payload.id);
    return save(route, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.HAS_ADMIN_DEPARTMENT]: ({}, payload) => {
    return axios
      .get(`/api/access_management/has_admin_profile/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_ACCESS_PROFILE]: ({}, payload) => {
    return axios
      .get(`/api/access_management/profile/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_ACCESS_PROFILE_USERS]: ({}, payload) => {
    return axios
      .get(`/api/access_management/users/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_ACCESS_PROFILE_USERS]: ({}, payload) => {
    return axios
      .post("/api/access_management/users", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_DEPARTMENT_USERS]: ({ commit }, payload) => {
    return axios
      .get(`/api/access_management/users_by_department/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_DEPARTMENT_USERS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_PROFILES]: ({ commit }, payload) => {
    const resource = `/api/access_management/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_PROFILES_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.INACTIVATE_PROFILE]: ({}, payload) => {
    return axios
      .put(`/api/access_management/profile/inactivate/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
