import {
  READ_ACTION,
  CREATE_ACTION,
  WITHDRAWAL_RESOURCE
} from '@/constants/resources'

export default [
  {
    path: '/pagamentos',
    name: 'withdrawal-list',
    component: () => import('@/modules/withdrawal/views/WithdrawalList.vue'),
    meta: {
      resource: WITHDRAWAL_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Meus pagamentos'
    },
  }
]
