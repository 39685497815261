// Getters
export const FINANCIAL_ADJUSTMENTS_SEARCH_RESULT = 'financial_adjustment/FINANCIAL_ADJUSTMENTS_SEARCH_RESULT'
export const FINANCIAL_ADJUSTMENT_REASONS = 'financial_adjustment/FINANCIAL_ADJUSTMENT_REASONS'
export const FINANCIAL_ADJUSTMENT_CREDIT_TYPES = 'financial_adjustment/FINANCIAL_ADJUSTMENT_CREDIT_TYPES'
export const FINANCIAL_ADJUSTMENT_TYPES = 'financial_adjustment/FINANCIAL_ADJUSTMENT_TYPES'
export const FINANCIAL_ADJUSTMENT_TOTALIZER = 'financial_adjustment/FINANCIAL_ADJUSTMENT_TOTALIZER'
export const SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR = 'financial_adjustment/SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR'
export const FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR = 'financial_adjustment/FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR'
export const FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT = 'financial_adjustment/FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT'


// Mutations
export const MUTATE_FINANCIAL_ADJUSTMENTS_SEARCH_RESULT = 'financial_adjustment/MUTATE_FINANCIAL_ADJUSTMENTS_SEARCH_RESULT'
export const MUTATE_FINANCIAL_ADJUSTMENT_REASONS = 'financial_adjustment/MUTATE_FINANCIAL_ADJUSTMENT_REASONS'
export const MUTATE_FINANCIAL_ADJUSTMENT_CREDIT_TYPES = 'financial_adjustment/MUTATE_FINANCIAL_ADJUSTMENT_CREDIT_TYPES'
export const MUTATE_FINANCIAL_ADJUSTMENT_TYPES = 'financial_adjustment/MUTATE_FINANCIAL_ADJUSTMENT_TYPES'
export const MUTATE_FINANCIAL_ADJUSTMENT_TOTALIZER = 'financial_adjustment/MUTATE_FINANCIAL_ADJUSTMENT_TOTALIZER'
export const MUTATE_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR = 'financial_adjustment/MUTATE_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR'
export const MUTATE_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR = 'financial_adjustment/MUTATE_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR'
export const MUTATE_FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT = 'financial_adjustment/MUTATE_FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT'

// Actions
export const OPEN_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR = 'financial_adjustment/OPEN_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR'
export const GET_FINANCIAL_ADJUSTMENT = 'financial_adjustment/GET_FINANCIAL_ADJUSTMENT'
export const SEARCH_FINANCIAL_ADJUSTMENTS = 'financial_adjustment/SEARCH_FINANCIAL_ADJUSTMENTS'
export const SAVE_FINANCIAL_ADJUSTMENT  = 'financial_adjustment/SAVE_FINANCIAL_ADJUSTMENT'
export const DELETE_FINANCIAL_ADJUSTMENT = 'financial_adjustment/DELETE_FINANCIAL_ADJUSTMENT'
export const GET_FINANCIAL_ADJUSTMENT_REASONS = 'financial_adjustment/GET_FINANCIAL_ADJUSTMENT_REASONS'
export const GET_FINANCIAL_ADJUSTMENT_CREDIT_TYPES = 'financial_adjustment/GET_FINANCIAL_ADJUSTMENT_CREDIT_TYPES'
export const GET_FINANCIAL_ADJUSTMENT_TYPES = 'financial_adjustment/GET_FINANCIAL_ADJUSTMENT_TYPES'
export const GET_FINANCIAL_ADJUSTMENT_TOTALIZER = 'financial_adjustment/GET_FINANCIAL_ADJUSTMENT_TOTALIZER'
export const OPEN_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR = 'financial_adjustment/OPEN_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR'
export const GET_FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT = 'financial_adjustment/GET_FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT'