import * as types from './types'

export default {
  [types.PRODUCTS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.NETWORK_DISTRIBUTIONS]: state => {
    return state.networkDistributions
  }
}
