import _ from "lodash";
import * as types from "./types";

export default {
  [types.MUTATE_NEW_TO_DO_SIDEBAR]: (state, payload) => {
    state.newToDoSidebar = { ...state.newToDoSidebar, ...payload }
  },
  [types.MUTATE_TASKS_FROM_TODAY]: (state, payload) => {
    state.tasksFromToday = payload;
  },
  [types.MUTATE_TODOS_INBOX_SEARCH_RESULT]: (state, payload) => {
    state.todosInboxSearchResult = payload;
  },
  [types.MUTATE_TO_DO_DETAILS_SIDEBAR]: (state, payload) => {
    state.toDoDetailsSidebar = { ...state.toDoDetailsSidebar, ...payload }
  },
  [types.MUTATE_TODOS_FLOAT_SIDEBAR]: (state, payload) => {
    state.todosFloatSidebar = payload
  },
};
