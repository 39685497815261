import _ from 'lodash'
import axios from 'axios'
import ability from '@/libs/acl/ability'
import * as types from './types'
import { getCredentials, setCredentials, deleteCredentials, transformAbilities } from '@/helpers/credentials'
import { ADMIN_CREDENTIALS_STORAGE_KEY } from '@/constants/auth'

export default {
  [types.MUTATE_TOKEN]: (state, payload) => {
    const {
      token,
      user,
      abilities,
      consultant_level,
    } = payload
    if (token) {
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
      setCredentials(
        ADMIN_CREDENTIALS_STORAGE_KEY,
        { token, user, abilities, consultant_level }
      )
    } else {
      const cookie = getCredentials(ADMIN_CREDENTIALS_STORAGE_KEY)
      setCredentials(
        ADMIN_CREDENTIALS_STORAGE_KEY,
        { ...cookie, user, abilities, consultant_level }
      )
    }
    state.user = user
    state.consultant_level = consultant_level
    const transformedAbilities = transformAbilities(abilities)
    state.abilities = transformedAbilities
    ability.update(transformedAbilities)
  },
  [types.MUTATE_TOKEN_LOGOUT]: (state) => {
    deleteCredentials(ADMIN_CREDENTIALS_STORAGE_KEY)
    state.user = undefined
    state.abilities = undefined
    state.consultant_level = undefined
    state.report_pendencies = undefined
    ability.update([])
  },
  [types.MUTATE_COMPANY_PENDENCIES]: (state) => {
    var today = new Date();
    state.company_pendencies_viewed = today.toDateString()
  },
  [types.MUTATE_SYSTEM_VERSION_NOTIFICATIONS]: (state, payload) => {
    state.announcement_notifications = payload
  }
}
