import * as types from './types'

export default {
  [types.MUTATE_PARTNER_COMPANIES_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_PRODUCTS]: (state, payload) => {
    state.products = payload
  },
  [types.MUTATE_PARTNER_COMPANY_AGENT_TYPES]: (state, payload) => {
    state.agentTypes = payload
  }
}
