// Getters
export const CURRENT_COMMISSIONS_SEARCH_RESULT =
  "commission/CURRENT_COMMISSIONS_SEARCH_RESULT";
export const HISTORICAL_COMMISSIONS_SEARCH_RESULT =
  "commission/HISTORICAL_COMMISSIONS_SEARCH_RESULT";
export const PAST_FUTURE_COMMISSIONS_SEARCH_RESULT =
  "commission/PAST_FUTURE_COMMISSIONS_SEARCH_RESULT";
export const COMMISSION_PRODUCTS = "commission/COMMISSION_PRODUCTS";
export const COMMISSION_MANAGEMENT_SEARCH_RESULT =
  "commission/COMMISSION_MANAGEMENT_SEARCH_RESULT";
export const PAYMENT_CONCILIATORS = "commission/PAYMENT_CONCILIATORS";
export const FINANCIAL_SOLUTIONS = "commission/FINANCIAL_SOLUTIONS";
export const PAYMENT_PRODUCTS = "commission/PAYMENT_PRODUCTS";
export const GET_COMMISSION_MANAGEMENT_TOTALIZER =
  "commission/GET_COMMISSION_MANAGEMENT_TOTALIZER";
export const CHART_COMMISSION_MANAGEMENT =
  "commission/CHART_COMMISSION_MANAGEMENT";

// Mutations
export const MUTATE_CURRENT_COMMISSIONS_SEARCH_RESULT =
  "commission/MUTATE_CURRENT_COMMISSIONS_SEARCH_RESULT";
export const MUTATE_HISTORICAL_COMMISSIONS_SEARCH_RESULT =
  "commission/MUTATE_HISTORICAL_COMMISSIONS_SEARCH_RESULT";
export const MUTATE_PAST_FUTURE_COMMISSIONS_SEARCH_RESULT =
  "commission/MUTATE_PAST_FUTURE_COMMISSIONS_SEARCH_RESULT";
export const MUTATE_COMMISSION_PRODUCTS =
  "commission/MUTATE_COMMISSION_PRODUCTS";
export const MUTATE_COMMISSION_MANAGEMENT_SEARCH_RESULT =
  "commission/MUTATE_COMMISSION_MANAGEMENT_SEARCH_RESULT";
export const MUTATE_PAYMENT_CONCILIATORS =
  "commission/MUTATE_PAYMENT_CONCILIATORS";
export const MUTATE_FINANCIAL_SOLUTIONS =
  "commission/MUTATE_FINANCIAL_SOLUTIONS";
export const MUTATE_PAYMENT_PRODUCTS = "commission/MUTATE_PAYMENT_PRODUCTS";
export const MUTATE_COMMISSION_MANAGEMENT_TOTALIZER =
  "commission/MUTATE_COMMISSION_MANAGEMENT_TOTALIZER";
export const MUTATE_CHART_COMMISSION_MANAGEMENT =
  "commission/MUTATE_CHART_COMMISSION_MANAGEMENT";

// Actions
export const SEARCH_CURRENT_COMMISSIONS =
  "commission/SEARCH_CURRENT_COMMISSIONS";
export const SEARCH_HISTORICAL_COMMISSIONS =
  "commission/SEARCH_HISTORICAL_COMMISSIONS";
export const SEARCH_PAST_FUTURE_COMMISSIONS =
  "commission/SEARCH_PAST_FUTURE_COMMISSIONS";
export const GET_COMMISSION_PRODUCTS = "commission/GET_COMMISSION_PRODUCTS";
export const GET_HAS_COMISSIONS_REPORT = "commission/GET_HAS_COMISSIONS_REPORT";
export const SEARCH_COMMISSION_MANAGEMENT =
  "commission/SEARCH_COMMISSION_MANAGEMENT";
export const GET_PAYMENT_CONCILIATORS = "commission/GET_PAYMENT_CONCILIATORS";
export const GET_FINANCIAL_SOLUTIONS = "commission/GET_FINANCIAL_SOLUTIONS";
export const GET_PAYMENT_PRODUCTS = "commission/GET_PAYMENT_PRODUCTS";
export const COMMISSION_MANAGEMENT_TOTALIZER =
  "commission/COMMISSION_MANAGEMENT_TOTALIZER";
export const SEARCH_CHART_COMMISSION_MANAGEMENT =
  "commission/SEARCH_CHART_COMMISSION_MANAGEMENT";
export const EXPORT_CONTRACTS_COMISSIONS = "report/EXPORT_CONTRACTS_COMISSIONS";
