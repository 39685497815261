import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  withdrawals: [],
  withdrawalRequestSidebar: {
    visible: false,
    saveAction: () => {}
  },
  withdrawalDetailsSidebar:{
    id: undefined,
    visible: false
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
