import axios from "axios";
import * as types from "./types";

export default {
  [types.GET_EXPENSES_BY_COST_CENTER]: ({ commit }, payload) => {
    return axios
      .get(`/api/expenses/total-by-cost-center/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_TOTAL_EXPENSES_BY_COST_CENTER, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_EXPENSES]: ({ commit }, payload) => {
    const resource = `/api/expenses/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_EXPENSES_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_EXPENSE]: ({ commit }, payload) => {
    return axios
      .delete(`/api/expenses/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_EXPENSE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_EXPENSE_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.SAVE_EXPENSE]: ({}, payload) => {
    let save = axios.post;
    let route = "/api/expenses";
    if (payload.id) {
      save = axios.put;
      route = `/api/expenses/${payload.id}`;
    }
    return save(route, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_YEAR_INCOME_CHART_DATA]: ({ commit }, payload) => {
    return axios
      .get(`/api/expenses/yearly-incomes-chart-data/${payload}`,)
      .then((resp) => {
        commit(types.MUTATE_YEAR_INCOME_CHART_DATA, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_EXPENSE]: ({ commit }, payload) => {
    return axios
      .get(`/api/expenses/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_COMPETENCIES_EXPENSES]: ({ commit }, payload) => {
    return axios
      .get(`/api/expenses/monthly-by-categories/${payload}`,)
      .then((resp) => {
        commit(types.MUTATE_COMPETENCIES_EXPENSES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
