// Getters
export const TOTAL_EXPENSES_BY_COST_CENTER =
  "accounting_management/TOTAL_EXPENSES_BY_COST_CENTER";
export const EXPENSES_SEARCH_RESULT =
  "accounting_management/EXPENSES_SEARCH_RESULT";
export const SAVE_EXPENSE_SIDEBAR =
  "accounting_management/SAVE_EXPENSE_SIDEBAR";
export const COMPETENCIES_EXPENSES =
  "accounting_management/COMPETENCIES_EXPENSES";
export const EXPENSES_SEARCH_YEAR = "accounting_management/EXPENSES_SEARCH_YEAR";

// Mutations
export const MUTATE_TOTAL_EXPENSES_BY_COST_CENTER =
  "accounting_management/MUTATE_TOTAL_EXPENSES_BY_COST_CENTER";
export const MUTATE_EXPENSES_SEARCH_RESULT =
  "accounting_management/MUTATE_EXPENSES_SEARCH_RESULT";
export const MUTATE_EXPENSE_SIDEBAR =
  "accounting_management/MUTATE_EXPENSE_SIDEBAR";
export const MUTATE_YEAR_INCOME_CHART_DATA =
  "accounting_management/MUTATE_YEAR_INCOME_CHART_DATA";
export const MUTATE_COMPETENCIES_EXPENSES =
  "accounting_management/MUTATE_COMPETENCIES_EXPENSES";
export const MUTATE_EXPENSES_SEARCH_YEAR = "accounting_management/MUTATE_EXPENSES_SEARCH_YEAR";

// Actions
export const GET_EXPENSES_BY_COST_CENTER =
  "accounting_management/GET_EXPENSES_BY_COST_CENTER";
export const SEARCH_EXPENSES = "accounting_management/SEARCH_EXPENSES";
export const DELETE_EXPENSE = "accounting_management/DELETE_EXPENSE";
export const OPEN_EXPENSE_SIDEBAR =
  "accounting_management/OPEN_EXPENSE_SIDEBAR";
export const SAVE_EXPENSE = "accounting_management/SAVE_EXPENSE";
export const YEAR_INCOME_CHART_DATA =
  "accounting_management/YEAR_INCOME_CHART_DATA";
export const GET_YEAR_INCOME_CHART_DATA =
  "accounting_management/GET_YEAR_INCOME_CHART_DATA";
export const GET_EXPENSE = "accounting_management/GET_EXPENSE";
export const GET_COMPETENCIES_EXPENSES =
  "accounting_management/GET_COMPETENCIES_EXPENSES";
