import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BCardText,
  BMediaAside,
  BMediaBody,
  BCardTitle,
  BButtonGroup,
  BTooltip,
  BFormCheckbox,
  BCarousel,
  BCarouselSlide,
  BFormRadio,
} from "bootstrap-vue";

Vue.component(FeatherIcon.name, FeatherIcon);
// Vue.component("ToastificationContent", ToastificationContent);
Vue.component("BCard", BCard);
Vue.component("BRow", BRow);
Vue.component("BCol", BCol);
Vue.component("BButton", BButton);
Vue.component("BTable", BTable);
Vue.component("BPagination", BPagination);
Vue.component("BButtonGroup", BButtonGroup);
Vue.component("BTooltip", BTooltip);
Vue.component("BFormCheckbox", BFormCheckbox);
Vue.component("BFormRadio", BFormRadio);
Vue.component("BMedia", BMedia);
Vue.component("BFormInput", BFormInput);
Vue.component("BAvatar", BAvatar);
Vue.component("BLink", BLink);
Vue.component("BBadge", BBadge);
Vue.component("BDropdown", BDropdown);
Vue.component("BDropdownItem", BDropdownItem);
Vue.component("BCardHeader", BCardHeader);
Vue.component("BCardBody", BCardBody);
Vue.component("BSpinner", BSpinner);
Vue.component("BCardText", BCardText);
Vue.component("BMediaAside", BMediaAside);
Vue.component("BMediaBody", BMediaBody);
Vue.component("BCardTitle", BCardTitle);
Vue.component("BCarousel", BCarousel);
Vue.component("BCarouselSlide", BCarouselSlide);
