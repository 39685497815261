import axios from 'axios'
import * as types from './types'

export default {
  [types.SEARCH_CONSULTANTS]: ({ commit }, payload) => {
    const resource = `/api/consultants/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_CONSULTANTS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANT]: ({}, payload) => {
    return axios.get(`/api/consultants/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CEP]: ({ }, payload) => {
    return axios.get(`/api/addresses/by_cep/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      }).catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANT_INFO]: ({}, payload) => {
    return axios.get(`/api/consultants/info`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_CONSULTANT]: ({}, payload) => {
    return axios.post('/api/consultants', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_CONSULTANT_BASIC_DATA]: ({}, payload) => {
    return axios.put(`/api/consultants/${payload.id}/basic_information`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_CONSULTANT_COMPANY_DATA]: ({}, payload) => {
    return axios.post('/api/consultants/company_information', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_CONSULTANT]: ({}, payload) => {
    return axios.delete(`/api/consultants/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_DETAILS_GENERAL_INFORMATION]: ({ commit }, payload) => {
    commit(types.CLEAR_DETAILS_GENERAL_INFORMATION)
    return axios.get(`/api/consultants/${payload}/details/general_information`)
      .then(resp => {
        commit(types.MUTATE_DETAILS_GENERAL_INFORMATION, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_DETAILS_COMPANY_DATA]: ({ commit }, payload) => {
    return axios.get(`/api/consultants/${payload}/details/company`)
      .then(resp => {
        commit(types.MUTATE_DETAILS_COMPANY_DATA, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_DETAILS_NETWORK_DATA]: ({ commit }, payload) => {
    return axios.get(`/api/consultants/${payload}/details/network`)
      .then(resp => {
        commit(types.MUTATE_DETAILS_NETWORK_DATA, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.UPDATE_CONSULTANT_CONTRACT_SIGNATURE_DATE]: ({ commit }, payload) => {
    return axios.patch(`/api/consultants/${payload}/document/contract_date`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.UPDATE_CONSULTANT_CONTRACT_TERMINATION_SIGNATURE_DATE]: ({ commit }, payload) => {
    return axios.patch(`/api/consultants/${payload}/document/contract_termination_date`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_NEW_CONSULTANT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_CONSULTANT_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_TRANSFERRED_CONSULTANTS]: ({ commit }, payload) => {
    const resource = `/api/consultants/transferred?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_TRANSFERRED_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.TRANSFER_CONSULTANTS]: ({}, payload) => {
    return axios.post(`/api/consultants/transfer_consultants`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_TRANSFERABLE_CONSULTANTS]: ({commit}, payload) => {
    return axios.get(`/api/consultants/transferable`)
      .then(resp => {
        commit(types.MUTATE_TRANSFERABLE_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_DIRECT_TEAM]: ({ commit }, payload) => {
    return axios.get(`/api/consultants/${payload}/direct_team`)
      .then(resp => {
        commit(types.MUTATE_DIRECT_TEAM, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_CHANGE_CONSULTANT_ROLE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_CHANGE_CONSULTANT_ROLE_SIDEBAR, { ...payload, visible: true })
  },
  [types.CHANGE_CONSULTANT_ROLE]: ({}, payload) => {
    return axios.post(`/api/consultants/change_role`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ROLE_CHANGE_IS_VALID]: ({}, payload) => {
    return axios.post(`/api/consultants/validate_pnce_consultants`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_AFFILIATION_TYPES]: ({ commit }, payload) => {
    return axios.get(`/api/consultants/affiliation_types`)
      .then(resp => {
        commit(types.MUTATE_AFFILIATION_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
}
