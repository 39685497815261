// Getters
export const PARTNER_COMPANIES_SEARCH_RESULT = 'partner_company/PARTNER_COMPANIES_SEARCH_RESULT'
export const PARTNER_COMPANY_AGENT_TYPES = 'partner_company/PARTNER_COMPANY_AGENT_TYPES'
export const PRODUCTS = 'partner_company/PRODUCTS'

// Mutations
export const MUTATE_PARTNER_COMPANIES_SEARCH_RESULT = 'partner_company/MUTATE_PARTNER_COMPANIES_SEARCH_RESULT'
export const MUTATE_PARTNER_COMPANY_AGENT_TYPES = 'partner_company/MUTATE_PARTNER_COMPANY_AGENT_TYPES'
export const MUTATE_PRODUCTS = 'partner_company/MUTATE_PRODUCTS'

// Actions
export const SEARCH_PARTNER_COMPANIES = 'partner_company/SEARCH_PARTNER_COMPANIES'
export const GET_PARTNER_COMPANY = 'partner_company/GET_PARTNER_COMPANY'
export const SAVE_PARTNER_COMPANY = 'partner_company/SAVE_PARTNER_COMPANY'
export const DELETE_PARTNER_COMPANY = 'partner_company/DELETE_PARTNER_COMPANY'
export const GET_ALL_PRODUCTS = 'partner_company/GET_ALL_PRODUCTS'
export const GET_PARTNER_COMPANY_AGENT_TYPES = 'partner_company/GET_PARTNER_COMPANY_AGENT_TYPES'
