import axios from 'axios'
import * as types from './types'

export default {
  [types.SEARCH_SUPPORT_TICKETS]: ({ commit }, payload) => {
    const resource = `/api/support_tickets/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_SUPPORT_TICKETS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SET_STATUS]: ({ commit }, payload) => {
    const resource = `/api/support_tickets/set_status`
    return axios.post(resource, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.RESOLVE_TICKET]: ({ commit }, payload) => {
    const resource = `/api/support_tickets/resolve_ticket`
    return axios.post(resource, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_DETAILS_GENERAL_INFORMATION]: ({ commit }, id) => {
    commit(types.CLEAR_DETAILS_GENERAL_INFORMATION)
    return axios.get(`/api/support_tickets/${id}/details/general_information`)
      .then(resp => {
        commit(types.MUTATE_DETAILS_GENERAL_INFORMATION, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_TICKET_LOGS]: ({ commit }, id) => {
    return axios.get(`/api/support_tickets/${id}/details/get_ticket_logs`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_RESOLVE_TICKET_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_RESOLVE_TICKET_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_TICKET_TYPES]: ({ commit }, payload) => {
    return axios
      .get(`/api/support_tickets/ticket_types`)
      .then((resp) => {
        commit(types.MUTATE_TICKET_TYPES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_TICKET_STATUS]: ({ commit }, payload) => {
    return axios
      .get(`/api/support_tickets/ticket_status`)
      .then((resp) => {
        commit(types.MUTATE_TICKET_STATUS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
}
