import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  user: undefined,
  abilities: undefined,
  consultant_level: undefined,
  report_pendencies: undefined,
  company_pendencies_viewed: false,
  announcement_notifications: undefined
}

export default {
  state,
  actions,
  getters,
  mutations
}
