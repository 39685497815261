// Getters
export const LEADS_SEARCH_RESULT = "lead/LEADS_SEARCH_RESULT";
export const LEADS_SEARCH_TOTALIZERS = "lead/LEADS_SEARCH_TOTALIZERS";
export const NEW_LEAD_SIDEBAR = "lead/NEW_LEAD_SIDEBAR";
export const LEAD_TYPES = "lead/LEAD_TYPES";
export const LEAD_PN_TYPES = "lead/LEAD_PN_TYPES";
export const OCCUPATIONS = "lead/OCCUPATIONS";
export const SCHOOL_LEVELS = "lead/SCHOOL_LEVELS";
export const INCOMES = "lead/INCOMES";
export const GOALS = "lead/GOALS";
export const ORIGINS = "lead/ORIGINS";
export const SETTINGS_TABLE_SIDEBAR = "lead/SETTINGS_TABLE_SIDEBAR";
export const SETTINGS_TABLE_CUSTOMERS_LEADS =
  "lead/SETTINGS_TABLE_CUSTOMERS_LEADS";
export const SAVE_SETTINGS_TABLE_SIDEBAR = "lead/SAVE_SETTINGS_TABLE_SIDEBAR";
export const LEADS_TABLE_COLUMNS = "lead/LEADS_TABLE_COLUMNS";
export const STATUSES = "lead/STATUSES";
export const SEQUENCE_COLUMN_ENABLED = "lead/SEQUENCE_COLUMN_ENABLED";
export const LEAD_POTENTIALS = "lead/LEAD_POTENTIALS";
export const LEAD_SEARCH_POTENTIALS = "lead/LEAD_SEARCH_POTENTIALS";
export const SETTINGS_TABLE_POTENTIAL_LEADS =
  "lead/SETTINGS_TABLE_POTENTIAL_LEADS";
export const SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR =
  "lead/SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR";
export const SEQUENCE_COLUMN_POTENTIAL_LEADS_ENABLED =
  "lead/SEQUENCE_COLUMN_POTENTIAL_LEADS_ENABLED";
export const SOURCES = "lead/SOURCES";
export const LEAD_STATUSES = "lead/LEAD_STATUSES";
export const IMPORT_LEADS_SIDEBAR = "lead/IMPORT_LEADS_SIDEBAR";
export const CAMPAIGNS_LIST = "lead/CAMPAIGNS_LIST";
export const NEW_CAMPAIGN_SIDEBAR = "lead/NEW_CAMPAIGN_SIDEBAR";

// Mutations
export const MUTATE_LEADS_SEARCH_RESULT = "lead/MUTATE_LEADS_SEARCH_RESULT";
export const MUTATE_LEADS_SEARCH_TOTALIZERS = "lead/MUTATE_LEADS_SEARCH_TOTALIZERS";
export const MUTATE_NEW_LEAD_SIDEBAR = "lead/MUTATE_NEW_LEAD_SIDEBAR";
export const MUTATE_LEAD_TYPES = "lead/MUTATE_LEAD_TYPES";
export const MUTATE_LEAD_PN_TYPES = "lead/MUTATE_LEAD_PN_TYPES";
export const MUTATE_OCCUPATIONS = "lead/MUTATE_OCCUPATIONS";
export const MUTATE_SCHOOL_LEVELS = "lead/MUTATE_SCHOOL_LEVELS";
export const MUTATE_INCOMES = "lead/MUTATE_INCOMES";
export const MUTATE_GOALS = "lead/MUTATE_GOALS";
export const MUTATE_ORIGINS = "lead/MUTATE_ORIGINS";
export const MUTATE_SETTINGS_TABLE_CUSTOMERS_LEADS =
  "lead/MUTATE_SETTINGS_TABLE_CUSTOMERS_LEADS";
export const MUTATE_SETTINGS_TABLE_SIDEBAR =
  "lead/MUTATE_SETTINGS_TABLE_SIDEBAR";
export const MUTATE_LEADS_TABLE_COLUMNS = "lead/MUTATE_LEADS_TABLE_COLUMNS";
export const HIDE_COLUMNS = "lead/HIDE_COLUMNS";
export const MUTATE_STATUSES = "lead/MUTATE_STATUSES";
export const MUTATE_DISABLE_SEQ_COLUMN = "lead/MUTATE_DISABLE_SEQ_COLUMN";
export const MUTATE_LEAD_POTENTIALS = "lead/MUTATE_LEAD_POTENTIALS";
export const MUTATE_LEAD_SEARCH_POTENTIALS =
  "lead/MUTATE_LEAD_SEARCH_POTENTIALS";
export const MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS =
  "lead/MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS";
export const MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR =
  "lead/MUTATE_SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR";
export const HIDE_COLUMNS_POTENTIAL_LEADS = "lead/HIDE_COLUMNS_POTENTIAL_LEADS";
export const MUTATE_DISABLE_SEQ_COLUMN_POTENTIAL_LEADS =
  "lead/MUTATE_DISABLE_SEQ_COLUMN_POTENTIAL_LEADS";
export const MUTATE_SOURCES = "lead/MUTATE_SOURCES";
export const MUTATE_LEAD_STATUSES = "lead/MUTATE_LEAD_STATUSES";
export const MUTATE_IMPORT_LEADS_SIDEBAR = "lead/MUTATE_IMPORT_LEADS_SIDEBAR";
export const MUTATE_CAMPAIGNS_LIST = "lead/MUTATE_CAMPAIGNS_LIST";
export const MUTATE_NEW_CAMPAIGN_SIDEBAR = "lead/MUTATE_NEW_CAMPAIGN_SIDEBAR";

// Actions
export const GET_LEAD = "lead/GET_LEAD";
export const SEARCH_LEADS = "lead/SEARCH_LEADS";
export const OPEN_NEW_LEAD_SIDEBAR = "lead/OPEN_NEW_LEAD_SIDEBAR";
export const GET_LEAD_TYPES = "lead/GET_LEAD_TYPES";
export const GET_LEAD_PN_TYPES = "lead/GET_LEAD_PN_TYPES";
export const GET_LEAD_INCOME = "lead/GET_LEAD_INCOME";
export const GET_OCCUPATIONS = "lead/GET_OCCUPATIONS";
export const GET_SCHOOL_LEVELS = "lead/GET_SCHOOL_LEVELS";
export const GET_INCOMES = "lead/GET_INCOMES";
export const GET_GOALS = "lead/GET_GOALS";
export const GET_ORIGINS = "lead/GET_ORIGINS";
export const STORE_LEAD = "lead/STORE_LEAD";
export const DOWNLOAD_RESUME = "lead/DOWNLOAD_RESUME";
export const OPEN_SETTINGS_TABLE_SIDEBAR = "lead/SETTINGS_TABLE_SIDEBAR";
export const GET_SETTINGS_TABLE_CUSTOMERS_LEADS =
  "lead/GET_SETTINGS_TABLE_CUSTOMERS_LEADS";
export const SET_CUSTOMER_LEAD_STATUS = "lead/SET_CUSTOMER_LEAD_STATUS";
export const SET_CUSTOMER_LEAD_SEQUENCE = "lead/SET_CUSTOMER_LEAD_SEQUENCE";
export const GET_STATUSES = "lead/GET_STATUSES";
export const SET_CALL_REMINDER = "lead/SET_CALL_REMINDER";
export const GET_CUSTOMER_LEAD = "lead/GET_CUSTOMER_LEAD";
export const DELETE_LEAD = "lead/DELETE_LEAD";
export const REORDER_LEAD_TABLE = "lead/REORDER_LEAD_TABLE";
export const TRANSFER_LEADS = "lead/TRANSFER_LEADS";
export const GET_LEAD_POTENTIALS = "lead/GET_LEAD_POTENTIALS";
export const SEARCH_LEAD_POTENTIALS = "lead/SEARCH_LEAD_POTENTIALS";
export const LEADS_POTENTIALS_TABLE_COLUMNS =
  "lead/LEADS_POTENTIALS_TABLE_COLUMNS";
export const OPEN_SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR =
  "lead/OPEN_SETTINGS_TABLE_POTENTIAL_LEADS_SIDEBAR";
export const GET_SETTINGS_TABLE_POTENTIAL_LEADS =
  "lead/GET_SETTINGS_TABLE_POTENTIAL_LEADS";
export const SAVE_SETTINGS_TABLE_POTENTIAL_LEADS =
  "lead/SAVE_SETTINGS_TABLE_POTENTIAL_LEADS";
export const GET_SOURCES = "lead/GET_SOURCES";
export const GET_LEAD_STATUSES = "lead/GET_LEAD_STATUSES";
export const SET_LEAD_POTENTIAL = "lead/SET_LEAD_POTENTIAL";
export const REORDER_POTENTIAL_LEAD_TABLE = "lead/REORDER_POTENTIAL_LEAD_TABLE";
export const OPEN_IMPORT_LEADS_SIDEBAR = "lead/IMPORT_LEADS_SIDEBAR";
export const SAVE_IMPORT_LEADS_SIDEBAR = "lead/SAVE_IMPORT_LEADS_SIDEBAR";
export const CHECK_DUPLICATE_CELL_PHONE = "lead/CHECK_DUPLICATE_CELL_PHONE";
export const CHECK_DUPLICATE_EMAIL = "lead/CHECK_DUPLICATE_EMAIL";
export const GET_CAMPAIGNS_LIST = "lead/GET_CAMPAIGNS_LIST";
export const OPEN_NEW_CAMPAIGN_SIDEBAR = "lead/OPEN_NEW_CAMPAIGN_SIDEBAR";
export const STORE_CAMPAIGN = "lead/STORE_CAMPAIGN";
export const DELETE_CAMPAIGN = "lead/DELETE_CAMPAIGN";
export const UPDATE_CAMPAIGN_STATUS = "lead/UPDATE_CAMPAIGN_STATUS";