import * as types from "./types";

export default {
  [types.APPOINTMENTS_SEARCH_RESULT]: (state) => {
    return state.searchResult;
  },
  [types.APPOINTMENTS_SEARCH_TOTALIZERS]: (state) => {
    return state.searchTotalizers;
  },
  [types.APPOINTMENT_EVENT_TYPES]: (state) => {
    return state.eventTypes;
  },
  [types.NEW_APPOINTMENT_SIDEBAR]: (state) => {
    return state.newAppointmentSidebar;
  },
  [types.APPOINTMENT_CUSTOMERS]: (state) => {
    return state.customers;
  },
  [types.APPOINTMENT_REPORT_SIDEBAR]: (state) => {
    return state.appointmentReportSidebar;
  },
  [types.APPOINTMENT_REPORT_DETAILS_SIDEBAR]: (state) => {
    return state.appointmentReportDetailsSidebar;
  },
  [types.CONSULTANTS_SPECIALIST]: (state) => {
    return state.consultantsSpecialist;
  },
};
