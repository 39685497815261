// Getters
export const NEW_TO_DO_SIDEBAR = "todo/NEW_TO_DO_SIDEBAR";
export const TODOS_INBOX_SEARCH_RESULT = "todo/TODOS_INBOX_SEARCH_RESULT";
export const GET_TASKS_FROM_TODAY = "todo/GET_TASKS_FROM_TODAY";
export const TASKS_FROM_TODAY = "todo/TASKS_FROM_TODAY";
export const TO_DO_DETAILS_SIDEBAR = "todo/TO_DO_DETAILS_SIDEBAR";
export const TODOS_FLOAT_SIDEBAR = "todo/TODOS_FLOAT_SIDEBAR";



// Mutations
export const MUTATE_NEW_TO_DO_SIDEBAR = "todo/MUTATE_NEW_TO_DO_SIDEBAR";
export const MUTATE_TODOS_INBOX_SEARCH_RESULT = "todo/MUTATE_TODOS_INBOX_SEARCH_RESULT";
export const MUTATE_TASKS_FROM_TODAY = "todo/MUTATE_TASKS_FROM_TODAY";
export const MUTATE_TO_DO_DETAILS_SIDEBAR = "todo/MUTATE_TO_DO_DETAILS_SIDEBAR";
export const MUTATE_TODOS_FLOAT_SIDEBAR = "todo/MUTATE_TODOS_FLOAT_SIDEBAR";

// Actions
export const OPEN_NEW_TO_DO_SIDEBAR = "todo/OPEN_NEW_TO_DO_SIDEBAR";
export const STORE_TO_DO = "todo/STORE_TO_DO";
export const SEARCH_TODOS_INBOX = "todo/SEARCH_TODOS_INBOX";
export const MARK_AS_COMPLETED = "todo/MARK_AS_COMPLETED";
export const DELETE_TASK = "todo/DELETE_TASK";
export const MARK_AS_IMPORTANT = "todo/MARK_AS_IMPORTANT";
export const OPEN_TO_DO_DETAILS_SIDEBAR = "todo/OPEN_TO_DO_DETAILS_SIDEBAR";
export const GET_TASK = "todo/GET_TASK";
export const GET_TODOS_FLOAT_SIDEBAR = "todo/GET_TODOS_FLOAT_SIDEBAR";