import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from "@/auth/utils";
import store from "@/store";
import * as types from "@/modules/account/store/types";
import { initApi } from "@/helpers/api";
import account from "./routes/account";
import contract from "./routes/contract";
import kpi from "./routes/kpi";
import consultant from "./routes/consultant";
import miscellaneous from "./routes/miscellaneous";
import report from "./routes/report";
import notification from "./routes/notification";
import customer from "./routes/customer";
import appointment from "./routes/appointment";
import system from "./routes/system";
import commission from "./routes/commission";
import withdrawal from "./routes/withdrawal";
import financial_adjustment from "./routes/financial_adjustment";
import financial_management from "./routes/financial_management";
import franchises from "./routes/franchises";
import dashboard from "./routes/dashboard";
import task from "./routes/task";
import nps from "./routes/nps";
import accounting_management from "./routes/accounting_management";

initApi();

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "account-login" } },
    ...account,
    ...contract,
    ...kpi,
    ...consultant,
    ...report,
    ...miscellaneous,
    ...notification,
    ...customer,
    ...appointment,
    ...system,
    ...commission,
    ...withdrawal,
    ...financial_adjustment,
    ...financial_management,
    ...franchises,
    ...dashboard,
    ...task,
    ...nps,
    ...accounting_management,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

const passTroughRoutes = {
  standardRoutes: ["account-token-auth", "misc-not-authorized", "auth-login"],
  contractRoutes: ["contract-save"],
  companyRoutes: [
    "consultant-company-info-save",
    "lead-profile",
    "customer-profile",
    "contract-details-summary",
    "ticket-details-summary"
  ],
  appointmentRoutes: ["appointments"],
};

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (isLoggedIn) {
    store.dispatch(types.CHECK_SYSTEM_VERSION_NOTIFICATIONS);
  }
  if (isLoggedIn && !passTroughRoutes.appointmentRoutes.includes(to.name)) {
    store.dispatch(types.CHECK_APPOINTMENT_REPORT_PENDENCIES);
  }
  if (isLoggedIn && !passTroughRoutes.companyRoutes.includes(to.name)) {
    store.dispatch(types.CHECK_COMPANY_PENDENCIES);
  }
  if (isLoggedIn && !passTroughRoutes.contractRoutes.includes(to.name)) {
    store.dispatch(types.CHECK_CONTRACT_REPORT_PENDENCIES);
  }
  if (!canNavigate(to) && !to.meta.public) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "account-login" });
    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser());
  }
  if (to.meta.public) {
    return next();
  }
  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
