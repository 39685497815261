import * as types from './types'

export default {
  [types.VERSIONS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.DASHBOARD_BANNERS_SEARCH_RESULT]: state => {
    return state.dashBannersSearchResult
  },
  [types.NEW_BANNER_SIDEBAR]: state => {
    return state.newBannerSidebar
  },
  [types.ANNOUNCEMENT_TYPES]: state => {
    return state.announcementTypes
  }
}
