import * as types from "./types";

export default {
  [types.MUTATE_APPOINTMENTS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload;
  },
  [types.MUTATE_APPOINTMENTS_SEARCH_TOTALIZERS]: (state, payload) => {
    state.searchTotalizers = payload;
  },
  [types.MUTATE_APPOINTMENT_EVENT_TYPES]: (state, payload) => {
    state.eventTypes = payload;
  },
  [types.MUTATE_NEW_APPOINTMENT_SIDEBAR]: (state, payload) => {
    state.newAppointmentSidebar = {
      ...state.newAppointmentSidebar,
      ...payload,
    };
  },
  [types.MUTATE_APPOINTMENT_CUSTOMERS]: (state, payload) => {
    state.customers = payload;
  },
  [types.MUTATE_APPOINTMENT_REPORT_SIDEBAR]: (state, payload) => {
    state.appointmentReportSidebar = {
      ...state.appointmentReportSidebar,
      ...payload,
    };
  },
  [types.MUTATE_APPOINTMENT_REPORT_DETAILS_SIDEBAR]: (state, payload) => {
    state.appointmentReportDetailsSidebar = {
      ...state.appointmentReportDetailsSidebar,
      ...payload,
    };
  },
  [types.MUTATE_CONSULTANTS_SPECIALIST]: (state, payload) => {
    state.consultantsSpecialist = payload;
  },
};
