// Getters
export const SUPPORT_TICKETS_SEARCH_RESULT = 'support_tickets/SUPPORT_TICKETS_SEARCH_RESULT'
export const DETAILS_GENERAL_INFORMATION = 'support_tickets/DETAILS_GENERAL_INFORMATION'
export const RESOLVE_TICKET_SIDEBAR = 'support_tickets/RESOLVE_TICKET_SIDEBAR'
export const TICKET_TYPES = "support_tickets/TICKET_TYPES";
export const TICKET_STATUS = "support_tickets/TICKET_STATUS";

// Mutations
export const MUTATE_SUPPORT_TICKETS_SEARCH_RESULT = 'support_tickets/MUTATE_SUPPORT_TICKETS_SEARCH_RESULT'
export const CLEAR_DETAILS_GENERAL_INFORMATION = 'support_tickets/CLEAR_DETAILS_GENERAL_INFORMATION'
export const MUTATE_DETAILS_GENERAL_INFORMATION = 'support_tickets/MUTATE_DETAILS_GENERAL_INFORMATION'
export const MUTATE_RESOLVE_TICKET_SIDEBAR = 'support_tickets/MUTATE_RESOLVE_TICKET_SIDEBAR'
export const MUTATE_TICKET_TYPES = "support_tickets/MUTATE_TICKET_TYPES";
export const MUTATE_TICKET_STATUS = "support_tickets/MUTATE_TICKET_STATUS";

// Actions
export const SEARCH_SUPPORT_TICKETS = 'support_tickets/SEARCH_SUPPORT_TICKETS'
export const SET_STATUS = 'support_tickets/SET_STATUS'
export const RESOLVE_TICKET = 'support_tickets/RESOLVE_TICKET'
export const GET_DETAILS_GENERAL_INFORMATION = 'support_tickets/GET_DETAILS_GENERAL_INFORMATION'
export const GET_TICKET_LOGS = 'support_tickets/GET_TICKET_LOGS'
export const OPEN_RESOLVE_TICKET_SIDEBAR = 'support_tickets/OPEN_RESOLVE_TICKET_SIDEBAR'
export const GET_TICKET_TYPES = "support_tickets/GET_TICKET_TYPES";
export const GET_TICKET_STATUS = "support_tickets/GET_TICKET_STATUS"