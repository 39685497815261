import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  consultants: undefined,
  topByScheduledQuantity: [],
  topByDoneQuantity: [],
  topByPaidAmount: [],
  kpiList: [],
}

export default {
  state,
  actions,
  getters,
  mutations
}
