import * as types from './types'

export default {
  [types.CONSULTANTS]: state => {
    return state.consultants
  },
  [types.TOP_BY_SCHEDULED_QUANTITY]: state => {
    return state.topByScheduledQuantity
  },
  [types.TOP_BY_DONE_QUANTITY]: state => {
    return state.topByDoneQuantity
  },
  [types.TOP_BY_PAID_AMOUNT]: state => {
    return state.topByPaidAmount
  },
  [types.KPI_LIST]: state => {
    return state.kpiList
  }
}
