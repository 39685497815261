import Vue from "vue";
import { ToastPlugin, ModalPlugin, BootstrapVue } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import axios from "axios";
import VueTheMask from "vue-the-mask";
import * as types from "./modules/account/store/types";
import { getCredentials } from "./helpers/credentials";
import { declareFilters } from "./helpers/filters";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { ADMIN_CREDENTIALS_STORAGE_KEY } from "@/constants/auth";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Axios Mock Adapter
import "@/@fake-db/db";

// BSV Plugin Registration
Vue.use(BootstrapVue);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

Vue.use(VueTheMask);
Vue.use(VueTelInput, {
  defaultCountry: "br",
  autoDefaultCountry: false,
  mode: "international",
  validCharactersOnly: true,
  inputOptions: {
    placeholder: "Informe o telefone",
  },
});
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

// Declaration of global filters
declareFilters();

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = false;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status || 403 === error.response.status) {
      store.dispatch(types.LOGOUT);
      router.push({ name: "misc-not-authorized" });
    }
    return Promise.reject(error);
  }
);

const token = getCredentials(ADMIN_CREDENTIALS_STORAGE_KEY) || undefined;
if (token) {
  store.commit(types.MUTATE_TOKEN, token);
  store.dispatch(types.GET_USER_DATA);
}

app.$mount("#app");
