import * as types from './types'

export default {
  [types.CUSTOMERS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.CUSTOMERS_SEARCH_TOTALIZERS]: state => {
    return state.searchTotalizers
  },
  [types.CUSTOMERS_TABLE_COLUMNS]: state => {
    return state.tableColumns
  },
  [types.LEAD_TYPES]: state => {
    return state.leadTypes
  },
  [types.OCCUPATIONS]: state => {
    return state.occupations
  },
  [types.SCHOOL_LEVELS]: state => {
    return state.schoolLevels
  },
  [types.INCOMES]: state => {
    return state.incomes
  },
  [types.GOALS]: state => {
    return state.goals
  },
  [types.ORIGINS]: state => {
    return state.origins
  },
  [types.COUNTERS]: state => {
    return state.counters
  },
  [types.SETTINGS_TABLE_SIDEBAR]: state => {
    return state.settingsTableSidebar
  },
  [types.SETTINGS_TABLE_CUSTOMERS]: state => {
    return state.settingsTableCustomer
  },
  [types.STATUSES]: state => {
    return state.statuses
  },
  [types.SEQUENCE_COLUMN_ENABLED]: state => {
    return state.sequenceColumnEnabled
  },
  [types.CUSTOMER_TRANSFER_OPTIONS]: state => {
    return state.customerTransferOptions
  },
}
