import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import { getCredentials } from '@/helpers/credentials'
import { ADMIN_CREDENTIALS_STORAGE_KEY } from '@/constants/auth'
import { transformAbilities } from '@/helpers/credentials'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

let existingAbility = undefined

const credentials = getCredentials(ADMIN_CREDENTIALS_STORAGE_KEY)
if (credentials) {
    existingAbility = transformAbilities(credentials.abilities)
}

export default new Ability(existingAbility || initialAbility)
