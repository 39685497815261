import axios from 'axios'
import * as types from './types'

export default {
  [types.SEARCH_VERSIONS]: ({ commit }, payload) => {
    const resource = `/api/announcements/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_VERSIONS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_VERSION]: ({}, payload) => {
    let save = axios.post
    let route = '/api/announcements'
    if (payload.id) {
      save = axios.put
      route = `/api/announcements/${payload.id}`
    }
    return save(route, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_VERSION]: ({}, payload) => {
    return axios.get(`/api/announcements/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_VERSION]: ({}, payload) => {
    return axios.delete(`/api/announcements/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }, 
  [types.OPEN_NEW_BANNER_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_BANNER_SIDEBAR, { ...payload, visible: true });
  },
  [types.SEARCH_DASHBOARD_BANNERS]: ({ commit }, payload) => {
    const resource = `/api/dashboard_banners/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_DASHBOARD_BANNERS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.STORE_DASHBOARD_BANNER]: ({}, payload) => {
    let route = '/api/dashboard_banners'
    if (payload.id) {
      route = `/api/dashboard_banners/${payload.id}`
    }
    return axios.post(route, payload.request)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_DASHBOARD_BANNER]: ({}, payload) => {
    return axios.get(`/api/dashboard_banners/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_DASHBOARD_BANNER]: ({}, payload) => {
    return axios.delete(`/api/dashboard_banners/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }, 
  [types.UPDATE_DASHBOARD_BANNER_SEQUENCE]: ({}, payload) => {
    return axios.put(`/api/dashboard_banners/sequence`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }, 
  [types.GET_ANNOUNCEMENT_TYPES]: ({ commit }) => {
    return axios.get('/api/announcements/types')
      .then(resp => {
        commit(types.MUTATE_ANNOUNCEMENT_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
