import {
  READ_ACTION,
  CREATE_ACTION,
  LEAD_RESOURCE
} from '@/constants/resources'

export default [
  {
    path: '/compromissos',
    name: 'appointment-list',
    component: () => import('@/modules/appointment/views/AppointmentsList.vue'),
    meta: {
      resource: LEAD_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Compromissos'
    },
  }
]
