import * as types from "./types";

export default {
  [types.APPOINTMENTS_FROM_TODAY]: (state) => {
    return state.appointmentsFromToday;
  },
  [types.BIRTHDAYS_THIS_MONTH]: (state) => {
    return state.birthdaysThisMonth;
  },
  [types.CUSTOMERS_REMINDER]: (state) => {
    return state.customersReminder;
  },
  [types.LEADS_REMINDER]: (state) => {
    return state.leadsReminder;
  },
  [types.BANNERS]: (state) => {
    return state.banners;
  }
};
