import _ from 'lodash'
import * as types from './types'

export default {
  [types.MUTATE_CUSTOMERS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_CUSTOMERS_SEARCH_TOTALIZERS]: (state, payload) => {
    state.searchTotalizers = payload
  },
  [types.MUTATE_NEW_LEAD_SIDEBAR]: (state, payload) => {
    state.newLeadSidebar = { ...state.newLeadSidebar, ...payload }
  },
  [types.MUTATE_LEAD_TYPES]: (state, payload) => {
    state.leadTypes = payload
  },
  [types.MUTATE_OCCUPATIONS]: (state, payload) => {
    state.occupations = payload
  },
  [types.MUTATE_SCHOOL_LEVELS]: (state, payload) => {
    state.schoolLevels = payload
  },
  [types.MUTATE_INCOMES]: (state, payload) => {
    state.incomes = payload
  },
  [types.MUTATE_GOALS]: (state, payload) => {
    state.goals = payload
  },
  [types.MUTATE_ORIGINS]: (state, payload) => {
    state.origins = payload
  },
  [types.MUTATE_COUNTERS]: (state, payload) => {
    state.counters = payload
  },
  [types.OPEN_SETTINGS_TABLE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_SETTINGS_TABLE_SIDEBAR, { ...payload, visible: true })
  },
  [types.MUTATE_SETTINGS_TABLE_SIDEBAR]: (state, payload) => {
    state.settingsTableSidebar = { ...state.settingsTableSidebar, ...payload }
  },
  [types.MUTATE_SETTINGS_TABLE_CUSTOMERS]: (state, payload) => {
    if (typeof payload === 'string' || payload instanceof String) {
      state.settingsTableCustomer = JSON.parse(payload)
    } else {
      state.settingsTableCustomer = payload
    }
    if (state.searchResult?.data) {
      state.searchResult.data = [ ...state.searchResult.data ]
    }
  },
  [types.HIDE_COLUMNS]: (state) => {
    _.forEach(
      Object.keys(state.settingsTableCustomer),
      settingElement => {
        const tableColumnsIndex = _.findIndex(state.tableColumns, { key: settingElement })
        if (tableColumnsIndex >= 0) {
          const column = state.tableColumns[tableColumnsIndex]
          if (state.settingsTableCustomer[settingElement]) {
            column.tdClass = ''
            column.thClass = ''
          } else {
            column.tdClass = 'd-none'
            column.thClass = 'd-none'
          }
        }
      }
    )
  },
  [types.MUTATE_STATUSES]: (state, payload) => {
    payload = _.map(_.filter(payload,{customer_type : 2}),_.partial(_.omit,_,'customer_type'))
    state.statuses = payload
  },
  [types.MUTATE_CUSTOMER_TRANSFER_OPTIONS]: (state, payload) => {
    state.customerTransferOptions = payload
  },

}


