import * as types from './types'

export default {
  [types.MUTATE_SUPPORT_TICKETS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_DETAILS_GENERAL_INFORMATION]: (state, payload) => {
    state.details.generalInformation = {
      ...payload
    }
  },
  [types.CLEAR_DETAILS_GENERAL_INFORMATION]: (state, payload) => {
    state.details.generalInformation = undefined
  },
  [types.RESOLVE_TICKET_SIDEBAR]: state => {
    return state.resolveTicketSidebar
  },
  [types.MUTATE_RESOLVE_TICKET_SIDEBAR]: (state, payload) => {
    state.resolveTicketSidebar = { ...state.resolveTicketSidebar, ...payload }
  },
  [types.MUTATE_TICKET_TYPES]: (state, payload) => {
    state.ticketTypes = payload;
  },
  [types.MUTATE_TICKET_STATUS]: (state, payload) => {
    state.ticketStatus = payload;
  },
}
