import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  appointmentsFromToday: [],
  tasksFromToday: [],
  newToDoSidebar: {
    id: undefined,
    isRemove: false,
    visible: false,
    saveAction: () => {},
  },
  todosInboxSearchResult: undefined,
  toDoDetailsSidebar: {
    id: undefined,
    visible: false,
    saveAction: () => {},
  },
  todosFloatSidebar: undefined
};

export default {
  state,
  actions,
  getters,
  mutations,
};
