// Getters
export const DIGITAL_FRANCHISES_SEARCH_RESULT =
  "franchises/DIGITAL_FRANCHISES_SEARCH_RESULT";
export const PHYSICAL_FRANCHISES_SEARCH_RESULT =
  "franchises/PHYSICAL_FRANCHISES_SEARCH_RESULT";
export const NEW_FRANCHISE_SIDEBAR = "franchises/NEW_FRANCHISE_SIDEBAR";
export const NEW_PHYSICAL_FRANCHISE_SIDEBAR =
  "franchises/NEW_PHYSICAL_FRANCHISE_SIDEBAR";
export const RESPONSABLES = "franchises/RESPONSABLES";
export const PHYSICAL_RESPONSABLES = "franchises/PHYSICAL_RESPONSABLES";
export const DIGITAL_TABLE_COLUMNS = "franchises/DIGITAL_TABLE_COLUMNS";
export const PHYSICAL_TABLE_COLUMNS = "franchises/PHYSICAL_TABLE_COLUMNS";

// Mutations
export const MUTATE_DIGITAL_FRANCHISES_RESULT =
  "franchises/MUTATE_DIGITAL_FRANCHISES_RESULT";
export const MUTATE_PHYSICAL_FRANCHISES_RESULT =
  "franchises/MUTATE_PHYSICAL_FRANCHISES_RESULT";
export const MUTATE_NEW_FRANCHISE_SIDEBAR =
  "franchises/MUTATE_NEW_FRANCHISE_SIDEBAR";
export const MUTATE_NEW_PHYSICAL_FRANCHISE_SIDEBAR =
  "franchises/MUTATE_NEW_PHYSICAL_FRANCHISE_SIDEBAR";
export const MUTATE_RESPONSABLES = "franchises/MUTATE_RESPONSABLES";
export const MUTATE_PHYSICAL_RESPONSABLES =
  "franchises/MUTATE_PHYSICAL_RESPONSABLES";

// Actions
export const GET_DIGITAL_FRANCHISE = "franchises/GET_DIGITAL_FRANCHISE";
export const GET_PHYSICAL_FRANCHISE = "franchises/GET_PHYSICAL_FRANCHISE";
export const SEARCH_DIGITAL_FRANCHISES = "franchises/SEARCH_DIGITAL_FRANCHISES";
export const SEARCH_PHYSICAL_FRANCHISES =
  "franchises/SEARCH_PHYSICAL_FRANCHISES";
export const OPEN_NEW_FRANCHISE_SIDEBAR =
  "franchises/OPEN_NEW_FRANCHISE_SIDEBAR";
export const OPEN_NEW_PHYSICAL_FRANCHISE_SIDEBAR =
  "franchises/OPEN_NEW_PHYSICAL_FRANCHISE_SIDEBAR";
export const GET_RESPONSABLES = "franchises/GET_RESPONSABLES";
export const GET_PHYSICAL_RESPONSABLES = "franchises/GET_PHYSICAL_RESPONSABLES";
export const STORE_FRANCHISE = "franchises/STORE_FRANCHISE";
export const EDIT_FRANCHISE = "franchises/EDIT_FRANCHISE";
export const STORE_PHYSICAL_FRANCHISE = "franchises/STORE_PHYSICAL_FRANCHISE";
export const EDIT_PHYSICAL_FRANCHISE = "franchises/EDIT_PHYSICAL_FRANCHISE";
