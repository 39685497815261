// Getters
export const INITIAL_ITEMS_PER_PAGE = 'shared/INITIAL_ITEMS_PER_PAGE'
export const ITEMS_PER_PAGE_OPTIONS = 'shared/ITEMS_PER_PAGE_OPTIONS'
export const BUSINESS_CONFIG_PRODUCT_TAX = 'shared/BUSINESS_CONFIG_PRODUCT_TAX'
export const BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS = 'shared/BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS'
export const FILE_UPLOAD_SIDEBAR = 'shared/FILE_UPLOAD_SIDEBAR'
export const COMMENT_SIDEBAR = 'shared/COMMENT_SIDEBAR'
export const CUSTOM_FIELD_TYPES = 'shared/CUSTOM_FIELD_TYPES'
export const CUSTOMERS = 'shared/CUSTOMERS'
export const ADDRESS_STATES = 'shared/ADDRESS_STATES'
export const ADDRESS_CITIES = 'shared/ADDRESS_CITIES'
export const INCOMES = 'shared/INCOMES'
export const OCCUPATIONS = 'shared/OCCUPATIONS'
export const BANKS = 'shared/BANKS'
export const PIX_KEY_TYPES = 'shared/PIX_KEY_TYPES'
export const CONSULTANT_APPROVAL_DATES = 'shared/CONSULTANT_APPROVAL_DATES'
export const CONSULTANT_FUTURE_APPROVAL_DATES = 'shared/CONSULTANT_FUTURE_APPROVAL_DATES'
export const FRANCHISES = 'shared/FRANCHISES'
export const PHYSICAL_FRANCHISES = 'shared/PHYSICAL_FRANCHISES'
export const ROLES = 'shared/ROLES'
export const CONSULTANTS = 'shared/CONSULTANTS'
export const ALL_CONSULTANTS = 'shared/ALL_CONSULTANTS'
export const STRUCTURE_TYPES = 'shared/STRUCTURE_TYPES'
export const SPECIALIST_CONSULTANTS = 'shared/SPECIALIST_CONSULTANTS'
export const CLOSER_CONSULTANTS = 'shared/CLOSER_CONSULTANTS'
export const PARTNER_COMPANIES = 'shared/PARTNER_COMPANIES'
export const PRODUCTS = 'shared/PRODUCTS'
export const CREDIT_TYPES = 'shared/CREDIT_TYPES'
export const SCHOOL_LEVELS = 'shared/SCHOOL_LEVELS'
export const LEAD_ORIGINS = 'shared/LEAD_ORIGINS'
export const LEAD_GOALS = 'shared/LEAD_GOALS'
export const WITHDRAWAL_SUMMARY = 'shared/WITHDRAWAL_SUMMARY'
export const PRESENT_BILLING_DATE = 'shared/PRESENT_BILLING_DATE'
export const BILLING_PAYMENT_DEADLINE = 'shared/BILLING_PAYMENT_DEADLINE'
export const EMPLOYEE_PAYMENTS_INFO = 'shared/EMPLOYEE_PAYMENTS_INFO'
export const DEPARTMENTS = 'shared/DEPARTMENTS'
export const EMPLOYEES = 'shared/EMPLOYEES'
export const WITHDRAWAL_STATUSES = 'shared/WITHDRAWAL_STATUSES'
export const CONSULTANTS_PARTNER = 'shared/CONSULTANT_PARTNERS'
export const WITHDRAWAL_CATEGORIES = 'shared/WITHDRAWAL_CATEGORIES'
export const PAYMENT_GATEWAYS = 'shared/PAYMENT_GATEWAYS'
export const ACTIVE_CONSULTANTS = 'shared/ACTIVE_CONSULTANTS'
export const QR_CODE_SIDEBAR = 'shared/QR_CODE_SIDEBAR'
export const CAMPAIGNS = 'shared/CAMPAIGNS'
export const COST_CENTERS = 'shared/COST_CENTERS'
export const COST_CENTER_CATEGORIES = 'shared/COST_CENTER_CATEGORIES'
export const ALL_COST_CENTER_CATEGORIES = 'shared/ALL_COST_CENTER_CATEGORIES'
export const PAYMENT_TYPES = 'shared/PAYMENT_TYPES'
export const GROUP_COMPANIES = 'shared/GROUP_COMPANIES'
export const EMPLOYEES_BY_COST_CENTER = "accounting_management/EMPLOYEES_BY_COST_CENTER";

// Mutations
export const MUTATE_BUSINESS_CONFIG_PRODUCT_TAX = 'shared/MUTATE_BUSINESS_CONFIG_PRODUCT_TAX'
export const MUTATE_BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS = 'shared/MUTATE_BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS'
export const MUTATE_FILE_UPLOAD_SIDEBAR = 'shared/MUTATE_FILE_UPLOAD_SIDEBAR'
export const MUTATE_COMMENT_SIDEBAR = 'shared/MUTATE_COMMENT_SIDEBAR'
export const MUTATE_CUSTOM_FIELD_TYPES = 'shared/MUTATE_CUSTOM_FIELD_TYPES'
export const MUTATE_CUSTOMERS = 'shared/MUTATE_CUSTOMERS'
export const MUTATE_ADDRESS_STATES =  'shared/MUTATE_ADDRESS_STATES'
export const MUTATE_ADDRESS_CITIES = 'shared/MUTATE_ADDRESS_CITIES'
export const MUTATE_INCOMES = 'shared/MUTATE_INCOMES'
export const MUTATE_OCCUPATIONS = 'shared/MUTATE_OCCUPATIONS'
export const MUTATE_BANKS = 'shared/MUTATE_BANKS'
export const MUTATE_PIX_KEY_TYPES = 'shared/MUTATE_PIX_KEY_TYPES'
export const MUTATE_CONSULTANT_APPROVAL_DATES = 'shared/MUTATE_CONSULTANT_APPROVAL_DATES'
export const MUTATE_CONSULTANT_FUTURE_APPROVAL_DATES = 'shared/MUTATE_CONSULTANT_FUTURE_APPROVAL_DATES'
export const MUTATE_FRANCHISES = 'shared/MUTATE_FRANCHISES'
export const MUTATE_PHYSICAL_FRANCHISES = 'shared/MUTATE_PHYSICAL_FRANCHISES'
export const MUTATE_ROLES = 'shared/MUTATE_ROLES'
export const MUTATE_CONSULTANTS = 'shared/MUTATE_CONSULTANTS'
export const MUTATE_ALL_CONSULTANTS = 'shared/MUTATE_ALL_CONSULTANTS'
export const MUTATE_STRUCTURE_TYPES = 'shared/MUTATE_STRUCTURE_TYPES'
export const MUTATE_SPECIALIST_CONSULTANTS = 'shared/MUTATE_SPECIALIST_CONSULTANTS'
export const MUTATE_CLOSER_CONSULTANTS = 'shared/MUTATE_CLOSER_CONSULTANTS'
export const MUTATE_PARTNER_COMPANIES = 'shared/MUTATE_PARTNER_COMPANIES'
export const MUTATE_PRODUCTS = 'shared/MUTATE_PRODUCTS'
export const MUTATE_CREDIT_TYPES = 'shared/MUTATE_CREDIT_TYPES'
export const MUTATE_SCHOOL_LEVELS = 'shared/MUTATE_SCHOOL_LEVELS'
export const MUTATE_LEAD_ORIGINS = 'shared/MUTATE_LEAD_ORIGINS'
export const MUTATE_LEAD_GOALS = 'shared/MUTATE_LEAD_GOALS'
export const MUTATE_WITHDRAWAL_SUMMARY = 'shared/MUTATE_WITHDRAWAL_SUMMARY'
export const MUTATE_PRESENT_BILLING_DATE = 'shared/MUTATE_PRESENT_BILLING_DATE'
export const MUTATE_BILLING_PAYMENT_DEADLINE = 'shared/MUTATE_BILLING_PAYMENT_DEADLINE'
export const MUTATE_EMPLOYEE_PAYMENTS_INFO = 'shared/MUTATE_EMPLOYEE_PAYMENTS_INFO'
export const MUTATE_DEPARTMENTS = 'shared/MUTATE_DEPARTMENTS'
export const MUTATE_EMPLOYEES = 'shared/MUTATE_EMPLOYEES'
export const MUTATE_WITHDRAWAL_STATUSES = 'shared/MUTATE_WITHDRAWAL_STATUSES'
export const MUTATE_CONSULTANTS_PARTNER = 'shared/MUTATE_CONSULTANT_PARTNERS'
export const MUTATE_WITHDRAWAL_CATEGORIES = 'shared/MUTATE_WITHDRAWAL_CATEGORIES'
export const MUTATE_PAYMENT_GATEWAYS = 'shared/MUTATE_PAYMENT_GATEWAYS'
export const MUTATE_ACTIVE_CONSULTANTS = 'shared/MUTATE_ACTIVE_CONSULTANTS'
export const MUTATE_QR_CODE_SIDEBAR = 'shared/MUTATE_QR_CODE_SIDEBAR'
export const MUTATE_CAMPAIGNS = 'shared/MUTATE_CAMPAIGNS'
export const MUTATE_COST_CENTERS = 'shared/MUTATE_COST_CENTERS'
export const MUTATE_COST_CENTER_CATEGORIES = 'shared/MUTATE_COST_CENTER_CATEGORIES'
export const MUTATE_ALL_COST_CENTER_CATEGORIES = 'shared/MUTATE_ALL_COST_CENTER_CATEGORIES'
export const MUTATE_PAYMENT_TYPES = 'shared/MUTATE_PAYMENT_TYPES'
export const MUTATE_GROUP_COMPANIES = 'shared/MUTATE_GROUP_COMPANIES'
export const MUTATE_EMPLOYEES_BY_COST_CENTER = 'shared/MUTATE_EMPLOYEES_BY_COST_CENTER'


// Actions
export const GET_BUSINESS_CONFIG_PRODUCT_TAX = 'shared/GET_BUSINESS_CONFIG_PRODUCT_TAX'
export const GET_BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS = 'shared/GET_BUSINESS_CONFIG_BILLING_CHANGE_REQUEST_DEADLINE_FOR_PNS'
export const GET_BUSINESS_CONFIG_WHATSAPP_SUPPORT = 'shared/GET_BUSINESS_CONFIG_WHATSAPP_SUPPORT'
export const GET_BUSINESS_CONFIG_X_MOMENT_PDF = 'shared/GET_BUSINESS_CONFIG_X_MOMENT_PDF'
export const GET_CUSTOMERS = 'shared/GET_CUSTOMERS'
export const GET_ALL_CUSTOMERS = 'shared/GET_ALL_CUSTOMERS'
export const GET_CUSTOMER = 'shared/GET_CUSTOMER'
export const GET_CONSULTANT = 'shared/GET_CONSULTANT'
export const OPEN_FILE_UPLOAD_SIDEBAR = 'shared/OPEN_FILE_UPLOAD_SIDEBAR'
export const OPEN_COMMENT_SIDEBAR  = 'shared/OPEN_COMMENT_SIDEBAR'
export const GET_COMPANY_AGENT = 'shared/GET_COMPANY_AGENT'
export const GET_APPOINTMENT = 'shared/GET_APPOINTMENT'
export const GET_CUSTOM_FIELD_TYPES = 'shared/GET_CUSTOM_FIELD_TYPES'
export const GET_ADDRESS_STATES = 'shared/GET_ADDRESS_STATES'
export const GET_ADDRESS_CITIES = 'shared/GET_ADDRESS_CITIES'
export const GET_ADDRESS_FROM_CEP = 'shared/GET_ADDRESS_FROM_CEP'
export const GET_INCOMES = 'shared/GET_INCOMES'
export const GET_OCCUPATIONS = 'shared/GET_OCCUPATIONS'
export const GET_BANKS = 'shared/GET_BANKS'
export const GET_PIX_KEY_TYPES = 'shared/GET_PIX_KEY_TYPES'
export const GET_INTERVIEW_TOKEN = 'shared/GET_INTERVIEW_TOKEN'
export const GET_CONSULTANT_APPROVAL_DATES = 'shared/GET_CONSULTANT_APPROVAL_DATES'
export const GET_CONSULTANT_FUTURE_APPROVAL_DATES = 'shared/GET_CONSULTANT_FUTURE_APPROVAL_DATES'
export const GET_FRANCHISES = 'shared/GET_FRANCHISES'
export const GET_PHYSICAL_FRANCHISES = 'shared/GET_PHYSICAL_FRANCHISES'
export const GET_ROLES = 'shared/GET_ROLES'
export const GET_CONSULTANTS = 'shared/GET_CONSULTANTS'
export const GET_ALL_CONSULTANTS = 'shared/GET_ALL_CONSULTANTS'
export const GET_STRUCTURE_TYPES = 'shared/GET_STRUCTURE_TYPES'
export const SEND_INTERVIEW_WHATSAPP = 'shared/SEND_INTERVIEW_WHATSAPP'
export const SEND_INTERVIEW_EMAIL = 'shared/SEND_INTERVIEW_EMAIL'
export const GET_SPECIALIST_CONSULTANTS = 'shared/GET_SPECIALIST_CONSULTANTS'
export const GET_CLOSER_CONSULTANTS = 'shared/GET_CLOSER_CONSULTANTS'
export const GET_PARTNER_COMPANIES = 'shared/GET_PARTNER_COMPANIES'
export const GET_PRODUCTS = 'shared/GET_PRODUCTS'
export const GET_CREDIT_TYPES = 'shared/GET_CREDIT_TYPES'
export const GET_SCHOOL_LEVELS = 'shared/GET_SCHOOL_LEVELS'
export const GET_LEAD_ORIGINS = 'shared/GET_LEAD_ORIGINS'
export const GET_LEAD_BY_CUSTOMER = 'shared/GET_LEAD_BY_CUSTOMER'
export const GET_LEAD_GOALS = 'shared/GET_LEAD_GOALS'
export const SAVE_CUSTOMER_COMMENT = 'shared/SAVE_CUSTOMER_COMMENT'
export const GET_WITHDRAWAL_SUMMARY = 'shared/GET_WITHDRAWAL_SUMMARY'
export const GET_PRESENT_BILLING_DATE = 'shared/GET_PRESENT_BILLING_DATE'
export const GET_BILLING_PAYMENT_DEADLINE = 'shared/GET_BILLING_PAYMENT_DEADLINE'
export const GET_EMPLOYEE_PAYMENTS_INFO = 'shared/GET_EMPLOYEE_PAYMENTS_INFO'
export const GET_DEPARTMENTS = 'shared/GET_DEPARTMENTS'
export const GET_EMPLOYEES = 'shared/GET_EMPLOYEES'
export const GET_WITHDRAWAL_STATUSES = 'shared/GET_WITHDRAWAL_STATUSES'
export const GET_CONSULTANTS_PARTNER = 'shared/GET_CONSULTANT_PARTNERS'
export const GET_WITHDRAWAL_CATEGORIES = 'shared/GET_WITHDRAWAL_CATEGORIES'
export const GET_PAYMENT_GATEWAYS = 'shared/GET_PAYMENT_GATEWAYS'
export const GET_ACTIVE_CONSULTANTS = 'shared/GET_ACTIVE_CONSULTANTS'
export const OPEN_QR_CODE_SIDEBAR = 'shared/OPEN_QR_CODE_SIDEBAR'
export const GET_CAMPAIGNS = 'shared/GET_CAMPAIGNS'
export const GET_COST_CENTERS = 'shared/GET_COST_CENTERS'
export const GET_COST_CENTER_CATEGORIES = 'shared/GET_COST_CENTER_CATEGORIES'
export const GET_ALL_COST_CENTER_CATEGORIES = 'shared/GET_ALL_COST_CENTER_CATEGORIES'
export const GET_PAYMENT_TYPES = 'shared/GET_PAYMENT_TYPES'
export const GET_GROUP_COMPANIES = 'shared/GET_GROUP_COMPANIES'
export const GET_EMPLOYEES_BY_COST_CENTER = "accounting_management/GET_EMPLOYEES_BY_COST_CENTER";