import * as types from './types'

export default {
  [types.MUTATE_PRODUCTS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_NETWORK_DISTRIBUTIONS]: (state, payload) => {
    state.networkDistributions = payload
  }
}
