import * as types from "./types";

export default {
  [types.ABILITIES]: (state) => {
    return state.abilities;
  },
  [types.DEPARTMENT_USERS]: (state) => {
    return state.departmentUers;
  },
  [types.PROFILES_RESULT]: (state) => {
    return state.profilesResult;
  },
};
