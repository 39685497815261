import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  notifications: undefined,
  notificationList: undefined,
  unreadNotifications: undefined,
  notificationSidebar: {
    id: undefined,
    title: undefined,
    type: undefined,
    tag: undefined,
    metadata: undefined,
    visible: false,
    markAsRead: true
  },
  notificationsCounter: {
    notifications: undefined,
    pendencies: undefined,
    solveds: undefined
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
