// Getters
export const NPS_STRUCTURE_SEARCH_RESULT = 'nps/NPS_STRUCTURE_SEARCH_RESULT'
export const NPS_CONSULTANT_SEARCH_RESULT = 'nps/NPS_CONSULTANT_SEARCH_RESULT'
export const NPS_STATUSES = 'nps/NPS_STATUSES'
export const NPS_CUSTOMERS_LIST = 'nps/NPS_CUSTOMERS_LIST'
export const NPS_FRANCHISES_CONSULTANTS_LIST = 'nps/NPS_FRANCHISES_CONSULTANTS_LIST'
export const NPS_TOTALIZER = 'nps/NPS_TOTALIZER'
export const NPS_FRANCHISE_TOTALIZER = 'nps/NPS_FRANCHISE_TOTALIZER'

// Mutations
export const MUTATE_NPS_STRUCTURE_SEARCH_RESULT = 'nps/MUTATE_NPS_STRUCTURE_SEARCH_RESULT'
export const MUTATE_NPS_CONSULTANT_SEARCH_RESULT = 'nps/MUTATE_NPS_CONSULTANT_SEARCH_RESULT'
export const MUTATE_NPS_STATUSES = 'nps/MUTATE_NPS_STATUSES'
export const MUTATE_NPS_CUSTOMERS_LIST = 'nps/MUTATE_NPS_CUSTOMERS_LIST'
export const MUTATE_NPS_FRANCHISES_CONSULTANTS_LIST = 'nps/MUTATE_NPS_FRANCHISES_CONSULTANTS_LIST'
export const MUTATE_NPS_TOTALIZER = 'nps/MUTATE_NPS_TOTALIZER'
export const MUTATE_NPS_FRANCHISE_TOTALIZER = 'nps/MUTATE_NPS_FRANCHISE_TOTALIZER'


// Actions
export const SEARCH_STRUCTURE_NPS = 'nps/SEARCH_STRUCTURE_NPS'
export const SEARCH_CONSULTANT_NPS = 'nps/SEARCH_CONSULTANT_NPS'
export const GET_NPS_STATUSES = 'nps/GET_NPS_STATUSES'
export const GET_NPS_CUSTOMERS_LIST = 'nps/GET_NPS_CUSTOMERS_LIST'
export const RESEND_TO_WHATSAPP = 'nps/RESEND_TO_WHATSAPP'
export const GET_NPS_FRANCHISES_CONSULTANTS_LIST = 'nps/SEARCH_NPS_FRANCHISES_CONSULTANTS_LIST'
export const GET_NPS_TOTALIZER = 'nps/GET_NPS_TOTALIZER'
export const GET_NPS_FRANCHISE_TOTALIZER = 'nps/GET_NPS_FRANCHISE_TOTALIZER'


