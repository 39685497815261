// Getters
export const PRODUCTS_SEARCH_RESULT = 'product/PRODUCTS_SEARCH_RESULT'
export const NETWORK_DISTRIBUTIONS = 'product/NETWORK_DISTRIBUTIONS'

// Mutations
export const MUTATE_PRODUCTS_SEARCH_RESULT = 'product/MUTATE_PRODUCTS_SEARCH_RESULT'
export const MUTATE_NETWORK_DISTRIBUTIONS = 'product/MUTATE_NETWORK_DISTRIBUTIONS'

// Actions
export const GET_NETWORK_DISTRIBUTIONS = 'product/GET_NETWORK_DISTRIBUTIONS'
export const SEARCH_PRODUCTS = 'product/SEARCH_PRODUCTS'
export const GET_PRODUCT = 'product/GET_PRODUCT'
export const SAVE_PRODUCT = 'product/SAVE_PRODUCT'
export const DELETE_PRODUCT = 'product/DELETE_PRODUCT'
