// Getters
export const WITHDRAWALS =  'withdrawal/WITHDRAWALS'
export const WITHDRAWAL_REQUEST_SIDEBAR = 'withdrawal/WITHDRAWAL_REQUEST_SIDEBAR'
export const WITHDRAWAL_DETAILS_SIDEBAR = 'withdrawal/WITHDRAWAL_DETAILS_SIDEBAR'

// Mutations
export const MUTATE_WITHDRAWALS = 'withdraw/MUTATE_WITHDRAWALS'
export const MUTATE_WITHDRAWAL_REQUEST_SIDEBAR = 'withdrawal/MUTATE_WITHDRAWAL_REQUEST_SIDEBAR'
export const MUTATE_WITHDRAWAL_DETAILS_SIDEBAR = 'withdrawal/MUTATE_WITHDRAWAL_DETAILS_SIDEBAR'

// Actions
export const OPEN_WITHDRAWAL_REQUEST_SIDEBAR = 'withdrawal/OPEN_WITHDRAWAL_REQUEST_SIDEBAR'
export const SAVE_WITHDRAWAL_REQUEST = 'withdrawal/SAVE_WITHDRAWAL_REQUEST'
export const GET_WITHDRAWALS = 'withdrawal/GET_WITHDRAWALS'
export const DELETE_WITHDRAWAL = 'withdrawal/DELETE_WITHDRAWAL'
export const OPEN_WITHDRAWAL_DETAILS_SIDEBAR = 'withdrawal/OPEN_WITHDRAWAL_DETAILS_SIDEBAR'
export const GET_WITHDRAWAL_DETAILS = 'withdrawal/GET_WITHDRAWAL_DETAILS'
export const GET_WITHDRAWAL_DOCUMENT_REQUIRED = 'withdrawal/GET_WITHDRAWAL_DOCUMENT_REQUIRED'