import axios from "axios";
import * as types from "./types";

export default {
  [types.OPEN_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.SEARCH_FINANCIAL_ADJUSTMENTS]: ({ commit }, payload) => {
    const resource = `/api/financial_adjustments/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_FINANCIAL_ADJUSTMENTS_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_FINANCIAL_ADJUSTMENT]: ({}, payload) => {
    return axios
      .get(`/api/financial_adjustments/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_FINANCIAL_ADJUSTMENT]: ({}, payload) => {
    let save = axios.post;
    let route = "/api/financial_adjustments";
    if (payload.id) {
      save = axios.put;
      route = `/api/financial_adjustments/${payload.id}`;
    }
    return save(route, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_FINANCIAL_ADJUSTMENT]: ({}, payload) => {
    return axios
      .delete(`/api/financial_adjustments/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_FINANCIAL_ADJUSTMENT_REASONS]: ({ commit }) => {
    return axios
      .get(`/api/financial_adjustments/reasons`)
      .then((resp) => {
        commit(types.MUTATE_FINANCIAL_ADJUSTMENT_REASONS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_FINANCIAL_ADJUSTMENT_TYPES]: ({ commit }) => {
    return axios
      .get(`/api/financial_adjustments/types`)
      .then((resp) => {
        commit(types.MUTATE_FINANCIAL_ADJUSTMENT_TYPES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_FINANCIAL_ADJUSTMENT_CREDIT_TYPES]: ({ commit }) => {
    return axios
      .get(`/api/financial_adjustments/credit_types`)
      .then((resp) => {
        commit(types.MUTATE_FINANCIAL_ADJUSTMENT_CREDIT_TYPES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_FINANCIAL_ADJUSTMENT_TOTALIZER]: ({ commit }, payload) => {
    return axios
      .post(`/api/financial_adjustments/totalizer`, payload)
      .then((resp) => {
        commit(types.MUTATE_FINANCIAL_ADJUSTMENT_TOTALIZER, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT]: ({ commit }, payload) => {
    const resource = `/api/financial_adjustments/search_by_month?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
};
