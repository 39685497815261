import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  customer: undefined,
  userPersonalDataSidebar: {
    user: undefined,
    visible: false,
    saveAction: undefined,
  },
  userAddressSidebar: {
    user: undefined,
    visible: false,
    saveAction: undefined,
  },
  uploadFileSidebar: {
    id: undefined,
    files: [],
    visible: false,
    saveAction: undefined,
  },
  customerComments: [],
  customerTimelineEvents: [],
  customerAppointments: [],
  customerContracts: [],
  customerFiles: [],
  customerRecommendations: [],
};

export default {
  state,
  actions,
  getters,
  mutations,
};
