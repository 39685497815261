import * as types from './types'

export default {
  [types.MUTATE_NOTIFICATIONS]: (state, payload) => {
    state.notifications = payload
  },
  [types.MUTATE_NOTIFICATION_LIST]: (state, payload) => {
    state.notificationList = payload
  },
  [types.MUTATE_UNREAD_NOTIFICATIONS]: (state, payload) => {
    state.unreadNotifications = payload
  },
  [types.MUTATE_NOTIFICATION_SIDEBAR]: (state, payload) => {
    state.notificationSidebar = { ...state.notificationSidebar, ...payload }
  },
  [types.MUTATE_NOTIFICATIONS_COUNTER]: (state, payload) => {
    state.notificationsCounter = payload
  },
}
