import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  searchResult: [],
  adjustmentReasons: [],
  adjustmentCreditTypes: [],
  adjustmentTypes: [],
  saveFinancialAdjustmentSidebar: {
    id: undefined,
    visible: false,
    saveAction: () => {}
  },
  adjustmentTotalizer: undefined,
  adjustmentDetailSidebar: {
    id: undefined,
    visible: false
  },
  adjustmentsByMonth: [],
}

export default {
  state,
  actions,
  getters,
  mutations
}
