import {
  READ_ACTION,
  CONTRACT_RESOURCE
} from '@/constants/resources'

export const initialAbility = [
  {
    resource: CONTRACT_RESOURCE,
    action: READ_ACTION,
  }
]

export const _ = undefined
