import axios from "axios";
import * as types from "./types";
import moment from "moment";
import { PENDING_TASKS } from "@/constants/todo_types";
import * as pagination from "@/constants/pagination";

export default {
  [types.OPEN_NEW_TO_DO_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_TO_DO_SIDEBAR, { ...payload, visible: true });
  },
  [types.GET_TASKS_FROM_TODAY]: ({ commit }, payload) => {
    if(!payload)
    {
      payload = {
        currentPage: pagination.DEFAULT_CURRENT_PAGE,
        limit: pagination.DEFAULT_PAGE_LIMIT
      }
    }
    payload = {
      ...payload,
      date_start: moment().format("YYYY-MM-DD"),
      task_type: [PENDING_TASKS]
    };
    const resource = `/api/tasks/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_TASKS_FROM_TODAY, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.MARK_AS_COMPLETED]: ({ commit }, id) => {
    return axios
      .get(`/api/tasks/${id}/mark_as_completed`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.STORE_TO_DO]: ({}, payload) => {
    let save = axios.post;
    let route = "/api/tasks";
    if (payload.id) {
      save = axios.put;
      route = `/api/tasks/${payload.id}`;
      delete payload.id;
    }
    return save(route, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_TASK]: ({}, payload) => {
    return axios
      .get(`/api/tasks/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_TODOS_INBOX]: ({ commit }, payload) => {
    const resource = `/api/tasks/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_TODOS_INBOX_SEARCH_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_TASK]: ({}, payload) => {
    return axios
      .delete(`/api/tasks/${payload.id}`, {
        data: { delete_type: payload.delete_type },
      })
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.MARK_AS_IMPORTANT]: ({}, id) => {
    return axios
      .get(`/api/tasks/${id}/mark_as_important`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.OPEN_TO_DO_DETAILS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_TO_DO_DETAILS_SIDEBAR, { ...payload, visible: true });
  },
  [types.GET_TASK]: ({}, payload) => {
    return axios
      .get(`/api/tasks/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_TODOS_FLOAT_SIDEBAR]: ({commit}, payload) => {
    const resource = `/api/tasks/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_TODOS_FLOAT_SIDEBAR, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
