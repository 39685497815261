import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  searchResult: undefined,
  networkDistributions: undefined
}

export default {
  state,
  actions,
  getters,
  mutations
}
