import axios from 'axios'
import * as types from './types'

export default {
  [types.SEARCH_STRUCTURE_NPS]: ({ commit }, payload) => {
    const resource = `/api/nps_surveys/by_structure?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
				commit(types.MUTATE_NPS_STRUCTURE_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_CONSULTANT_NPS]: ({ commit }, payload) => {
    const resource = `/api/nps_surveys/by_consultant?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
				commit(types.MUTATE_NPS_CONSULTANT_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.RESEND_TO_WHATSAPP]: ({ commit }, token) => {
    const resource = `/api/nps_surveys/nps_customer/${token}`;
    return axios
      .post(resource)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_NPS_TOTALIZER]: ({ commit }, payload) => {
    return axios
      .post(`/api/nps_surveys/totalizers`, payload)
      .then((resp) => {
        commit(types.MUTATE_NPS_TOTALIZER, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_NPS_FRANCHISE_TOTALIZER]: ({ commit }, payload) => {
    return axios
      .post(`/api/nps_surveys/franchise_totalizers`, payload)
      .then((resp) => {
        commit(types.MUTATE_NPS_FRANCHISE_TOTALIZER, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
	[types.GET_NPS_STATUSES]: ({ commit }, payload) => {
    return axios
      .get(`/api/nps_surveys/statuses`)
      .then((resp) => {
				commit(types.MUTATE_NPS_STATUSES, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_NPS_CUSTOMERS_LIST]: ({ commit }, payload) => {
    const resource = `/api/nps_surveys/by_customers?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
				commit(types.MUTATE_NPS_CUSTOMERS_LIST, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_NPS_FRANCHISES_CONSULTANTS_LIST]: ({ commit }, payload) => {
    const resource = `/api/nps_surveys/by_franchises_consultants`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
				commit(types.MUTATE_NPS_FRANCHISES_CONSULTANTS_LIST, resp.data)
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
}
