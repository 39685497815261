import axios from 'axios'
import { getCredentials } from '@/helpers/credentials'
import { ADMIN_CREDENTIALS_STORAGE_KEY } from '@/constants/auth'
import * as types from './types'

let kpiClient = undefined
const getClientInstance = () => {
  if (!kpiClient) {
    kpiClient = axios.create({})
    kpiClient.defaults.baseURL = process.env.VUE_APP_KPI_ENDPOINT
    kpiClient.defaults.withCredentials = false
    kpiClient.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCredentials(ADMIN_CREDENTIALS_STORAGE_KEY)?.token}`
    }
  }
  return kpiClient
}

export default {
  [types.GET_CONSULTANTS]: ({ commit }, payload) => {
    return axios.get('/api/consultants/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_TOP_BY_SCHEDULED_QUANTITY]: ({ commit }, payload) => {
    return getClientInstance().get('/api/ranking/by_quantity/scheduled', { params: payload })
      .then(resp => {
        commit(types.MUTATE_TOP_BY_SCHEDULED_QUANTITY, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_TOP_BY_DONE_QUANTITY]: ({ commit }, payload) => {
    return getClientInstance().get('/api/ranking/by_quantity/done', { params: payload })
      .then(resp => {
        commit(types.MUTATE_TOP_BY_DONE_QUANTITY, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_TOP_BY_PAID_AMOUNT]: ({ commit }, payload) => {
    return getClientInstance().get('/api/ranking/by_amount/paid', { params: payload })
      .then(resp => {
        commit(types.MUTATE_TOP_BY_PAID_AMOUNT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANT_AND_CHILDS]: ({ commit, dispatch }, payload) => {
    return getClientInstance().get(`/api/consultant_performance/childs`, { params: payload })
      .then(resp => {
        commit(types.MUTATE_KPI_LIST, resp.data)
        dispatch(types.GET_LEADS_PERFOMANCE, payload)
        dispatch(types.GET_HOT_LEADS_PERFOMANCE, payload)
        dispatch(types.GET_APPOINTMENTS_PERFORMANCE, payload)
        dispatch(types.GET_APPOINTMENT_REPORTS_PERFORMANCE, payload)
        dispatch(types.GET_INTERVIEWS_PERFORMANCE, payload)
        dispatch(types.GET_CONTRACTS_PERFORMANCE, payload)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_LEADS_PERFOMANCE]: ({ commit }, payload) => {
    return getClientInstance().get(`/api/consultant_performance/leads`, { params: payload })
      .then(resp => {
        commit(types.MUTATE_KPI_LIST_CONTENT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_HOT_LEADS_PERFOMANCE]: ({ commit }, payload) => {
    return getClientInstance().get(`/api/consultant_performance/hot_leads`, { params: payload })
      .then(resp => {
        commit(types.MUTATE_KPI_LIST_CONTENT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_APPOINTMENTS_PERFORMANCE]: ({ commit }, payload) => {
    return getClientInstance().get(`/api/consultant_performance/appointments`, { params: payload })
      .then(resp => {
        commit(types.MUTATE_KPI_LIST_CONTENT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_APPOINTMENT_REPORTS_PERFORMANCE]: ({ commit }, payload) => {
    return getClientInstance().get(`/api/consultant_performance/appointment_reports`, { params: payload })
      .then(resp => {
        commit(types.MUTATE_KPI_LIST_CONTENT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_INTERVIEWS_PERFORMANCE]: ({ commit }, payload) => {
    return getClientInstance().get(`/api/consultant_performance/interviews`, { params: payload })
      .then(resp => {
        commit(types.MUTATE_KPI_LIST_CONTENT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONTRACTS_PERFORMANCE]: ({ commit }, payload) => {
    return getClientInstance().get(`/api/consultant_performance/contracts`, { params: payload })
      .then(resp => {
        commit(types.MUTATE_KPI_LIST_CONTENT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
