import * as types from './types'

export default {
  [types.FINANCIAL_SOLUTION_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.COMPANIES_FINANCIAL_SOLUTION]: state => {
    return state.companiesList
  },
  [types.PRODUCTS_FINANCIAL_SOLUTION]: state => {
    return state.productsList
  },
  [types.PROVISIONING_TYPES]: state => {
    return state.provisioningTypes
  },


}
