import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  appointmentsFromToday: [],
  birthdaysThisMonth: [],
  customersReminder: [],
  leadsReminder: [],
  banners: []
};

export default {
  state,
  actions,
  getters,
  mutations,
};
