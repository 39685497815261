// Getters
export const APPOINTMENTS_SEARCH_RESULT =
  "appointment/APPOINTMENTS_SEARCH_RESULT";
export const APPOINTMENTS_SEARCH_TOTALIZERS =
  "appointment/APPOINTMENTS_SEARCH_TOTALIZERS";
export const APPOINTMENT_EVENT_TYPES = "appointment/APPOINTMENT_EVENT_TYPES";
export const NEW_APPOINTMENT_SIDEBAR = "appointment/NEW_APPOINTMENT_SIDEBAR";
export const APPOINTMENT_CUSTOMERS = "appointment/APPOINTMENT_CUSTOMERS";
export const APPOINTMENT_REPORT_SIDEBAR =
  "appointment/APPOINTMENT_REPORT_SIDEBAR";
export const APPOINTMENT_REPORT_DETAILS_SIDEBAR =
  "appointment/APPOINTMENT_REPORT_DETAILS_SIDEBAR";
export const CONSULTANTS_SPECIALIST = "appointment/CONSULTANTS_SPECIALIST";

// Mutations
export const MUTATE_APPOINTMENTS_SEARCH_RESULT =
  "appointment/MUTATE_APPOINTMENTS_SEARCH_RESULT";
  export const MUTATE_APPOINTMENTS_SEARCH_TOTALIZERS =
    "appointment/MUTATE_APPOINTMENTS_SEARCH_TOTALIZERS";
export const MUTATE_APPOINTMENT_EVENT_TYPES =
  "appointment/MUTATE_APPOINTMENT_EVENT_TYPES";
export const MUTATE_NEW_APPOINTMENT_SIDEBAR =
  "appointment/MUTATE_NEW_APPOINTMENT_SIDEBAR";
export const MUTATE_APPOINTMENT_CUSTOMERS =
  "appointment/MUTATE_APPOINTMENT_CUSTOMERS";
export const MUTATE_APPOINTMENT_REPORT_SIDEBAR =
  "appointment/MUTATE_APPOINTMENT_REPORT_SIDEBAR";
export const MUTATE_APPOINTMENT_REPORT_DETAILS_SIDEBAR =
  "appointment/MUTATE_APPOINTMENT_REPORT_DETAILS_SIDEBAR";
export const MUTATE_CONSULTANTS_SPECIALIST =
  "appointment/MUTATE_CONSULTANTS_SPECIALIST";

// Actions
export const SEARCH_APPOINTMENTS = "appointment/SEARCH_APPOINTMENTS";
export const GET_APPOINTMENT = "appointment/GET_APPOINTMENT";
export const SAVE_APPOINTMENT = "appointment/SAVE_APPOINTMENT";
export const DELETE_APPOINTMENT = "appointment/DELETE_APPOINTMENT";
export const GET_APPOINTMENT_EVENT_TYPES =
  "appointment/GET_APPOINTMENT_EVENT_TYPES";
export const OPEN_NEW_APPOINTMENT_SIDEBAR =
  "appointment/OPEN_NEW_APPOINTMENT_SIDEBAR";
export const GET_APPOINTMENT_CUSTOMERS =
  "appointment/GET_APPOINTMENT_CUSTOMERS";
export const STORE_APPOINTMENT = "appointment/STORE_APPOINTMENT";
export const OPEN_APPOINTMENT_REPORT_SIDEBAR =
  "appointment/OPEN_APPOINTMENT_REPORT_SIDEBAR";
export const STORE_REPORT = "appointment/STORE_REPORT";
export const GET_APPOINTMENT_REPORT_PENDENCIES =
  "appointment/GET_APPOINTMENT_REPORT_PENDENCIES";
export const EXPORT_APPOINTMENTS = "appointment/EXPORT_APPOINTMENTS";
export const OPEN_APPOINTMENT_REPORT_DETAILS_SIDEBAR =
  "appointment/OPEN_APPOINTMENT_REPORT_DETAILS_SIDEBAR";
export const GET_CONSULTANTS_SPECIALIST =
  "appointment/GET_CONSULTANTS_SPECIALIST";
export const CHECK_FOR_OVERLAPPING_APPOINTMENTS =
  "appointment/CHECK_FOR_OVERLAPPING_APPOINTMENTS";
