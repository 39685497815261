import axios from 'axios'
import * as types from './types'

export default {
  [types.GET_COMPANIES_FINANCIAL_SOLUTION]: ({ commit }, payload) => {
    return axios.get('/api/partner_companies/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_COMPANIES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PRODUCTS_FINANCIAL_SOLUTION]: ({ commit }, payload) => {
    return axios.get('/api/products/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_PRODUCTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_FINANCIAL_SOLUTION]: ({ commit }, payload) => {
    const resource = `/api/financial_solutions/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_FINANCIAL_SOLUTION_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_FINANCIAL_SOLUTION]: ({}, payload) => {
    return axios.get(`/api/financial_solutions/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_FINANCIAL_SOLUTION]: ({}, payload) => {
    let save = axios.post
    let route = '/api/financial_solutions'
    if (payload.id) {
      save = axios.put
      route = `/api/financial_solutions/${payload.id}`
    }
    return save(route, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_FINANCIAL_SOLUTION]: ({}, payload) => {
    return axios.delete(`/api/financial_solutions/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PROVISIONING_TYPES]: ({ commit }, payload) => {
    return axios.get(`/api/common/provisioning_types`)
      .then(resp => {
        commit(types.MUTATE_PROVISIONING_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
