import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  searchResult: undefined,
  searchPaidResult: undefined,
  searchExpectedResult: undefined,
  paymentSidebar:{
    id: undefined,
    saveAction: undefined,
    visible: false,
  },
  billDetailsSidebar:{
    id: undefined,
    visible: false
  },
  activeBillingMonth: undefined,
  contractPaymentsSearchResult: undefined,
  paymentConciliators: [],
  paymentProducts: [],
  paymentsImportsList: [],
  importPaymentsSidebar: {
    saveAction: undefined,
    visible: false
  },
  paymentsImportsDetailsList: [],
  paymentsImportsDetailsTotalizer: undefined
}

export default {
  state,
  actions,
  getters,
  mutations
}
