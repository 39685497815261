import axios from 'axios'
import * as types from './types'

export default {
  [types.GET_NETWORK_DISTRIBUTIONS]: ({ commit }, payload) => {
    return axios.get('/api/network_distributions/summary')
      .then(resp => {
        commit(types.MUTATE_NETWORK_DISTRIBUTIONS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_PRODUCTS]: ({ commit }, payload) => {
    const resource = `/api/products/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_PRODUCTS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PRODUCT]: ({}, payload) => {
    return axios.get(`/api/products/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_PRODUCT]: ({}, payload) => {
    let save = axios.post
    let route = '/api/products'
    if (payload.id) {
      save = axios.put
      route = `/api/products/${payload.id}`
    }
    return save(route, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_PRODUCT]: ({}, payload) => {
    return axios.delete(`/api/products/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
}
