import axios from 'axios'
import * as types from './types'

export default {
  [types.GET_WITHDRAWALS]: ({ commit }, payload) => {
    const resource = `/api/withdrawals/requests?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_WITHDRAWALS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_WITHDRAWAL_REQUEST_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_WITHDRAWAL_REQUEST_SIDEBAR, { ...payload, visible: true })
  },
  [types.SAVE_WITHDRAWAL_REQUEST]: ({}, payload) => {
    return axios.post(`/api/withdrawals/requests/create`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_WITHDRAWAL]: ({}, payload) => {
    return axios.delete(`/api/withdrawals/requests/delete`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_WITHDRAWAL_DETAILS_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_WITHDRAWAL_DETAILS_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_WITHDRAWAL_DETAILS]: ({}, payload) => {
    return axios.get(`/api/withdrawals/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_WITHDRAWAL_DOCUMENT_REQUIRED]: ({}, payload) => {
    return axios.get(`/api/withdrawals/requests/is_document_required`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
