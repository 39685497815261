import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  searchResult: undefined,
  details: {
    generalInformation: undefined,
  },
  resolveTicketSidebar: {
    id: undefined,
    visible: false,
    saveAction: undefined,
  },
  ticketTypes: [],
  ticketStatus: []
}

export default {
  state,
  actions,
  getters,
  mutations
}
