import axios from "axios";
import * as types from "./types";

export default {
  [types.OPEN_NEW_FRANCHISE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_FRANCHISE_SIDEBAR, { ...payload, visible: true });
  },
  [types.OPEN_NEW_PHYSICAL_FRANCHISE_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_NEW_PHYSICAL_FRANCHISE_SIDEBAR, {
      ...payload,
      visible: true,
    });
  },
  [types.GET_DIGITAL_FRANCHISE]: ({}, payload) => {
    return axios
      .get(`/api/digital_franchises/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_PHYSICAL_FRANCHISE]: ({}, payload) => {
    return axios
      .get(`/api/physical_franchises/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEARCH_DIGITAL_FRANCHISES]: ({ commit }, payload) => {
    const resource = `/api/digital_franchises/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_DIGITAL_FRANCHISES_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.SEARCH_PHYSICAL_FRANCHISES]: ({ commit }, payload) => {
    const resource = `/api/physical_franchises/search?page=${payload.currentPage}`;
    delete payload.currentPage;
    return axios
      .post(resource, payload)
      .then((resp) => {
        commit(types.MUTATE_PHYSICAL_FRANCHISES_RESULT, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.STORE_FRANCHISE]: ({}, payload) => {
    return axios
      .post(`/api/digital_franchises`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.STORE_PHYSICAL_FRANCHISE]: ({}, payload) => {
    return axios
      .post(`/api/physical_franchises`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.EDIT_FRANCHISE]: ({}, payload) => {
    return axios
      .post(`/api/digital_franchises/${payload.id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.EDIT_PHYSICAL_FRANCHISE]: ({}, payload) => {
    return axios
      .post(`/api/physical_franchises/${payload.id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  [types.GET_RESPONSABLES]: ({ commit }, payload) => {
    return axios
      .get("/api/digital_franchises/summaryConsultants", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_RESPONSABLES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_PHYSICAL_RESPONSABLES]: ({ commit }, payload) => {
    return axios
      .get("/api/physical_franchises/summaryConsultants", { params: payload })
      .then((resp) => {
        commit(types.MUTATE_PHYSICAL_RESPONSABLES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
