import {
  READ_ACTION,
  MANAGE_ACTION,
  NPS_SURVEY,
  NPS_SURVEY_FRANCHISE
} from '@/constants/resources'

  
export default [
  {
    path: '/nps/estrutura',
    name: 'nps-structure',
    component: () => import('@/modules/nps/views/NpsStructure.vue'),
    meta: {
      resource: NPS_SURVEY,
      action: MANAGE_ACTION,
      pageTitle: "NPS Estrutura",
    },
  },
  {
    path: '/nps/consultant',
    name: 'nps-consultant',
    component: () => import('@/modules/nps/views/NpsConsultant.vue'),
    meta: {
      resource: NPS_SURVEY,
      action: READ_ACTION,
      pageTitle: "Meus NPS",
    },
  },
  {
    path: '/nps/consultor/:consultant?',
    name: 'nps-consultant-details',
    component: () => import('@/modules/nps/views/NpsCustomersList.vue'),
    meta: {
      resource: NPS_SURVEY,
      action: READ_ACTION,
      pageTitle: "NPS - Detalhes do PN",
    },
  },
  {
    path: '/nps/franquias',
    name: 'nps-franchises',
    component: () => import('@/modules/nps/views/NpsFranchisesConsultantsList.vue'),
    meta: {
      resource: NPS_SURVEY_FRANCHISE,
      action: READ_ACTION,
      pageTitle: "NPS Franquias",
    },
    
  },
  {
    path: '/nps/franquia/:franchise?',
    name: 'nps-franchise-details',
    component: () => import('@/modules/nps/views/NpsFranchisesConsultantsList.vue'),
    meta: {
      resource: NPS_SURVEY_FRANCHISE,
      action: READ_ACTION,
      pageTitle: "NPS - Detalhes da franquia",
    },
    
  },
]