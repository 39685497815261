// Getters
export const FINANCIAL_SOLUTION_SEARCH_RESULT = 'financial_solution/FINANCIAL_SOLUTION_SEARCH_RESULT'
export const COMPANIES_FINANCIAL_SOLUTION = 'financial_solution/COMPANIES_FINANCIAL_SOLUTION'
export const PRODUCTS_FINANCIAL_SOLUTION = 'financial_solution/PRODUCTS_FINANCIAL_SOLUTION'
export const PROVISIONING_TYPES = 'financial_solution/PROVISIONING_TYPES'


// Mutations
export const MUTATE_FINANCIAL_SOLUTION_SEARCH_RESULT = 'financial_solution/MUTATE_FINANCIAL_SOLUTION_SEARCH_RESULT'
export const MUTATE_COMPANIES = 'financial_solution/MUTATE_COMPANIES'
export const MUTATE_PRODUCTS = 'financial_solution/MUTATE_PRODUCTS'
export const MUTATE_PROVISIONING_TYPES = 'financial_solution/MUTATE_PROVISIONING_TYPES'



// Actions
export const GET_COMPANIES_FINANCIAL_SOLUTION = 'financial_solution/GET_COMPANIES_FINANCIAL_SOLUTION'
export const GET_PRODUCTS_FINANCIAL_SOLUTION = 'financial_solution/GET_PRODUCTS_FINANCIAL_SOLUTION'
export const SEARCH_FINANCIAL_SOLUTION = 'financial_solution/SEARCH_FINANCIAL_SOLUTION'
export const GET_FINANCIAL_SOLUTION = 'financial_solution/GET_FINANCIAL_SOLUTION'
export const SAVE_FINANCIAL_SOLUTION = 'financial_solution/SAVE_FINANCIAL_SOLUTION'
export const DELETE_FINANCIAL_SOLUTION = 'financial_solution/DELETE_FINANCIAL_SOLUTION'
export const GET_PROVISIONING_TYPES = 'financial_solution/GET_PROVISIONING_TYPES'

