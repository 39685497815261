import axios from 'axios'
import * as types from './types'

export default {
  [types.SEARCH_PARTNER_COMPANIES]: ({ commit }, payload) => {
    const resource = `/api/partner_companies/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_PARTNER_COMPANIES_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PARTNER_COMPANY]: ({}, payload) => {
    return axios.get(`/api/partner_companies/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_PARTNER_COMPANY]: ({}, payload) => {
    let save = axios.post
    let route = '/api/partner_companies'
    if (payload.id) {
      save = axios.put
      route = `/api/partner_companies/${payload.id}`
    }
    return save(route, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_PARTNER_COMPANY]: ({}, payload) => {
    return axios.delete(`/api/partner_companies/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PARTNER_COMPANY_AGENT_TYPES]: ({commit}) => {
    return axios.get(`/api/partner_company_agents/types`)
      .then(resp => {
        commit(types.MUTATE_PARTNER_COMPANY_AGENT_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ALL_PRODUCTS]: ({commit}) => {
    return axios.get(`/api/products`)
      .then(resp => {
        commit(types.MUTATE_PRODUCTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },

}
