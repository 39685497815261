// Getters
export const APPOINTMENTS_FROM_TODAY = "dashboard/APPOINTMENTS_FROM_TODAY";
export const BIRTHDAYS_THIS_MONTH = "dashboard/BIRTHDAYS_THIS_MONTH";
export const CUSTOMERS_REMINDER = "dashboard/CUSTOMERS_REMINDER";
export const LEADS_REMINDER = "dashboard/LEADS_REMINDER";
export const BANNERS = "dashboard/BANNERS";


// Mutations
export const MUTATE_APPOINTMENTS_FROM_TODAY =
  "dashboard/MUTATE_APPOINTMENTS_FROM_TODAY";
export const MUTATE_BIRTHDAYS_THIS_MONTH =
  "dashboard/MUTATE_BIRTHDAYS_THIS_MONTH";
export const MUTATE_CUSTOMERS_REMINDER = "dashboard/MUTATE_CUSTOMERS_REMINDER";
export const MUTATE_LEADS_REMINDER = "dashboard/MUTATE_LEADS_REMINDER";
export const MUTATE_BANNERS = "dashboard/MUTATE_BANNERS";

// Actions
export const POST_APPOINTMENTS_FROM_TODAY =
  "dashboard/POST_APPOINTMENTS_FROM_TODAY";
export const GET_BIRTHDAYS_THIS_MONTH = "dashboard/GET_BIRTHDAYS_THIS_MONTH";
export const GET_CUSTOMERS_REMINDER = "dashboard/GET_CUSTOMERS_REMINDER";
export const GET_LEADS_REMINDER = "dashboard/GET_LEADS_REMINDER";
export const GET_BANNERS = "dashboard/GET_BANNERS";