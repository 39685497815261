import * as types from './types'

export default {
  [types.MUTATE_FINANCIAL_ADJUSTMENTS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_FINANCIAL_ADJUSTMENT_REASONS]: (state, payload) => {
    state.adjustmentReasons = payload
  },
  [types.MUTATE_FINANCIAL_ADJUSTMENT_CREDIT_TYPES]: (state, payload) => {
    state.adjustmentCreditTypes = payload
  },
  [types.MUTATE_FINANCIAL_ADJUSTMENT_TYPES]: (state, payload) => {
    state.adjustmentTypes = payload
  },
  [types.MUTATE_FINANCIAL_ADJUSTMENT_TOTALIZER]: (state, payload) => {
    state.adjustmentTotalizer = payload
  },
  [types.MUTATE_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR]: (state, payload) => {
    state.saveFinancialAdjustmentSidebar = { ...state.saveFinancialAdjustmentSidebar, ...payload }
  },
  [types.MUTATE_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR]: (state, payload) => {
    state.adjustmentDetailSidebar = { ...state.adjustmentDetailSidebar, ...payload }
  },
  [types.MUTATE_FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT]: (state, payload) => {
    state.adjustmentsByMonth = payload
  },
}
