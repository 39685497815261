import * as types from "./types";
import _ from "lodash";

export default {
  [types.MUTATE_CONSULTANTS]: (state, payload) => {
    state.consultants = payload;
  },
  [types.MUTATE_TOP_BY_SCHEDULED_QUANTITY]: (state, payload) => {
    state.topByScheduledQuantity = payload;
  },
  [types.MUTATE_TOP_BY_DONE_QUANTITY]: (state, payload) => {
    state.topByDoneQuantity = payload;
  },
  [types.MUTATE_TOP_BY_PAID_AMOUNT]: (state, payload) => {
    state.topByPaidAmount = payload;
  },
  [types.MUTATE_KPI_LIST]: (state, payload) => {
    state.kpiList = [];
    _.forEach(payload, (e) => {
      var content = {
        id: e.id,
        leader_franchise_name: e.leader_franchise_name,
        digital_franchise_id: e.digital_franchise_id,
        name: e.name,
        hot_leads_quantity: 0,
        scheduled_interview_quantity: 0,
        pre_interviews_quantity: 0,
        interviews_quantity: 0,
        interview_contract_quantity: 0,
        done_interview_quantity: 0,
        client_leads_quantity: 0,
        consultant_leads_quantity: 0,
        scheduled_x_moment_quantity: 0,
        done_x_moment_quantity: 0,
        x_moment_positive_feedbacks_quantity: 0,
        scheduled_partnership_quantity: 0,
        done_partnership_quantity: 0,
        partnership_positive_feedbacks_quantity: 0,
        interview_contract_amount: 0,
        subscription_contract_amount: 0,
        other_financial_solutions_contract_amount: 0,
        total_contract_amount: 0,
        signature_contract_quantity: 0,
        support_contract_quantity: 0,
        scheduled_negotiation_quantity: 0,
        negotiation_positive_feedbacks_quantity: 0,
        renewal_contract_quantity: 0,
        negotiation_interview_contract_quantity: 0,
      };
      state.kpiList.push(content);
    });
  },
  [types.MUTATE_KPI_LIST_CONTENT]: (state, payload) => {
    _.forEach(payload, (e) => {
      var kpiRow = _.find(state.kpiList, {
        id: e.consultant_id,
        digital_franchise_id: e.digital_franchise_id,
      });
      _.assign(kpiRow, e);
    });
  },
};
