export const infoKPI = `
<div style="text-align: start; line-height: 1.4; margin-top: 1rem;">
<b>Leads quentes:</b><br/>
O que é: É considerado um LEAD Quente, quando um potencial cliente tem algum tipo de interesse no serviço da Futuro.<br/>
Regra: O lead será contabilizado como quente quando o consultor clicar no botão de ação "Quente", este botão se encontra na tabela dos LEADs Clientes. Um LEAD Quente só será considerado no faturômetro a primeira vez que ficar quente.<br/><br/>

<b>EA:</b><br/>
O que é: É considerada uma entrevista agendada, quando agenda uma reunião de entrevista com um lead ou um cliente.<br/>
Regra: Contabiliza-se 1 EA no faturômetro quando agendar uma reunião de entrevista com um LEAD ou com um Cliente. É possível cancelar essa EA dentro de 24h, caso tenha agendado por engano.<br/><br/>

<b>PEF:</b><br/>
O que é: É considerada uma Pré-Entrevista Feita, quando o cliente abre o link de pré-entrevista e preenche algo nela.<br/>
Regra: Irá contabilizar uma Pré-Entrevista Feita quando o cliente clicar em Finalizar ou caso o cliente tiver preenchido algo na Pré-Entrevista e o consultor clicar em transformar esta Pré-Entrevista em Entrevista.<br/><br/>

<b>EF:</b><br/>
O que é: É uma Entrevista Feita, uma entrevista agendada que foi realizada.<br/>
Regra: Ao reportar o compromisso de EA e que esta Entrevista foi feita, deve aparecer no faturometro 1 EF.<br/><br/>

<b>EP:</b><br/>
O que é: É uma entrevista paga, quando o cliente realiza o pagamento de EP na reunião de entrevista e a Futuro recebe esse pagamento.<br/>
Regra no Faturômetro: Quando o cliente paga uma EP e a Futuro recebe esse valor, ela é contabilizada no Faturômetro Bruto e também no Faturômetro Validado com o numeral 1. E também será contabilizado no faturômetro na data em que foi realizado o pagamento. O report de toda EP é automático, ou seja não será necessessário reportar esse contrato.<br/><br/>

<b>NEA:</b><br/>
O que é: É uma Negociação de Entrevista Agendada, essa é a reunião que agendamos quando um cliente não paga a EP na reunião de Entrevista.<br/>
Regra: Quando agendar a Negociação de EP vai contabilizar 1 NEA no faturômetro.<br/><br/>

<b>NEP:</b><br/>
O que é: É uma Negociação de Entrevista Paga, quando o cliente realiza o pagamento de EP na reunião de Negociação de EP e a Futuro recebe esse pagamento.<br/>
Regra no Faturômetro: Quando o cliente paga uma EP na reunião de NEA (Negociação de Entrevista Agendada) e a Futuro recebe esse valor, ela é contabilizada no Faturômetro Bruto e também no Faturômetro Validado com o numeral 1. E será contabilizado no faturômetro na data em que foi realizado o pagamento. O report de toda NEP é automático, ou seja não será necessessário reportar esse contrato.<br/><br/>

<b>REC PN:</b><br/>
O que é: Considera-se uma Recomendação de Parceiro de Negócios quando o PN recebe uma indicação de um PN e registra no sistema. <br/>
Regra: Contabiliza no faturômetro 1 REC PN de duas formas: Ao cadastrar um Novo LEAD PN (na tela LEAD PN) recomendado por alguém ou cadastrando uma recomendação de PN dentro da entrevista.<br/><br/>

<b>REC CL:</b><br/>
O que é: Considera-se uma Recomendação de Parceiro de Cliente quando o PN recebe uma indicação de um cliente e registra no sistema. <br/>
Regra: Contabiliza no faturômetro 1 REC CL de duas formas: Ao cadastrar um Novo LEAD Cliente (na tela LEAD CLIENTE) recomendado por alguém ou cadastrando uma recomendação de cliente dentro da entrevista.<br/><br/>

<b>REC/EF:</b><br/>
O que é: Média de quantas recomendações por entrevista feita o PN está pegando, dentro de um período específico.<br/> 
Regra: A média virá a partir da soma de todas as recomendações cadastradas na entrevista ou como um novo lead no sistema dividido pela quantidade de EF, dentro de um período específico.<br/><br/>

<b>EA/EF:</b><br/>
O que é: É a eficiência para entender quantas entrevistas agendadas foram feitas. Esta é uma eficiência que espelha principalmente a qualidade da ligação do PN. <br/>
Regra: A eficiência virá a partir da soma de todas as entrevistas feitas dividido pelas entrevistas agendadas, dentro de um período específico.<br/><br/>

<b>EF/EP:</b><br/>
O que é: É a eficiência para entender quantas entrevistas feitas foram pagas. Esta é uma eficiência que espelha principalmente a qualidade do fechamento do PN. <br/>
Regra: A eficiência virá a partir da soma de todas as entrevistas pagas dividido pelas entrevistas feitas, dentro de um período específico.<br/><br/>

<b>EA/EP:</b><br/>
O que é: É a eficiência para entender quantas entrevistas agendadas foram pagas. Esta é uma eficiência que espelha a qualidade da ligação e do fechamento do PN. <br/>
Regra: A eficiência virá a partir da soma de todas as entrevistas agendadas dividido pelas entrevistas pagas, dentro de um período específico.<br/><br/>

<b>R$/EP:</b><br/>
O que é: É a eficiência para entender a média de valor das EPs de clientes. Esta é uma eficiência que espelha o potencial de renda dos clientes que um PN está atendendo. <br/>
Regra: o valor virá a partir da soma de todas os valores das entrevistas pagas dividido pela quantidade das entrevistas pagas, dentro de um período específico.<br/><br/>

<b>MXA:</b><br/>
O que é: Considera-se um Momento X Agendado quando o compromisso de Momento X é agendada. Lembrando que o MX é a reunião que realizamos depois que o cliente paga a EP. 
Regra: apenas clientes podem ter agendamento de MX.<br/><br/>

<b>MXF:</b><br/>
O que é: Considera-se um Momento X Feito quando o compromisso de Momento X é realizado.<br/> Regra: Será contabilizado no Faturômetro MXF, quando reportar que o MXA foi feito, tanto em Validado quanto em Bruto.<br/><br/>

<b>MXC:</b><br/>
O que é: Considera-se um Momento X com contrato quando o cliente fecha pelo menos um contrato na reunião de Momento X.<br/> 
Regra: Será contabilizado no Faturômetro um MXC Bruto: quando fizer o report do contrato daquele compromisso, contabilizará apenas um contrato. Já para contabilizar no Faturômetro um MXC Validado, quando o contrato que for fechado neste MX estiver com o status a partir de Proposta Implantada.<br/><br/>

<b>MXA/MXF:</b><br/>
O que é: É a eficiência para entender quantos MX agendados foram feitos. Esta é uma eficiência que espelha principalmente a qualidade da ligação do PN ao agendar uma reunião de MX. <br/>
Regra: A eficiência virá a partir da soma de todos os MX feitos dividido pelos MX agendadas, dentro de um período específico.<br/><br/>

<b>MXF/MXC:</b><br/>
O que é: É a eficiência para entender quantos MX feitos geraram contratos. Esta é uma eficiência que espelha clientes com potencial de produto e consultores que tem um bom fechamento de produtos na reunião de MX. <br/>
Regra: A eficiência virá a partir da soma de todos os MX com contratos dividido pelos MX feitos, dentro de um período específico.<br/><br/>

<b>PA:</b><br/>
O que é: Considera-se uma Parceria Agendado quando o compromisso de Parceria é agendada. Lembrando que PA é a reunião de acompanhamento que fazemos depois de realizar um MX, ou pode ser uma outra reunião de Parceria de um cliente. <br/>
Regra: Clientes e leads podem ter agendamento de parceria.<br/><br/>

<b>PF:</b><br/>
O que é: Considera-se uma Parceria Feita quando o compromisso de Parceria é realizado com um cliente. <br/>
Regra: é contabilizado no Faturômetro uma PF quando uma reunião de PA é reportada como Feita.<br/><br/>

<b>PC:</b><br/>
O que é: Considera-se uma Parceria com contrato quando é fechado pelo menos um contrato no compromisso de Parceria. <br/>
Regra: É contabilizado no Faturômetro Bruto uma PC, quando fizer o report do contrato daquele compromisso, contabilizará apenas um contrato. Já uma PC é contabilizada no Faturômetro Validado quando aquele contrato fechado passar para o status de Proposta Implantada.<br/><br/>

<b>PA/PF:</b><br/>
O que é: É a eficiência para entender quantas Parcerias Agendados foram feitas. Esta é uma eficiência que espelha principalmente a qualidade da ligação do PN ao agendar uma reunião de Parceria. <br/>
Regra: A eficiência virá a partir da soma de todos os Parcerias feitas dividido pelas Parcerias agendadas, dentro de um período específico.<br/><br/>

<b>PF/PC:</b><br/>
O que é: É a eficiência para entender quantas Parcerias feitas geraram contratos. Esta é uma eficiência que espelha clientes com potencial de produto e consultores que tem um bom fechamento de produtos na reunião de Parceria. <br/>
Regra: A eficiência virá a partir da soma de todas as Parcerias com contratos dividido pelas Parcerias feitas, dentro de um período específico.<br/><br/>

<b>Suporte:</b><br/>
O que é: É considerado um Suporte quando o cliente paga a assinatura de R$59,90 e a Futuro recebe esse pagamento. <br/>
Regra no Faturômetro: Quando o cliente paga um Suporte na reunião de EA (Entrevista Agendada), na reunião de MXA (Momento X Agendado) ou PA (Parceria Agendada) e a Futuro recebe esse valor, ela é contabilizada no Faturômetro Bruto e também no Faturômetro Validado com o numeral 1. E será contabilizado no Faturômetro na data em que foi realizado o pagamento. O report de todo suporte é automático, ou seja não será necessessário reportar esse contrato.<br/><br/>

<b>Acompanhamento:</b><br/>
O que é: É considerado um acompanhamento quando o cliente paga um dos planos: Básico, Essencial ou Elite e a Futuro recebe esse pagamento. <br/>
Regra no Faturômetro: Quando o cliente paga um plano de acompanhamento na reunião de EA (Entrevista Agendada), na reunião de MXA (Momento X Agendado) ou PA (Parceria Agendada) e a Futuro recebe esse valor, ela é contabilizada no Faturômetro Bruto e também no Faturômetro Validado com o numeral 1. E será contabilizado no Faturômetro na data em que foi realizado o pagamento. O report de todo acompanhamento é automático, ou seja não será necessessário reportar esse contrato.<br/><br/>

<b>Renovação:</b><br/>
R$ de EP: O que é: É a soma dos valores das EPs que um consultor fechou dentro de um período. A intenção é o consultor entender o potencial da renda dos clientes que está atendendo. <br/>
Regra: Contabilizará no Faturômetro a soma do valor de todas as EPs realizadas e validadas dentro de um período específico.<br/><br/>

<b>R$ de Assinatura:</b><br/>
O que é: É a soma dos valores de todos os acompanhamentos e suportes que um consultor fechou, dentro de um período. A intenção é o consultor visualizar o valor que fechou de assinaturas e ver o quanto a sua comissão contínua aumentou naquele período selecionado. <br/>
Regra: Contabilizará no Faturômetro a soma do valor de todos os suportes e acompanhamentos que foram fechados e a partir do status proposta implantada, dentro de um período específico.<br/><br/>

<b>R$ de Solução:</b><br/>
O que é: É a soma dos valores de todas soluções financeiras que um consultor fechou, dentro de um período. A intenção é o consultor visualizar o valor que fechou de soluções financeiras naquele período selecionado. <br/>
Regra: Contabilizará no Faturômetro a soma do valor de todas as soluções financeiras fechadas dentro de um período específico. Para o Faturômetro Bruto apenas os contratos que foram reportados e ainda não passaram para o status de proposta implantada serão considerados. Já para o Faturômetro Validado serão considerados todas as solução que estão com o status a partir de proposta implantada.<br/><br/>

<b>R$ Total:</b><br/>
O que é: É a soma dos valores de todas as EPs, Assinaturas e Soluções Financeiras que um consultor fechou, dentro de um período. <br/>
Regra: Contabilizará no Faturômetro Bruto a soma do valor total de todas as EPs, Assinaturas que foram para proposta implantada. Já soluções financeiras, serão consideradas como faturamento bruto aqueles contratos que foram reportados e ainda não passaram para o status de proposta implantada. Já o Faturômetro Validado serão consideradas, EPs, Assinaturas e Soluções financeiras que estão com um status a partir de proposta implantada.<br/><br/>

<b>LTV/CL:</b><br/>
O que é: LTV (Lifetime Value) é uma estimativa da receita média que um cliente irá gerar ao longo de sua vida como cliente. Está eficiência mostrará uma estimativa de quanto cada cliente está gerando de receita ao longo do seu vínculo com a Futuro. É importante dizer que, cada produto na Futuro é calculado de forma específica, pois cada produto tem uma longividade específica. <br/>
Regra: Contabilizará no Faturômetro o valor do LTV total dividido pelo número de clientes que foram selecionados dentro daquele determinado período.<br/><br/>

<b>Validado e Bruto:</b><br/>
O que é: Contratos são considerados Brutos até virarem uma Proposta Implantada ou Rodando. Ou seja, quando o PN reportar que fechou negócio esse contrato entra no Faturômetro como Bruto (exceto para EP, NEP, Suportes e Acompanhamentos - pois assim que o cliente paga já contabiliza em bruto e em validado). Já contratos validados, contabilizam no Faturômetro a partir do Status de Proposta Implantada.<br/><br/>
</div>
`;

export const leadCustomer = `
<div style="text-align: start; line-height: 1.4; margin-top: 1rem;">
O objetivo desta tela é de ajudar o nosso parceiro de negócio a gerir seus potenciais clientes. Nela você poderá montar sua lista do Plano A, elencar/identificar os clientes mais potenciais e também classificar/encontrar potenciais clientes com maior interesse no seu serviço. <br/><br/>
    
Legenda:<br/>
Coluna Prio. - O objetivo desta coluna é do(a) Planejador(a) Futuro priorizar os potenciais clientes pra ligar. Criar uma lista automatizada para quando realizar a atividade de ligações (plano A).<br/> Aqui é possível tanto arrastar o lead quanto editar o número de prioridade. Esta funcionalidade só irá funcionar para clientes próprios e somente se não houve nenhum outro filtro selecionado (tanto os filtros acima da tabela quanto qualquer filtro selecionado na tabela por este botão ⇳)<br/><br/>
Total de Leads – Considera-se um Lead Cliente qualquer potencial cliente.  Temos 2 formas de cadastrar leds de clientes: Através do “Novo Lead Cliente” e cadastrando a recomendação de cliente via entrevista.<br/><br/>
Quente – Todo lead de cliente “Quente” é um lead que está interessado no serviço ou que está avisado que você entrará em contato.<br/><br/>
Frio – Todo lead de cliente “Frio” é um lead que não está avisado da sua ligação ou que nunca ouviu falar do serviço da Futuro Consultoria.<br/><br/>
Desinteressados – Todo lead de cliente que já demonstrou desinteresse pelo serviço da Futuro.<br/><br/>
A classificar – Todo Lead que precisa ser classificado entre Quente ou Frio.<br/><br/>
EA – Entrevistas agendadas.<br/><br/>
EF – Entrevistas feitas.   <br/><br/>
Data de Criação – Foi a data que o Lead foi cadastrado no sistema, seja através do botão “Novo Lead Cliente” ou da recomendação de cliente via entrevista.<br/><br/>
Data pra Lembrar – Ferramenta para lembrar a data para ligar para um determinado lead de cliente. Exemplo: quando um cliente pede pra você entrar em contato no dia X às Y horas.<br/><br/>
</div>
`;

export const customer = `
<div style="text-align: start; line-height: 1.4; margin-top: 1rem;">
Na metodologia Futuro, consideramos um cliente um contato que fechou algum tipo de contrato, seja uma entrevista paga, uma solução financeira ou um suporte/acompanhamento. Ou seja, o cliente deixa de ser um potencial cliente (Lead) ao fechar um contrato. Portanto, quando aquele lead vira um cliente, ele deixa de existir na tela “Leads clientes” e é transferido à tela “Clientes”.<br/><br/>

O objetivo desta tela é que o(a) Planejador(a) Futuro consiga gerir todos os seus clientes. E com os filtros, consiga também encontrar oportunidades de fazer cross-sell ou up-sell com seus clientes.<br/><br/>

Legenda:<br/>
Suporte: todos os seus assinantes de suporte.<br/><br/>
Básico: todos os seus assinantes de acompanhamento básico.<br/><br/>
Elite: todos os seus assinantes de acompanhamento elite.<br/><br/>
Essencial: todos os seus assinantes de acompanhamento essencial.<br/><br/>
Renda: a renda do seu cliente é puxada através do preenchimento da entrevista.<br/><br/>
Poupa: o quanto o seu cliente poupa é puxado através do preenchimento da entrevista.<br/><br/>
Data pra Lembrar: o objetivo dessa ferramenta é para relembrar o Planejador Futuro de alguma tarefa como ligação, agendar uma reunião etc. Resumindo funcionará como um “reminder” para o consultor.<br/><br/>
Status Entrevista Paga: São todos os clientes que pagaram a entrevista mas não possuem acompanhamento.<br/><br/>
Status Solução Financeira: São todos os clientes que possuem solução financeira  mas não têm outro produto contratado (nem EP, suporte ou acompanhamento).<br/><br/>
Dias sem contato: é o número de dias desde a última reunião feita com esse cliente.<br/><br/>
</div>
`;

export const appointment = `
<div style="text-align: start; line-height: 1.4; margin-top: 1rem;">
O objetivo desta funcionalidade é de ajudar o(a) Planejador(a) Futuro à organizar os seus compromissos com seus clientes. Além disso, você poderá identificar os seus clientes e da sua franquia com maior poder aquisitivo (“Big Shot”) e quem irá atende-los. Assim você e sua equipe poderão traçar a melhor estratégia para melhorar a performance de atendimento.<br/><br/>
 
Ação “novo compromisso”: Ao criar um novo compromisso um e-mail será encaminhado automaticamente para o seu cliente com o link da reunião. Ao incluir um PN especialista esse compromisso aparecerá automaticamente no sistema desse especialista.<br/><br/>
Ação “remover compromisso”: Só será possível deletar compromissos que antecedem a data e o horário da reunião. Compromissos que já passaram, não serão possíveis de excluí-los.<br/><br/>
Ação “Report compromisso”: Você precisa fazer o report dentro de 24h, caso contrário após 24h o seu sistema bloqueará qualquer outra ação até finalizar este report.<br/><br/>

Legenda:<br/>
MXA: Momento X Agendado<br/><br/>
PA: Parceria Agendada<br/><br/>
</div>
`;

export const leadPn = `
<div style="text-align: start; line-height: 1.4; margin-top: 1rem;">
Seja bem vindo à ferramenta para o gestor da Futuro gerir os LEADs de potenciais PNs (da sua equipe e seus próprios leads). Através dela o PN que está focado em ser um franqueado, ou que já é um franqueado, pode gerenciar e classificar o seu funil de LEADs até aprovar esse lead, transformando-o em PN.
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/lead-pn-5.png" />
<br/><br/>
A jornada do seu LEAD PN funciona da seguinte forma, primeiro vc vai criar esse LEAD através do botão NOVO LEAD PN, conforme imagem abaixo:
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/lead-pn-2.png" />
<br/><br/>
Depois de criá-lo a lógica é igual ao LEAD de Cliente, nas “ações” vc poderá classificar esse LEAD PN em Quente, Frio, Desinteressado, Sólides e Desclassificado. “Sólides”, é depois que esse lead entra na plataforma Sólides, onde será feita a gestão até a aprovação final. Já desclassificado seria quando esse lead não faz mais sentido no processo seletivo. Esses botões de ação se encontram na imagem abaixo:
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/lead-pn-1.png" />
<br/><br/>
Como faço para criar um NOVO PN? A lógica é bem parecida com LEADs e Clientes, quando esse LEAD passa a ser um PN, o gestor deve selecionar a ação “transformar em PN’, conforme imagem abaixo. Assim que for feita essa ação, esse lead entrará na ferramenta “Minha Equipe” e ficará com o status “transformado em PN” na ferramenta LEAD PN.
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/lead-pn-3.png" />
<br/><br/>
Por fim, também é possível agendar uma entrevista individual com esse LEAD PN, basta entrar no detalhe deste lead (imagem abaixo) e criar uma reunião. Ou por “Novo Compromisso” também é possível criar essa reunião.
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/lead-pn-4.png" />
</div>
`;

export const transferCustomers = ` 
<div style="text-align: start; line-height: 1.4; margin-top: 1rem;">
    Ao transferir clientes, as comissões serão também transferidas seguindo a seguinte regra de transferência de comissão:
    <ul>
        <li>Transferência de PN inativo para PN ativo:
            <ul>
                <li>Comissões de EP e Soluções financeiras são transferidas para o novo dono com o mesmo nível de comissão do dono anterior. 
                Exemplo: cliente de um PNH vai para um PNP, esse PNP receberá as próximas comissões dos contratos antigos de EP parceladas e Soluções financeiras como um PNP. 
                Os antigos líderes continuam recebendo o spread da mesma forma que já recebiam antes da transferência. </li>
                <li>Comissões de Assinaturas são transferidas para o novo dono de acordo com o nível de comissão do novo dono deste cliente. 
                Exemplo: cliente de um PNH vai para um PNP, esse PNP receberá as próximas comissões das assinaturas (suportes e acompanhamentos) como um PNP.  
                Os spreads das próximas comissões da assinatura irão para o líder do PNP (novo dono do cliente). </li>
            </ul>
        </li>
        <li>Transferência de PN ativo para PN ativo:
            <ul>
                <li>Apenas comissões de assinaturas serão transferidas, tanto para o novo dono do cliente, quanto para os líders do novo dono do cliente </li>
            </ul>
        </li>
    </ul>
</div>
`;
export const infoDashboard = `
<div style="text-align: start; line-height: 1.4; margin-top: 1rem;">
<p>
Olá Parceiro(a) Futuro!</p>
<p>A nossa
plataforma Oráculo para planejadores financeiros ganhou uma cara
nova! Sim, lançamos o seu DASHBOARD, agora você terá uma página
principal para resumir informações importantes que irá otimizar
ainda mais o seu dia a dia! </p>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-1.png" />
<br/><br/>
Nova Funcionalidade:
<br/><br/><br/>
<p>Dashboard</p>
<p>O principal
objetivo de uma página de Dashboard é resumir as principais
informações do seu dia a dia como planejador, afim de fazê-lo ganhar tempo e comodidade.
</p>
<ul>
	<li><p>Na parte
	superior do seu Dashboard, que é a parte mais relevante da sua
	página, você encontrará <b>Banners</b>, que será o ambiente onde
	a Futuro se comunicará com você a respeito de eventos e
	informações importantes.</p></li>
	<li><p><b>Área de
	Lembretes</b>: Nesse ambiente você terá 5 ferramentas para
	relembrá-lo de eventos e informações pessoais suas, de clientes, leads
	e sua equipe, são elas:</p></li>
</ul>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-2.png" />
<ul>
	<ul>
		<li><p><b>TO DO
		(imagem acima)</b>: Você verá todas as tarefas do seu <b>dia
		vigente </b>que foram registradas. Nele você conseguirá adicionar
		uma nova tarefa em “Tarefa Rápida”.</p></li>
	</ul>
</ul>
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-3.png" />
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-4.png" />
<br/><br/>
<ul>
	<li><p><b>Detalhes
	TO DO (imagens acima): </b>nessa tela você terá como organizar
	todas as suas tarefas, utilizando os seguintes agrupamentos:
	</p>
	<ul>
		<li><p><b>Tarefas
		pendentes: </b>Serão as tarefas que ainda não foram concluídas.</p></li>
		<li><p><b>Todas as
		Tarefas: </b>apresentará todas as tarefas independente da
		classificação da suas tarefas.</p></li>
		<li><p><b>Importantes:
		</b>As tarefas que forem classificadas como importantes estarão
		aqui.</p></li>
		<li><p><b>Concluídas:
		</b>Nesse espaço teremos todas as tarefas que foram marcadas como
		concluída.</p></li>
		<li><p><b>Atrasadas:
		</b>Ao passar o prazo da tarefa em 1 dia, caso não tenha sido concluída
		ela irá automaticamente para atrasadas.</p></li>
	</ul>
</ul>
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-5.png" />
<br/><br/>
<ul>
	<li><p><b>TO DO
	Lateral (imagem acima): </b>pensando no dia a dia do nosso
	planejador, criamos um atalho para você incluir uma tarefa
	independente da página do Oráculo que você estiver.</p></li>
</ul>
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-6.png" />
<br/><br/>
<ul>
	<li><p><b>TO DO
	Lateral (imagem acima): </b>também será possível visualizar de
	forma rápida as tarefas do dia que você selecionar e também é
	possível adicionar uma nova tarefa.</p></li>
</ul>
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-7.png" />
<br/><br/>
<ul>
	<li><p><b>Agenda da
	Semana (imagem acima): </b>Aqui será possível de forma semanal e resumida o
	acesso a sua agenda, da sua equipe direta e sua estrutura.
	</p></li>
</ul>
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-8.png" />
<br/><br/>
<ul>
	<li><p><b>Aniversários
	do mês: </b>Você encontrará resumidamente todos os clientes, leads de clientes e PNs
	aniversariantes do mês.</p></li>
</ul>
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-9.png" />
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-10.png" />
<br/><br/>
<ul>
	<li><p><b>Clientes
	para ligar (imagem acima): </b>Nessa tela irão aparecer no dia
	vigente do seu acesso, os clientes cadastrados para você ligar (apenas dia
	vigente). Para aparecer os clientes, é necessário cadastrar em
	“Lembrar” (imagem 2) a data e o horário.</p></li>
	<li><p><b>Clientes
	para ligar “Ver detalhes”: </b>ao clicar em ver detalhes irá
	direcionar para a tela “Clientes” com a lista dos clientes para
	ligar do dia vigente do seu acesso.</p></li>
</ul>
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-11.png" />
<br/><br/>
<img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dashboard-info-11.png" />
<br/><br/>
<ul>
	<li><p><b>Leads
	para ligar (imagem acima): </b>Nessa tela irão aparecer no dia
	vigente do seu acesso, os leads cadastrados para você ligar (apenas dia vigente).
	Para aparecer os clientes, é necessário cadastrar em “Lembrar”
	(imagem 2) a data e o horário.
	</p></li>
	<li><p><b>Leads
	para ligar “Ver detalhes”: </b>ao clicar em ver detalhes irá
	direcionar para a tela “Leads Clientes” com a lista dos clientes
	para ligar do dia vigente do seu acesso.</p></li>
</ul>
<p>
<font face="Helvetica, serif"><span style="letter-spacing: 0.1pt">Nós
da equipe de TI trabalhamos nessa versão para melhorar o dia a dia
do nosso Planejador! Esperamos que </span></font><b>gostem</b><font face="Helvetica, serif"><span style="letter-spacing: 0.1pt">
das melhorias realizadas e que isso entregue muito mais planejamentos
financeiros. Lembrando que qualquer feedback temos o nosso canal de
atendimento via Whats. E também aceitamos elogios =)</span></font></p>
<br/>
<font face="Helvetica, serif"><span style="letter-spacing: 0.1pt">Boas
vendas!</span></font>
</div>
`;

export const infoContracts = `
<div style="text-align: center; line-height: 1.4; margin-top: 1rem;">
<p><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/fluxo_contratos_1.jpg" /></p>
<p><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/fluxo_contratos_2.jpg" /></p>
<p><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/fluxo_contratos_3.jpg" /></p>
<p><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/fluxo_contratos_4.jpg" /></p>
<p><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/fluxo_contratos_5.jpg" /></p>
<p><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/fluxo_contratos_6.jpg" /></p>
<p><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/fluxo_contratos_7.jpg" /></p>
<p><img src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/fluxo_contratos_8.jpg" /></p>
</div>
`