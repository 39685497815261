import * as types from './types'

export default {
  [types.MUTATE_NPS_STRUCTURE_SEARCH_RESULT]: (state, payload) => {
    state.structureSearchResult = payload
  },
  [types.MUTATE_NPS_CONSULTANT_SEARCH_RESULT]: (state, payload) => {
    state.consultantSearchResult = payload
  },
  [types.MUTATE_NPS_STATUSES]: (state, payload) => {
    state.npsStatuses = payload
  },
  [types.MUTATE_NPS_CUSTOMERS_LIST]: (state, payload) => {
    state.npsCustomersList = payload
  },
  [types.MUTATE_NPS_FRANCHISES_CONSULTANTS_LIST]: (state, payload) => {
    state.npsFranchisesConsultantsList = payload
  },
  [types.MUTATE_NPS_TOTALIZER]: (state, payload) => {
    state.npsTotalizer = payload
  },
  [types.MUTATE_NPS_FRANCHISE_TOTALIZER]: (state, payload) => {
    state.npsFranchiseTotalizer = payload
  }
}
