import * as types from './types'

export default {
  [types.MUTATE_FINANCIAL_SOLUTION_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_COMPANIES]: (state, payload) => {
    state.companiesList = payload
  },
  [types.MUTATE_PRODUCTS]: (state, payload) => {
    state.productsList = payload
  },
  [types.MUTATE_PROVISIONING_TYPES]: (state, payload) => {
    state.provisioningTypes = payload
  },

 
}
