import _ from 'lodash'
import * as types from './types'
import * as financial from "@/constants/financial";

export default {
  [types.MUTATE_CONTRACTS_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload
  },
  [types.MUTATE_CONSULTANTS]: (state, payload) => {
    state.consultants = payload
  },
  [types.MUTATE_FINANCIAL_SOLUTIONS]: (state, payload) => {
    state.financialSolutions = payload
  },
  [types.MUTATE_FINANCIAL_SOLUTION_FIELDS]: (state, payload) => {
    state.financialSolutionFields = payload
  },
  [types.MUTATE_FINANCIAL_SOLUTION_CALCULATION_RULES]: (state, payload) => {
    state.financialSolutionCalculationRules = _.groupBy(payload, (rule) => rule.financial_solution_field_id)
  },
  [types.MUTATE_STATUS_LIST]: (state, payload) => {
    state.statusList = payload
  },
  [types.MUTATE_EDIT_CONTRACT_SIDEBAR]: (state, payload) => {
    state.editContractSidebar = { ...state.editContractSidebar, ...payload }
  },
  [types.MUTATE_ADD_RECEIPT_SIDEBAR]: (state, payload) => {
    state.addReceiptSidebar = { ...state.addReceiptSidebar, ...payload }
  },
  [types.MUTATE_REQUEST_DELETION_SIDEBAR]: (state, payload) => {
    state.requestDeletionSidebar = { ...state.requestDeletionSidebar, ...payload }
  },
  [types.MUTATE_EDIT_SNAPSHOT_SIDEBAR]: (state, payload) => {
    state.editSnapshotSidebar = { ...state.editSnapshotSidebar, ...payload }
  },
  [types.CLEAR_DETAILS_GENERAL_INFORMATION]: (state, payload) => {
    state.details.generalInformation = undefined
  },
  [types.MUTATE_DETAILS_GENERAL_INFORMATION]: (state, payload) => {
    state.details.generalInformation = {
      ...payload,
      customer: payload.customer?.name,
      customer_cpf: payload.customer?.cpf,
      customer_email: payload.customer?.email,
      customer_cell_phone: payload.customer?.cell_phone,
      consultant: payload.consultant?.name,
      closer_consultant: payload.closer_consultant?.name,
      specialist_consultant: payload.specialist_consultant?.name,
      payment_gateway: payload.payment_gateway?.name,
      contract_status: payload.contract_status?.name,
      contract_status_description: payload.contract_status?.description,
      product: payload.product?.name,
      partner_company: payload.partner_company?.name,
      financial_solution: payload.financial_solution?.name,
      allow_multiple_automatic_payments: payload.financial_solution?.allow_multiple_automatic_payments,
      custom_fields: _.filter(payload.custom_fields, field => field.field_name !== 'Prêmio mensal'),
      has_transactions: (
        payload.financial_solution_id == financial.EP ||
        payload.financial_solution_id == financial.ASSINATURA_SUPORTE ||
        payload.financial_solution_id ==
          financial.ACOMPANHAMENTO_BASICO ||
        payload.financial_solution_id ==
          financial.ACOMPANHAMENTO_ESSENCIAL ||
        payload.financial_solution_id == financial.ACOMPANHAMENTO_ELITE || 
        payload.financial_solution_id == financial.GESTAO_FINANCEIRA 
      )? true : false
    }
  },
  [types.MUTATE_COMMENTS]: (state, payload) => {
    state.comments = payload
  },
  [types.MUTATE_PAYMENTS]: (state, payload) => {
    state.payments = payload
  },
  [types.MUTATE_COMMISSIONS]: (state, payload) => {
    state.commissions = payload
  },
  [types.MUTATE_FILES]: (state, payload) => {
    state.files = payload
  },
  [types.MUTATE_HISTORY]: (state, payload) => {
    state.history = payload
  },
  [types.MUTATE_DELETION_REQUEST_SEARCH_RESULT]: (state, payload) => {
    state.deletionRequestSearchResult = payload
  },
  [types.MUTATE_REQUEST_DELETION_APROVAL_SIDEBAR]: (state, payload) => {
    state.requestDeletionAprovalSidebar = { ...state.requestDeletionAprovalSidebar, ...payload }
  },
  [types.MUTATE_CUSTOMER_APPOINTMENTS]: (state, payload) => {
    state.customerAppointments = payload
  },
  [types.MUTATE_EDIT_CONTRACT_OWNER_SIDEBAR]: (state, payload) => {
    state.editContractOwnerSidebar = { ...state.editContractOwnerSidebar, ...payload }
  },
  [types.MUTATE_QUERY_ASAAS_ID_SIDEBAR]: (state, payload) => {
    state.queryAsaasIdSidebar = { ...state.queryAsaasIdSidebar, ...payload }
  },
  [types.MUTATE_TRANSACTIONS]: (state, payload) => {
    state.transactions = payload
  },
  [types.MUTATE_SAVE_TRANSACTION_SIDEBAR]: (state, payload) => {
    state.saveTransactionSidebar = { ...state.saveTransactionSidebar, ...payload }
  },
}
