// Getters
export const VERSIONS_SEARCH_RESULT = 'system/VERSIONS_SEARCH_RESULT'
export const DASHBOARD_BANNERS_SEARCH_RESULT = 'system/DASHBOARD_BANNERS_SEARCH_RESULT'
export const NEW_BANNER_SIDEBAR = 'system/NEW_BANNER_SIDEBAR'
export const ANNOUNCEMENT_TYPES = 'system/ANNOUNCEMENT_TYPES'

// Mutations
export const MUTATE_VERSIONS_SEARCH_RESULT = 'system/MUTATE_VERSIONS_SEARCH_RESULT'
export const MUTATE_DASHBOARD_BANNERS_SEARCH_RESULT = 'system/MUTATE_DASHBOARD_BANNERS_SEARCH_RESULT'
export const MUTATE_NEW_BANNER_SIDEBAR = 'system/MUTATE_NEW_BANNER_SIDEBAR'
export const MUTATE_ANNOUNCEMENT_TYPES = 'system/MUTATE_ANNOUNCEMENT_TYPES'

// Actions
export const SEARCH_VERSIONS = 'system/SEARCH_VERSIONS'
export const GET_VERSION = 'system/GET_VERSION'
export const SAVE_VERSION = 'system/SAVE_VERSION'
export const DELETE_VERSION = 'system/DELETE_VERSION'
export const SEARCH_DASHBOARD_BANNERS = 'system/SEARCH_DASHBOARD_BANNERS'
export const OPEN_NEW_BANNER_SIDEBAR = 'system/OPEN_NEW_BANNER_SIDEBAR'
export const STORE_DASHBOARD_BANNER = 'system/STORE_DASHBOARD_BANNER'
export const GET_DASHBOARD_BANNER = 'system/GET_DASHBOARD_BANNER'
export const DELETE_DASHBOARD_BANNER = 'system/DELETE_DASHBOARD_BANNER'
export const UPDATE_DASHBOARD_BANNER_SEQUENCE = 'system/UPDATE_DASHBOARD_BANNER_SEQUENCE'
export const GET_ANNOUNCEMENT_TYPES = 'system/GET_ANNOUNCEMENT_TYPES'